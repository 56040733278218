import { Skeleton } from "antd";
import styled from "styled-components";

export const StyledSkeleton = styled(Skeleton.Input)`
  .ant-skeleton-input {
    &.ant-skeleton-input-sm {
      height: 1rem;
    }
  }
`;
