import { isNotNullOrUndefined } from "@lib/src/isNotNullOrUndefined";
import * as echarts from "echarts";

import geoJson from "@/assets/world-map.json";
import { getCountryName } from "@/utils/countries";

import { FleetMapVehicleCount } from "./FleetMap";

echarts.registerMap("world", geoJson as any);

export const buildFleetMapChartOptions = (
  zoom: number,
  vehicleCountByCountry?: FleetMapVehicleCount[],
  allowRoam?: boolean
) => {
  const lightestBlue = "#ccddff";
  const darkestBlue = "#7799ff";
  const defaultColor = "#4a4a4a";
  const borderColor = "#cccccc";
  const selectedBorderColor = "#ffac40";
  const selectedColor = "#ffcc60";
  const mouseoverColor = selectedColor;
  const data =
    geoJson?.features.map(({ properties: { name } }) => {
      const vehicleForCountry = vehicleCountByCountry?.find((v) => v.country == name);
      const disabled = vehicleForCountry == undefined;
      return {
        name,
        value: vehicleForCountry?.vehicles,
        select: {
          disabled,
          itemStyle: {
            borderWidth: 2,
            borderColor: selectedBorderColor,
            areaColor: disabled ? defaultColor : selectedColor,
          },
          label: {
            show: false,
          },
        },
        itemStyle: {
          borderColor,
          areaColor: defaultColor,
          label: {
            show: false,
          },
        },
        emphasis: {
          itemStyle: {
            areaColor: disabled ? defaultColor : mouseoverColor,
          },
          label: {
            show: false,
          },
        },
      };
    }) || [];
  return {
    option: {
      visualMap: {
        show: false,
        min: 0,
        max: Math.max(...data.map((d) => d.value).filter(isNotNullOrUndefined)),
        inRange: {
          color: data?.length ? [lightestBlue, darkestBlue] : defaultColor,
          symbol: "none",
        },
        calculable: true,
      },
      tooltip: {
        formatter: (params: { data: (typeof data)[number] }) => {
          return `${params.data.value || 0} vehicles in ${getCountryName(params.data.name)}`;
        },
      },
      legend: {
        show: false,
      },
      series: [
        {
          type: "map",
          map: "world",
          roam: !!allowRoam,
          showLegendSymbol: false,
          zoom,
          selectedMode: "single",
          data,
          projection: {
            // Mercator projection
            project: ([x, y]: [number, number]) => [
              (x / 180) * Math.PI,
              -Math.log(Math.tan((Math.PI / 2 + (y / 180) * Math.PI) / 2)),
            ],
            unproject: ([x, y]: [number, number]) => [
              (x * 180) / Math.PI,
              ((2 * 180) / Math.PI) * Math.atan(Math.exp(y)) - 90,
            ],
          },
        },
      ],
    },
  };
};
