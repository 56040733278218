import styled from "styled-components";

export const HDMSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 50px;
  h4 {
    margin-right: 10px;
    font-weight: 900;
    margin-bottom: 0px;
  }
`;

export const HDMHistoryChartWrapper = styled.div`
  margin: 20px 0px;
  padding: 1rem;
  .hdm-chart {
    padding: 0.75rem;
    border-radius: 0.75rem;
  }
`;

export const HDMChartHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .widget-title {
    margin-bottom: 0px;
      font-weight: 900;
      margin-bottom: 0px;
    }
  }
  .sub-title {
      font-size: 14px;
      color: #5f5f5f;
    }
  .hdm-chart-header-buttons {
    display: flex;
    align-items: center;
    .define-comparison-button {
      margin-left: 10px;
    }
  }
`;

export const HDMHistoryHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

export const HDMHistoryPortalElementContainer = styled.div`
  font-family: "Inter";
  min-width: 250px;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  .title {
    border-bottom: 1px solid black;
  }
  .tooltip-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    div {
      white-space: normal;
      overflow: hidden;
      max-width: 80%;
      text-align: right;
    }
  }
`;

export const HDMHistoryChartLegendContainer = styled.div`
  display: flex;
  align-items: center;
  .legend-cirlce {
    margin-right: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.accentBlue};
  }
`;
