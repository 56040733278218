import dayjs from "dayjs";

type MISFormatterProps = {
  from: Date;
};

/**
 * MIS = months in service
 * Returns a text element with amount of months passed since {@link from}.
 */
export default function MISFormatter({ from }: MISFormatterProps) {
  return <>{dayjs().diff(from, "month")}</>;
}
