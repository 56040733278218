import i18next from "i18next";
import { useState } from "react";
import { createPortal } from "react-dom";

import usePortalTargetObserver from "@/hooks/usePortalTargetObserver";
import { parseDateToStr } from "@/utils/dateUtils";
import { DataMatrixAggregations, DataMatrixDefinition } from "@/utils/types/commonDataDictionary";

import { HDMHistoryPortalElementContainer } from "./HDM.styled";
import { initialHDMHistoryChartTooltipDataElementId } from "./HistoricDataMatrixHistoryChart";

export type SCVHDMToolTip = {
  x: number;
  y: number;
  diagnosticCheckId: string;
  timestamp: number;
  odometer: number;
};

type HDMHistoryChartToolTipProps = {
  portalTargetId: string;
  aggregate: DataMatrixAggregations;
  tableDefinition: DataMatrixDefinition | undefined;
  // The rerender prop allows the usePortalTargetStateObserver to rerun and get a nonstale
  //   target element. When echarts adds event listeners it rerenders causing the observer
  //   to have a stale reference.
  rerenderProp?: any;
};

export const HDMHistoryChartTooltip = ({
  portalTargetId,
  aggregate,
  tableDefinition,
  rerenderProp,
}: HDMHistoryChartToolTipProps) => {
  const [targetElement, setTargetElement] = useState<Element | null>(null);
  const initialTooltipData = usePortalTargetObserver({
    elementSelector: ".hdm-history-tooltip-container",
    targetId: portalTargetId,
    initialDataElementSelector: `#${initialHDMHistoryChartTooltipDataElementId}`,
    setElement: setTargetElement,
    rerenderProp: rerenderProp,
  }) as SCVHDMToolTip;

  if (!targetElement || !initialTooltipData || !tableDefinition) return;

  return createPortal(
    <HDMHistoryPortalElementContainer>
      <span className="title">
        <h3 className="heading-x-small">
          <b>Table {aggregate}: </b>
          <span>
            {initialTooltipData.y} {tableDefinition.value_unit}
          </span>
        </h3>
      </span>
      <div className="tooltip-section">
        <span>Odometer</span>
        <div>{initialTooltipData.odometer} km</div>
      </div>
      <div className="tooltip-section">
        <span>Timestamp</span>
        <div>{parseDateToStr(new Date(initialTooltipData.timestamp), true)}</div>
      </div>
      <div className="tooltip-section">
        <span>{i18next.t("columnTitle.DIAGNOSTIC_CHECK_ID")}</span>
        <div>{initialTooltipData.diagnosticCheckId}</div>
      </div>
    </HDMHistoryPortalElementContainer>,
    targetElement
  );
};
