import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef } from "react";

import { FeatureId, useHomeQuery } from "@/api";
import { useAppendVisitedVehicleMutation } from "@/api";
import { useVehicleDetailsQuery } from "@/api/customerApi";
import { QUERY_SETTINGS } from "@/constants";
import { SCVContextProvider } from "@/contexts/SCVContext";
import Page404 from "@/pages/404";
import { Route } from "@/routes/vehicle.$pvin";
import { getCurrentCustomerId } from "@/utils/customers";
import { useFeatureFlags } from "@/utils/features";

import ChartLibraryWrapper from "./ChartLibraryWrapper";
import FaultHistoryByVehicle from "./FaultHistoryByVehicle";
import VehicleCategoricalData from "./VehicleCategoricalData";
import { VehicleChartsSection } from "./VehicleChartsSection";
import { VehicleDetailsContainer } from "./VehicleDetails.styled";
import { VehicleHistoricDataMatrix } from "./VehicleHistoricDataMatrix";

export default function VehicleDetails() {
  const queryClient = useQueryClient();
  const customerId = getCurrentCustomerId();
  const { isFeatureEnabled } = useFeatureFlags();

  const { pvin } = Route.useParams();
  const hasMutatedRef = useRef("");

  const appendVisitedMutation = useAppendVisitedVehicleMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: useHomeQuery.getKey({}) });
    },
  });

  const { isLoading, data } = useVehicleDetailsQuery({ id: pvin }, { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME });

  useEffect(() => {
    // To prevent multiple calls
    if (hasMutatedRef.current != pvin) {
      appendVisitedMutation.mutate({ customerId: customerId!, vehicleId: pvin });
      hasMutatedRef.current = pvin;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pvin, appendVisitedMutation]);

  const vehicleDetails = data?.vehicleDetails;
  const hasVehicleDetails = vehicleDetails?.length;

  if (!isLoading && !hasVehicleDetails) {
    return <Page404 message="Vehicle Not Found" />;
  }

  return (
    <SCVContextProvider initialPvin={pvin}>
      <VehicleDetailsContainer>
        <VehicleCategoricalData isLoading={isLoading} vehicleDetails={vehicleDetails} />
        <VehicleChartsSection />
        <ChartLibraryWrapper />
        <FaultHistoryByVehicle />
        {isFeatureEnabled(FeatureId.HdmTables) && <VehicleHistoricDataMatrix />}
      </VehicleDetailsContainer>
    </SCVContextProvider>
  );
}
