import { useContext } from "react";

import AppLogo from "@/assets/Pull_Icon_2.png";
import { CustomerContext } from "@/contexts/CustomerProvider";

import { SourceContainer, SourceImage } from "./CustomerSource.styled";

type CustomerSourceProps = {
  customLogoUri?: string;
  customName?: string;
};

const CustomerSource = ({ customLogoUri, customName }: CustomerSourceProps) => {
  const customer = useContext(CustomerContext);
  const logoSrc = customLogoUri ?? customer.currentCustomer?.logoUri ?? AppLogo;
  const altValue = customName ?? customer.currentCustomer?.name ?? "Pull Systems";

  return (
    <SourceContainer>
      <SourceImage src={logoSrc} height={16} alt={altValue} />
      <span>{altValue}</span>
    </SourceContainer>
  );
};

export default CustomerSource;
