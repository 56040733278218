import { DEFAULT_PAGINATION } from "@lib/src/table/defaults";
import { useContext } from "react";

import { useAnomalySummaryQuery } from "@/api/customerApi";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";

type useAnomalySummaryWithDetectorDataProps = {
  anomalyDetectorId?: string;
};

const useAnomalySummaryWithDetectorData = ({ anomalyDetectorId }: useAnomalySummaryWithDetectorDataProps) => {
  //TODO: use something better
  const detectorIdTypeSpecific = anomalyDetectorId?.startsWith("ml-")
    ? anomalyDetectorId.substring(3)
    : anomalyDetectorId;
  const { globalFilter } = useContext(GlobalFilterContext);

  const { data, isLoading } = useAnomalySummaryQuery({
    filter: globalFilter,
    pagination: DEFAULT_PAGINATION,
    id: detectorIdTypeSpecific,
  });

  const anomalyData = data?.filteredQuery.anomaliesWithVehicleCount?.items?.find(
    (a) => a.detector.id === detectorIdTypeSpecific
  );

  return { anomalyData, isLoading };
};

export default useAnomalySummaryWithDetectorData;
