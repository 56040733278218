import styled from "styled-components";

export const BlockLoadingContainer = styled.div`
  background: linear-gradient(90deg, #fff 0%, #e2e2e2 50%, #fff 100%);
  background-size: 400% 400%;
  animation: loading 1s ease infinite;
  min-height: 250px;
  min-width: 4rem;
  border-radius: 10px;
  @keyframes loading {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  &.lean {
    min-height: 135px;
    min-width: 360px;
  }
  &.line {
    min-height: 1rem;
  }
  &.inline {
    min-height: 1rem;
    display: inline-flex;
    vertical-align: middle;
    margin: 0 1rem;
    &.no-margin {
      margin: 0;
    }
  }
  &.with-margin {
    margin: 1rem;
  }
`;
