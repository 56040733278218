import styled from "styled-components";

export const AnomalyVehicleListContainer = styled.div`
  .anomaly-occurrences-paginator {
    text-align: center;
    padding-bottom: 20px;
  }
`;

export const AnomalyDetectorTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
`;
