import { CommonDataDictionary, DataMatrixDefinition, DataMatrixNormalizations } from "../types/commonDataDictionary";

export const getHDMTableDefinitions = (customerIdentifier: CommonDataDictionary, tableId?: string) => {
  return customerIdentifier.dataMatrixTables?.filter((t) => (!tableId ? true : t.table_id === tableId));
};

export const getHDMTableNormalizations = (table?: DataMatrixDefinition) => {
  return [DataMatrixNormalizations.RAW, ...(table?.normalizations ?? [])];
};

export const getHDMComparionsAttributesDisplayLabel = (key: string) => {
  const hdmAttributeDict = {
    model: "Model",
    modelYear: "Model Year",
    deliveryCountry: "Delivery Country",
    batterySize: "Battery Size",
    aggregation: "Aggregation",
    repairType: "Workshop visit",
    DiagnosticCheckType: "FAP",
  } as const;
  return hdmAttributeDict[key as keyof typeof hdmAttributeDict];
};
