import { useState } from "react";

import { AggregateType } from "@/api";
import { BasicWidget } from "@/components/widget/BasicWidget";

import { HDMComparison } from "./HDMComparison";
import { HistoricDataMatrixHistoryChart } from "./HistoricDataMatrixHistoryChart";

export const HDMAllowedAggregateTypes = {
  Mean: AggregateType.Mean,
  Median: AggregateType.Median,
  Std: AggregateType.Std,
} as const;

export type HDMSelectedDiagnosticCheck = { diagnosticCheckId: string; label: string };
export const HistoricDataMatrix = () => {
  const [selectedDiagnosticCheck, setSelectedDiagnosticCheck] = useState<HDMSelectedDiagnosticCheck>();
  const [selectedTable, setSelectedTable] = useState<string | undefined>();

  return (
    <div>
      <BasicWidget title={"HDM History"} subtitle="Access HDM Values for the vehicle">
        <HistoricDataMatrixHistoryChart
          selectedDiagnosticCheck={selectedDiagnosticCheck}
          setSelectedDiagnosticCheck={setSelectedDiagnosticCheck}
          selectedTable={selectedTable}
          setSelectedTable={setSelectedTable}
        />
      </BasicWidget>
      {selectedDiagnosticCheck && selectedTable && (
        <HDMComparison selectedDiagnosticCheck={selectedDiagnosticCheck} selectedTableId={selectedTable} />
      )}
    </div>
  );
};
