import { useContext } from "react";

import { FeatureId } from "@/api";
import { AnomalyDetectorType, SingleVehicleAnomalyDetector } from "@/api/customerApi";
import AppLogo from "@/assets/Pull_Icon_2.png";
import CustomerIcon from "@/components/customerIcon/CustomerIcon";
import { PatternLink } from "@/components/pattern/PatternLink";
import FromNowFormatter from "@/components/ui/FromNowFormatter";
import { CustomerContext } from "@/contexts/CustomerProvider";
import { toDateObj } from "@/utils/dateUtils";
import { RequireFeatureFlag } from "@/utils/features";

import { AnomalyDetectorSummaryContainer } from "./AnomalyDetectorSummary.styled";

interface AnomalyDetectorSummaryProps {
  anomaly: SingleVehicleAnomalyDetector;
}

const AnomalyDetectorSummary = ({ anomaly }: AnomalyDetectorSummaryProps) => {
  const customer = useContext(CustomerContext);
  const buildModelSourceElement = (isCustomer: boolean, isML: boolean) => {
    if (isCustomer) {
      return (
        <>
          <RequireFeatureFlag id={FeatureId.CustomerProvidedAnomalyModels}>
            <CustomerIcon size={24} alt={`${customer.currentCustomer?.name}`} />
            &nbsp;
          </RequireFeatureFlag>
          ML
        </>
      );
    }
    if (isML) {
      return (
        <>
          <RequireFeatureFlag id={FeatureId.CustomerProvidedAnomalyModels}>
            <img src={AppLogo} width={24} alt="Pull Systems" />
            &nbsp;
          </RequireFeatureFlag>
          ML
        </>
      );
    }
    return "Pattern";
  };

  const {
    vehicleData: { firstDetected, anomalousDaysCount },
  } = anomaly;

  const expression = anomaly.detector.expression;

  return (
    <AnomalyDetectorSummaryContainer>
      <div className="detector-data-container">
        {firstDetected ? (
          <div className="detector-data-item">
            <p className="body-medium status-label">Time since first detection</p>
            <p className="body-medium status-label bold">
              <FromNowFormatter value={toDateObj(firstDetected)} />
            </p>
          </div>
        ) : null}
        {anomalousDaysCount ? (
          <div className="detector-data-item">
            <p className="body-medium status-label">Total Anomalous Day(s)</p>
            <p className="body-medium status-label bold">{Math.floor(anomalousDaysCount)}</p>
          </div>
        ) : null}
        <div className="detector-data-item">
          <p className="body-medium status-label">Author</p>
          <p className="body-medium status-label bold">{anomaly?.detector?.creator?.name || "Pull Systems"}</p>
        </div>
        {!anomaly.detector.isCustomerMLModel ? (
          <div className="detector-data-item">
            <p className="body-medium status-label">Type</p>
            <p className="body-medium status-label bold">
              {buildModelSourceElement(
                !!anomaly.detector.isCustomerMLModel,
                anomaly.detector.isCustomerMLModel || anomaly.detector.type != AnomalyDetectorType.Pattern
              )}
            </p>
          </div>
        ) : undefined}
        {anomaly.detector.type === "Pattern" && expression && (
          <div className="detector-data-item">
            <p className="body-medium status-label">Pattern</p>
            <PatternLink id={expression.id} name={expression.name} />
          </div>
        )}
      </div>
    </AnomalyDetectorSummaryContainer>
  );
};

export default AnomalyDetectorSummary;
