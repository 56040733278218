import { Button, Select } from "antd";
import { EChartsType } from "echarts";
import EChartsReact from "echarts-for-react";
import i18next from "i18next";
import _ from "lodash";
import { Dispatch, RefObject, SetStateAction, useContext, useEffect, useRef, useState } from "react";

import { TimeSeriesIndependentVar, useHdmTableAggregateValueQuery } from "@/api/customerApi";
import TimeSeriesVariableSelector from "@/components/timeSeries/TimeSeriesVariableSelector";
import { BaseEChart } from "@/components/ui/BaseEChart";
import { QUERY_SETTINGS, UI_SETTINGS } from "@/constants";
import SCVContext from "@/contexts/SCVContext";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { getHDMTableDefinitions } from "@/utils/historicDataMatrix/hdmUtils";
import { DataMatrixAggregations } from "@/utils/types/commonDataDictionary";
import { AxisFields, DataZoomFields } from "@/utils/types/EChartsDefinitions";

import {
  HDMChartHeader,
  HDMHistoryChartLegendContainer,
  HDMHistoryChartWrapper,
  HDMHistoryHeader,
  HDMSelectWrapper,
} from "./HDM.styled";
import { HDMHistoryChartTooltip } from "./HDMHistoryChartToolTip";
import { getHDMHistoryChartConfig } from "./HDMHistoryChartUtils";
import { HDMSelectedDiagnosticCheck } from "./HistoricDataMatrix";

export const initialHDMHistoryChartTooltipDataElementId = "hdm-history-data-holder";

type HistoricDataMatrixHistoryChartProps = {
  selectedDiagnosticCheck?: HDMSelectedDiagnosticCheck;
  setSelectedDiagnosticCheck: Dispatch<HDMSelectedDiagnosticCheck | undefined>;
  selectedTable: string | undefined;
  setSelectedTable: Dispatch<SetStateAction<string | undefined>>;
};

export const initChartZoomMode = (chart?: EChartsType) => {
  chart?.dispatchAction({
    type: "takeGlobalCursor",
    key: "dataZoomSelect",
    dataZoomSelectActive: true,
  });
};

const tooltipPortalTargetId = "toolTipPortalTargetId";

export const HistoricDataMatrixHistoryChart = ({
  selectedDiagnosticCheck,
  setSelectedDiagnosticCheck,
  selectedTable,
  setSelectedTable,
}: HistoricDataMatrixHistoryChartProps) => {
  const { pvin } = useContext(SCVContext);
  const { customerIdentifier } = useCustomerIdentifier();
  const tables = getHDMTableDefinitions(customerIdentifier);
  const selectedTableDefintion = tables?.find((table) => table.table_id === selectedTable);
  const chartRef = useRef<EChartsReact>();

  const [xAxis, setXAxis] = useState<{ label: string; key: TimeSeriesIndependentVar }>({
    key: TimeSeriesIndependentVar.Time,
    label: "Time",
  });
  const [zoom, setZoom] = useState<{ min: number; max: number }>();
  useEffect(() => {
    setSelectedTable(tables?.[0].table_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, isLoading, isFetching } = useHdmTableAggregateValueQuery(
    { pvin, tableId: selectedTable ?? "", zoom: zoom ? { ...zoom, independentVariable: xAxis.key } : undefined },
    { enabled: !!selectedTable, staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME }
  );

  const handleXAxisChange = (key: TimeSeriesIndependentVar) => {
    if (key === TimeSeriesIndependentVar.Time) {
      setXAxis({ label: "Time", key });
    } else {
      setXAxis({ label: "Odometer", key });
    }
    setZoom(undefined);
  };

  const getTableLegend = () => {
    if (!selectedTableDefintion) return;
    const splitId = selectedTableDefintion.name.split(":");
    return splitId[1];
  };

  const onChartClick = (params: {
    componentType: string;
    data: { value: [number, number, typeof selectedDiagnosticCheck] };
  }) => {
    if (params.componentType === "series") {
      setSelectedDiagnosticCheck(params.data.value[2]);
    }
  };

  const handleZoomReset = () => {
    setZoom(undefined);
  };

  const zoomEventHandler = _.debounce((chart?: EChartsReact) => {
    const chartOptions = chart?.getEchartsInstance().getOption();
    if (chartOptions) {
      const dataZoom = chartOptions.dataZoom as DataZoomFields[];
      const xAxis = chartOptions.xAxis as AxisFields[];
      if (dataZoom.length > 0 && xAxis.length > 0) {
        const zoomStart = Math.floor(dataZoom[0].startValue);
        const zoomEnd = Math.round(dataZoom[0].endValue);

        if (zoomStart && zoomEnd && zoomEnd > zoomStart) {
          setZoom({ min: zoomStart, max: zoomEnd });
        }
      }
    }
  }, UI_SETTINGS.SEARCH_INPUT_DEBOUNCE);

  return (
    <HDMHistoryChartWrapper>
      <HDMHistoryHeader>
        <HDMSelectWrapper>
          <h4>HDM Table</h4>
          <Select
            value={selectedTable}
            options={tables?.map((t) => ({ label: t.name, value: t.table_id }))}
            defaultValue={tables?.[0].table_id}
            onChange={(tableId) => {
              setSelectedTable(tableId);
            }}
          />
        </HDMSelectWrapper>
        <HDMSelectWrapper>
          <h4>{i18next.t("columnTitle.DIAGNOSTIC_CHECK_ID")} Reading</h4>
          <Select
            options={data?.aggregateHDMTableValues.map((table) => ({
              label: `${i18next.t("columnTitle.DIAGNOSTIC_CHECK_ID")}: ${table.timestamp} (${table.diagnosticCheckId})`,
              value: table.diagnosticCheckId,
            }))}
            value={selectedDiagnosticCheck?.diagnosticCheckId}
            onChange={(diagnosticCheck) => {
              const dc = data?.aggregateHDMTableValues.find((table) => table.diagnosticCheckId === diagnosticCheck);
              setSelectedDiagnosticCheck({
                label: `${i18next.t("columnTitle.DIAGNOSTIC_CHECK_ID")}: ${dc?.timestamp} (${dc?.diagnosticCheckId})`,
                diagnosticCheckId: dc?.diagnosticCheckId ?? "",
              });
            }}
            placeholder={`Select ${i18next.t("columnTitle.DIAGNOSTIC_CHECK_ID")} Reading`}
            loading={isLoading || isFetching}
            style={{ width: "600px" }}
            disabled={isLoading || isFetching}
          />
        </HDMSelectWrapper>
      </HDMHistoryHeader>
      <div className="hdm-chart">
        <HDMHistoryChartTooltip
          portalTargetId={tooltipPortalTargetId}
          aggregate={DataMatrixAggregations.MEAN}
          tableDefinition={selectedTableDefintion}
          rerenderProp={chartRef.current?.getEchartsInstance()}
        />
        <HDMChartHeader>
          <div className="title">
            <h3 className="heading-x-small">Vehicle History</h3>
          </div>
          <div className="hdm-chart-header-buttons">
            <Button type="text" className="text-button" size="small" onClick={handleZoomReset} disabled={!zoom}>
              Reset Zoom
            </Button>
            <TimeSeriesVariableSelector currentVariable={xAxis.key} onChange={handleXAxisChange} />
          </div>
        </HDMChartHeader>
        <HDMHistoryChartLegendContainer>
          <div className="legend-cirlce"></div>
          <p className="body-small">
            {selectedTableDefintion?.default_table_aggregation} {getTableLegend()}
          </p>
        </HDMHistoryChartLegendContainer>
        <BaseEChart
          ref={chartRef as RefObject<EChartsReact>}
          option={{
            ...getHDMHistoryChartConfig({
              xAxis,
              data,
              zoom,
              selectedDiagnosticCheck,
              selectedTableDefintion,
              tooltipPortalTargetId: tooltipPortalTargetId,
            }),
          }}
          showLoading={isLoading}
          onEvents={{
            click: onChartClick,
            datazoom: () => {
              zoomEventHandler(chartRef.current);
            },
          }}
          onChartReady={(chart) => {
            initChartZoomMode(chartRef.current?.getEchartsInstance() || chart);
          }}
        />
      </div>
    </HDMHistoryChartWrapper>
  );
};
