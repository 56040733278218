import { useQueryClient } from "@tanstack/react-query";
import { Link, useNavigate } from "@tanstack/react-router";
import { Button, notification, Popconfirm, Skeleton } from "antd";
import { Modal } from "antd/lib";

import { useDeleteExpressionAnomalyMutation } from "@/api";
import CustomerSource from "@/components/customerSource/CustomerSource";
import { PatternLink } from "@/components/pattern/PatternLink";
import EntityCreatorElement from "@/components/ui/EntityCreatorElement";
import FromNowFormatter from "@/components/ui/FromNowFormatter";
import InlineLoadingSkeleton from "@/components/ui/InlineLoadingSkeleton";
import { BasicWidget } from "@/components/widget/BasicWidget";

import {
  DetectorAttributeContainer,
  DetectorDescriptionContainer,
  DetectorHeaderContainer,
  DetectorTitleContainer,
} from "./AnomalyDetailsHeader.styled";
import { AnomalyDetectorWithSimplePatternData } from "./AnomalyDetailsPage";
import { handleDeleteAnomaly } from "./handleDeleteAnomaly";
import useAnomalySummaryWithDetectorData from "./useAnomalySummaryWithDetectorData";

type AnomalyDetailsHeaderProps = {
  id: string;
};

const AnomalyDetailsHeader = ({ id }: AnomalyDetailsHeaderProps) => {
  const { anomalyData, isLoading } = useAnomalySummaryWithDetectorData({ anomalyDetectorId: id });
  const detectorData = anomalyData?.detector satisfies AnomalyDetectorWithSimplePatternData | undefined;

  const deleteAnomaly = useDeleteExpressionAnomalyMutation();

  const [modal, contextHolder] = Modal.useModal();
  const queryClient = useQueryClient();
  const [notificationApi, notificationContextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const expression = detectorData?.expression;
  const isEditIconShown = !!expression;
  const isDeletable = expression && detectorData?.creator?.isMe;

  const onDelete = () => {
    try {
      if (!detectorData) return;
      const { id, version } = detectorData;
      handleDeleteAnomaly(modal, notificationApi, id, version, deleteAnomaly, navigate, queryClient);
    } catch (error) {
      notificationApi.error({ message: "Invalid Anomaly Detector" });
    }
  };

  const description = expression ? (
    <DetectorDescriptionContainer className="detector-description-container">
      <div className="detector-description body-medium">{detectorData?.description}</div>
      <div className="pattern-link-container body-medium dark">
        <b>Source Pattern:</b>
        <PatternLink name={expression.name ?? ""} id={expression.id} />
      </div>
    </DetectorDescriptionContainer>
  ) : (
    <div className="detector-description body-medium">{detectorData?.description}</div>
  );

  return (
    <>
      {contextHolder}
      {notificationContextHolder}
      <BasicWidget hideHeader>
        <DetectorHeaderContainer className="detector-header-container">
          <DetectorTitleContainer className="detector-title-container">
            {!isLoading ? (
              <>
                <h1 className="heading-normal">{detectorData?.name}</h1>
                {description}
              </>
            ) : (
              <Skeleton active title />
            )}
          </DetectorTitleContainer>
          {detectorData?.isCustomerMLModel ? (
            <DetectorAttributeContainer className="detector-attr">
              <span className="attr-label body-small">Source</span>
              <CustomerSource />
            </DetectorAttributeContainer>
          ) : undefined}
          <DetectorAttributeContainer className="detector-attr">
            <span className="attr-label body-small">Author</span>
            {!isLoading ? <EntityCreatorElement creator={detectorData?.creator} /> : <InlineLoadingSkeleton />}
          </DetectorAttributeContainer>
          <DetectorAttributeContainer className="detector-attr">
            <span className="attr-label body-small">ID</span>

            {!isLoading ? (
              <span className="body-medium dark text-overflowing">{detectorData?.id ?? "-"}</span>
            ) : (
              <InlineLoadingSkeleton />
            )}
          </DetectorAttributeContainer>
          <DetectorAttributeContainer className="detector-attr">
            <span className="attr-label body-small">Version</span>

            {!isLoading ? (
              <span className="attr-value body-medium dark">
                {detectorData?.externalVersion
                  ? `V${detectorData.externalVersion}`
                  : detectorData?.version
                  ? `V${detectorData.version}`
                  : "-"}
              </span>
            ) : (
              <InlineLoadingSkeleton />
            )}
          </DetectorAttributeContainer>
          <DetectorAttributeContainer className="detector-attr">
            <span className="attr-label body-small">Last Update</span>

            {!isLoading ? (
              <span className="attr-value body-medium dark">
                {detectorData?.lastModified ? (
                  <FromNowFormatter value={new Date(Date.parse(detectorData.lastModified))} />
                ) : (
                  "-"
                )}
              </span>
            ) : (
              <InlineLoadingSkeleton />
            )}
          </DetectorAttributeContainer>
          <div className="ctas-container">
            {isEditIconShown && (
              <Link className="detector-cta" to="/anomaly-detector/$detectorId/edit" params={{ detectorId: id }}>
                <Button className="text-button cta-btn" type="text">
                  Edit
                </Button>
              </Link>
            )}
            {isDeletable && (
              <Popconfirm
                title="Are you sure you want to delete this detector?"
                okText="Yes"
                cancelText="No"
                onConfirm={onDelete}
              >
                <Button className="text-button cta-btn" type="text">
                  Delete
                </Button>
              </Popconfirm>
            )}
          </div>
        </DetectorHeaderContainer>
      </BasicWidget>
    </>
  );
};

export default AnomalyDetailsHeader;
