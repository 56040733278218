import { InfoCircleOutlined } from "@ant-design/icons";
import EChartsReact from "echarts-for-react";
import _ from "lodash";
import { RefObject, useEffect, useRef, useState } from "react";

import { FleetMapContainer, RoamOverlay } from "@/components/fleetMap/FleetMap.styled";
import { UI_SETTINGS } from "@/constants";

import { BaseEChart } from "../ui/BaseEChart";
import { buildFleetMapChartOptions } from "./FleetMapUtils";

const MIN_VIEWPORT_WIDTH = 1366;
const MAX_VIEWPORT_WIDTH = 2560;
const MIN_ZOOM_VALUE = 1.3;
const MAX_ZOOM_VALUE = 2.9;

const calculateZoomValue = (): number => {
  const viewportWidth = window.innerWidth;
  const clampedViewportWidth = Math.min(Math.max(viewportWidth, MIN_VIEWPORT_WIDTH), MAX_VIEWPORT_WIDTH);
  const normalizedValue = (clampedViewportWidth - MIN_VIEWPORT_WIDTH) / (MAX_VIEWPORT_WIDTH - MIN_VIEWPORT_WIDTH);

  return MIN_ZOOM_VALUE + normalizedValue * (MAX_ZOOM_VALUE - MIN_ZOOM_VALUE);
};

export type FleetMapVehicleCount = {
  country?: string;
  vehicles?: number;
};

interface FleetMapParams {
  data?: FleetMapVehicleCount[];
  selectedCountry?: string;
  onCountrySelected?: (country: string) => void;
  isLoading: boolean;
}

export default function FleetMap({ data, selectedCountry, onCountrySelected, isLoading }: FleetMapParams) {
  const [mapActive, setMapActive] = useState(false);
  const [modifierKeyPressed, setModifierKeyPressed] = useState(false);

  const [mapZoom, setMapZoom] = useState(calculateZoomValue());
  const mapRef = useRef<EChartsReact>();

  const onElementSelected = (params: any) => {
    const isNonEmptyCountry = data?.find((v) => params.data?.name == v?.country)?.vehicles;
    if (isNonEmptyCountry) {
      if (params.data?.name) {
        return onCountrySelected?.(params.data.name !== selectedCountry ? params.data.name : "");
      }
    } else {
      return onCountrySelected?.("");
    }
  };

  const handleMapMouseOver = () => {
    setMapActive(true);
  };

  const handleMapMouseOut = () => {
    setMapActive(false);
  };

  const handleMapKeyDown = (e: KeyboardEvent) => {
    if (e.shiftKey && !modifierKeyPressed) setModifierKeyPressed(true);
  };

  const handleMapKeyUp = () => {
    setModifierKeyPressed(false);
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === "hidden") {
      setModifierKeyPressed(false);
      setMapActive(false);
    }
  };

  const handleResize = _.debounce(() => {
    setMapZoom(calculateZoomValue());
  }, UI_SETTINGS.STANDARD_INPUT_DEBOUNCE);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleMapKeyDown);
    document.addEventListener("keyup", handleMapKeyUp);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("keydown", handleMapKeyDown);
      document.removeEventListener("keyup", handleMapKeyUp);
      document.addEventListener("visibilitychange", handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMapReady = () => {
    const mapInstance = mapRef.current?.getEchartsInstance();
    if (mapInstance) {
      if (!selectedCountry) {
        mapInstance.dispatchAction({
          type: "toggleSelect",
        });
      } else {
        mapInstance.dispatchAction({
          type: "select",
          name: selectedCountry,
        });
      }
    }
  };

  const chartConfig = buildFleetMapChartOptions(mapZoom, data, modifierKeyPressed && mapActive);

  const overlayClassName = `roam-overlay ${modifierKeyPressed ? " roaming" : ""}`;

  return (
    <FleetMapContainer className="fleet-intel-map" onMouseOver={handleMapMouseOver} onMouseOut={handleMapMouseOut}>
      {mapActive ? (
        <RoamOverlay className={overlayClassName}>
          <div className="overlay-body">
            <span className="body-medium">
              <InfoCircleOutlined /> {modifierKeyPressed ? "Roam is active" : "Hold shift to roam the map"}
            </span>
          </div>
        </RoamOverlay>
      ) : undefined}
      <BaseEChart
        {...chartConfig}
        className="echarts fleet-map-chart"
        ref={mapRef as RefObject<EChartsReact>}
        onChartReady={handleMapReady}
        onEvents={{ click: onElementSelected }}
        showLoading={isLoading}
        notMerge={true}
      />
    </FleetMapContainer>
  );
}
