import styled from "styled-components";

export const HDMCoparisonWrapper = styled.div`
  padding: 0.75rem;
  border-radius: 0.75rem;
  .hdm-heatmap-tooltip::after {
    content: "";
    position: absolute;
    top: 50%;
    left: -10px; /* Position the arrow on the left side */
    transform: translateY(-50%); /* Align vertically to the middle */
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #ffffff; /* Arrow pointing left */
  }
`;

export const HDMComparisonTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  .widget-title {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
`;

export const HDMComparisonContainer = styled.div`
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;
