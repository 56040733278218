import { UndoOutlined } from "@ant-design/icons";
import { Button } from "antd";
import EChartsReact from "echarts-for-react";
import { RefObject, useRef } from "react";

import { BaseEChart } from "@/components/ui/BaseEChart";

import { HeatMapContainer, HeatmapResetContainer } from "./HDMHeatMap.styled";
import { buildHDMHeatMapConfig } from "./HeatMapUtils";

const MAX_CELLS = 6;
const MAX_MODULES = 33;

type HDMHeatMapProps = {
  values: number[];
  isLoading: boolean;
  max: number;
  min: number;
  batterySize?: number;
  getDisplayValues: (index: number) => string;
  comparisonMode?: boolean;
};

const HDMHeatMap = ({
  values,
  isLoading,
  min,
  max,
  batterySize,
  getDisplayValues,
  comparisonMode,
}: HDMHeatMapProps) => {
  const chartRef = useRef<EChartsReact>();

  const chartConfig = buildHDMHeatMapConfig({
    data: values,
    cellCount: MAX_CELLS,
    moduleCount: batterySize ?? MAX_MODULES,
    minValue: values.length ? min : 0,
    maxValue: values.length ? max : 0,
    getDisplayValues: getDisplayValues,
    comparisonMode,
  });

  const handleResetHeatMap = () => {
    chartRef.current?.getEchartsInstance().dispatchAction({
      type: "selectDataRange",
      selected: [min, max],
    });
  };

  const containerClassName = `heatmap-container`;
  return (
    <HeatMapContainer className={containerClassName}>
      <BaseEChart
        ref={chartRef as RefObject<EChartsReact>}
        className="hdm-heatmap"
        {...chartConfig}
        showLoading={isLoading}
        showEmptyChart={false}
        showLoadingMask={true}
        notMerge
        lazyUpdate
        style={{ width: "100%" }}
      />
      <HeatmapResetContainer>
        <Button
          type="text"
          className="reset-button"
          size="small"
          icon={<UndoOutlined className="reset-icon" />}
          onClick={handleResetHeatMap}
        />
      </HeatmapResetContainer>
    </HeatMapContainer>
  );
};

export default HDMHeatMap;
