import { useContext, useId, useState } from "react";

import { TimeSeriesIndependentVar } from "@/api";
import { CalculatedStatus, useAnomalySampleVehiclesQuery } from "@/api/customerApi";
import TimeSeriesVariableSelector from "@/components/timeSeries/TimeSeriesVariableSelector";
import { BaseEChart } from "@/components/ui/BaseEChart";
import HelperTooltipWithIcon from "@/components/ui/HelperTooltipWithIcon";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { QUERY_SETTINGS } from "@/constants";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { intitialDataAttributeName } from "@/hooks/usePortalTargetObserver";
import { toArray } from "@/utils/arrayUtils";
import { EchartsTooltipFormatterFn } from "@/utils/types/EChartsDefinitions";

import AnomalyTooltipVehicleDetail, { VehiclePointBaseData } from "./AnomalyTooltipVehicleDetail";
import { AnalysisChartContainer, AnalysisTitleContainer } from "./AnomalyVehicleAnalysis.styled";
import {
  buildScatterChartConfig,
  buildScatterPoints,
  ScatterParam,
  unknownDatapointConverter,
} from "./ScatterChartUtils";
import useAnomalySummaryWithDetectorData from "./useAnomalySummaryWithDetectorData";

export const initialTooltipDataElementId = "vehicle-data-holder";

type AnomalyVehicleAnalysisProps = {
  id: string;
};

const AnomalyVehicleAnalysis = ({ id }: AnomalyVehicleAnalysisProps) => {
  const { globalFilter } = useContext(GlobalFilterContext);
  const [indVar, setIndVar] = useState(TimeSeriesIndependentVar.Time);
  const tooltipPortalTargetId = useId();

  const { isLoading: detectorLoading } = useAnomalySummaryWithDetectorData({
    anomalyDetectorId: id,
  });

  // Sample 300 vehicles for the scatterplot
  const { data, isLoading: vehiclesLoading } = useAnomalySampleVehiclesQuery(
    {
      anomalyId: id,
      anomalyStatus: CalculatedStatus.Available,
      sampleSize: 300,
      filter: globalFilter,
    },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME, keepPreviousData: false }
  );

  const vehicles = data?.filteredQuery.anomalySampleVehicles.data ?? [];

  const handleVariableSelected = (selectedVar: TimeSeriesIndependentVar) => {
    setIndVar(selectedVar);
  };

  const onTooltip: EchartsTooltipFormatterFn = (params, _ticket, _callback) => {
    const renderParams = toArray(params);
    return renderParams
      .map((param) => {
        const currentParam = param as ScatterParam;
        const [x, y, maybeDataPoint] = currentParam.value;
        const dataPoint = unknownDatapointConverter(maybeDataPoint);

        if (!dataPoint) return "";

        const vehicleBaseData: VehiclePointBaseData = {
          x,
          y,
          dataPoint,
        };

        return `<div class="tooltip-container">
          <div id="${initialTooltipDataElementId}" ${intitialDataAttributeName}=${JSON.stringify(
          vehicleBaseData
        )}></div>
          <div id=${tooltipPortalTargetId} class="portal-target"/>
        </div>
        `;
      })
      .join("\n");
  };

  const chartConfig = buildScatterChartConfig({
    seriesData: vehiclesLoading && detectorLoading ? [] : buildScatterPoints(indVar, vehicles),
    indVar,
    onTooltip,
  });

  const titleElement = (
    <AnalysisTitleContainer>
      <div className="widget-title">
        Analysis
        <HelperTooltipWithIcon message="Use the filters to customize this scatter plot and compare individual anomaly cases." />
      </div>
      <TimeSeriesVariableSelector currentVariable={indVar} onChange={handleVariableSelected} size="middle" />
    </AnalysisTitleContainer>
  );

  return (
    <BasicWidget title={titleElement}>
      <AnomalyTooltipVehicleDetail variable={indVar} portalTargetId={tooltipPortalTargetId} />
      <AnalysisChartContainer className="vehicle-detection-analysis-container">
        <BaseEChart {...chartConfig} showLoading={vehiclesLoading && detectorLoading} />
      </AnalysisChartContainer>
    </BasicWidget>
  );
};

export default AnomalyVehicleAnalysis;
