import { useAuth0 } from "@auth0/auth0-react";
import { lazy, Suspense, useContext, useEffect, useState } from "react";

import Loading from "@/components/loading";
import { CustomerContext } from "@/contexts/CustomerProvider";

// Lazy load the Apollo Sandbox
const ApolloSandboxComponent = lazy(() =>
  import("@apollo/sandbox/react").then((module) => ({
    default: module.ApolloSandbox,
  }))
);

export function ApiSandbox({ isCustomer, isAdmin }: { isCustomer?: boolean; isAdmin?: boolean }) {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState<string>();

  useEffect(() => {
    getAccessTokenSilently().then((tok) => {
      setToken(tok);
    });
  }, [getAccessTokenSilently]);

  let url = import.meta.env.VITE_API_URL;
  const customerContext = useContext(CustomerContext);
  if (isAdmin) {
    let u = new URL(url);
    u.pathname = "/admin-api";
    url = u.toString();
  }
  if (isCustomer) {
    url = customerContext?.currentCustomer?.customApiUrl || `${import.meta.env.VITE_API_URL}/customer"`;
  }

  if (token) {
    return (
      <Suspense fallback={<Loading />}>
        <ApolloSandboxComponent
          initialEndpoint={url}
          initialState={{
            headers: {
              authorization: `Bearer ${token}`,
            },
            sharedHeaders: {
              authorization: `Bearer ${token}`,
            },
          }}
          className="api-sandbox"
        />
      </Suspense>
    );
  }

  return <div />;
}
