import { AnomalyDetector, CalculatedStatus } from "@/api";
import { AnomalyDetectorType } from "@/api/customerApi";
import AnomalyVehicleList from "@/features/anomaly/details/AnomalyVehicleList";
import { Route } from "@/routes/anomaly-detector.$detectorId";

import AnomalyDetailsHeader from "./AnomalyDetailsHeader";
import { AnomalyDetailsLayout } from "./AnomalyDetailsPage.styled";
import AnomalyFleetSummary from "./AnomalyFleetSummary";
import AnomalyVehicleAnalysis from "./AnomalyVehicleAnalysis";
import useAnomalySummaryWithDetectorData from "./useAnomalySummaryWithDetectorData";

export type AnomalyDetectorWithSimplePatternData = Omit<AnomalyDetector, "expression"> & {
  expression?: {
    id: string;
    name?: string;
  } | null;
};

const AnomalyDetailsPage = () => {
  const { detectorId } = Route.useParams();
  const { anomalyData, isLoading } = useAnomalySummaryWithDetectorData({ anomalyDetectorId: detectorId });

  const isPatternAnomaly = !!anomalyData?.detector.expression;
  // Ml and CustomerProvided have no status, this defaults to Available to reuse API resolvers
  const anomalyStatus =
    !isLoading &&
    (anomalyData?.detector.type === AnomalyDetectorType.Ml ||
      anomalyData?.detector.type === AnomalyDetectorType.CustomerProvided)
      ? CalculatedStatus.Available
      : anomalyData?.detector.status ?? undefined;

  return (
    <AnomalyDetailsLayout>
      <AnomalyDetailsHeader id={detectorId} />
      <AnomalyVehicleAnalysis id={detectorId} />
      <AnomalyFleetSummary id={detectorId} status={anomalyStatus} />
      {/* <AnomalySeverityConfiguration id={detectorId} />  TODO AMAYA-4497 */}
      <AnomalyVehicleList
        isParentLoading={isLoading}
        id={detectorId}
        status={anomalyStatus}
        showStatus={isPatternAnomaly}
      />
    </AnomalyDetailsLayout>
  );
};

export default AnomalyDetailsPage;
