import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useTheme } from "styled-components";

type HelperTooltipWithIconProps = {
  message: string;
};

const HelperTooltipWithIcon = ({ message }: HelperTooltipWithIconProps) => {
  const theme = useTheme();

  return (
    <Tooltip title={message} placement="topLeft">
      <QuestionCircleOutlined style={{ color: theme.colors.blueGray, fontSize: "1rem" }} />
    </Tooltip>
  );
};

export default HelperTooltipWithIcon;
