import { CommonDataDictionary } from "../types/commonDataDictionary";

export const getFaultDescription = (
  customerIdentifier: CommonDataDictionary,
  code: string,
  ecu: string,
  troubleCode: string
) => {
  // Attempts to find a name with the full combo only if all params are truthy
  if (!!code && !!troubleCode && !!ecu) {
    const fullcombo = customerIdentifier.faultCodes?.find(
      (faultCode) => faultCode.faultCode === code && ecu === faultCode.ecu && troubleCode === faultCode.troubleCode
    );
    if (fullcombo) return fullcombo;
  }

  // Attempts to find a name with no regard for faultCode
  const descriptionRegardlessFaultCode = customerIdentifier.faultCodes?.find(
    (row) => !row.faultCode && row.troubleCode === troubleCode && row.ecu === ecu
  );

  if (descriptionRegardlessFaultCode) return descriptionRegardlessFaultCode;

  // Attempts to find a name with no regard for troubleCode
  const descriptionRegardlessTroubleCode = customerIdentifier.faultCodes?.find(
    (row) => !row.troubleCode && row.faultCode === code && row.ecu === ecu
  );

  if (descriptionRegardlessTroubleCode) return descriptionRegardlessTroubleCode;
};
