import ReactECharts, { EChartsReactProps } from "echarts-for-react";
import EChartsReact from "echarts-for-react";
import { forwardRef, useId } from "react";

import { BaseEChartEmptyWrapper } from "./BaseEChart.styled";
import { EmptyComponent } from "./EmptyComponent";
import { LoadingWrapper } from "./LoadingWrapper";

export type BaseEChartProps = EChartsReactProps & {
  showLoadingMask?: boolean;
  showEmptyChart?: boolean;
  minWidthLoadingMask?: boolean;
};

const hasDataInSeries = (series: any): boolean => {
  return Array.isArray(series.data) && series.data.length > 0;
};

const checkForData = (args: EChartsReactProps): boolean => {
  const series = args?.option?.series;
  if (!series) return false;

  if (Array.isArray(series)) {
    return series.length > 0 && series.map((serie) => hasDataInSeries(serie)).some((hasDataBool) => !!hasDataBool);
  }

  return hasDataInSeries(series);
};

// eslint-disable-next-line react/display-name
export const BaseEChart = forwardRef<EChartsReact, BaseEChartProps>((props, ref) => {
  const chartElementClassId = useId();

  const { className, showLoading, loadingOption, showLoadingMask, showEmptyChart, minWidthLoadingMask, ...args } =
    props;

  const chartPseudoId = `chart-${chartElementClassId}`;
  const chartClassnames = `${className} ${chartPseudoId}`;

  const hasData = checkForData(args);

  return (
    <LoadingWrapper
      isLoading={!!showLoading}
      showLoadingMask={showLoadingMask}
      minWidthLoadingMask={minWidthLoadingMask}
    >
      {!hasData && !showLoading && !showEmptyChart && (
        <BaseEChartEmptyWrapper>
          <EmptyComponent />
        </BaseEChartEmptyWrapper>
      )}
      <ReactECharts
        className={chartClassnames}
        ref={ref}
        {...args}
        loadingOption={
          loadingOption ?? {
            text: "",
            showSpinner: false,
          }
        }
      />
    </LoadingWrapper>
  );
});
