import { Flex, Skeleton, Typography } from "antd";
import { ReactElement } from "react";

import { TitleHeaderContainer } from "./PageContent.styled";

export type TopHeadingWithButtonsProps = {
  title: ReactElement | string | undefined | null | false;
  buttons?: ReactElement | string | undefined | null | false;
  description?: ReactElement | string | undefined | null | false;
  isLoading?: boolean;
};

export const TopHeadingWithButtons = ({ title, buttons, description, isLoading }: TopHeadingWithButtonsProps) => {
  return (
    <TitleHeaderContainer>
      <Flex justify="space-between" vertical gap={"0.5rem"}>
        <Typography.Title className="heading-large" level={2}>
          {!isLoading ? title : <Skeleton.Input />}
        </Typography.Title>
        {description ? (
          <Typography.Text type="secondary">{description}</Typography.Text>
        ) : isLoading ? (
          <Skeleton.Input style={{ width: 350 }} />
        ) : undefined}
      </Flex>
      <Flex justify="flex-end" align="center" gap={"0.5rem"}>
        {buttons}
      </Flex>
    </TitleHeaderContainer>
  );
};
