import { QuestionCircleOutlined } from "@ant-design/icons";
import { Link } from "@tanstack/react-router";
import { Tooltip } from "antd";
import { useContext } from "react";
import { useTheme } from "styled-components";

import { SortOrder } from "@/api";
import {
  AnomaliesWithVehicleCountSortField,
  AnomalyDetectorWithVehicleData,
  useAnomalySummaryQuery,
} from "@/api/customerApi";
import { defaultPagination } from "@/components/tables/defaultPagination";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { TitleWithTooltipContainer } from "@/components/widget/BasicWidget.styled";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";

import { BasicTable } from "../../../components/tables/BasicTable";
import { anomalySummaryCommonColumns } from "./anomalySummaryCommonColumns";

export const AnomalySummaryTableTop10 = () => {
  const theme = useTheme();
  const { globalFilter } = useContext(GlobalFilterContext);
  const { data, isLoading } = useAnomalySummaryQuery({
    filter: globalFilter,
    sorting: { field: AnomaliesWithVehicleCountSortField.AnomalyVehicleCount, order: SortOrder.Descend },
    pagination: defaultPagination,
  });
  const anomalies = (data?.filteredQuery.anomaliesWithVehicleCount.items || []) as AnomalyDetectorWithVehicleData[];

  return (
    <BasicWidget
      className="table"
      title={
        <TitleWithTooltipContainer>
          <div>Top Anomaly Detectors</div>
          <Tooltip
            title="List of Anomaly Detectors with vehicles where the anomaly score is currently above Caution (yellow) or Warning (red) thresholds."
            placement="topLeft"
          >
            <QuestionCircleOutlined style={{ color: theme.colors.blueGray, fontSize: "1rem" }} />
          </Tooltip>
        </TitleWithTooltipContainer>
      }
      extra={<Link to="/anomaly-detector">View All</Link>}
    >
      <BasicTable
        dataSource={anomalies}
        columns={anomalySummaryCommonColumns}
        rowKey={(anomaly) => anomaly.detector.id}
        pagination={false}
        loading={isLoading}
      />
    </BasicWidget>
  );
};
