import styled from "styled-components";

export const ComparisonFormWrapper = styled.div`
  padding: 1.5rem;
  .comparison-by-selector {
    .ant-segmented-item {
      color: ${({ theme }) => theme.colors.blueGray};
      .ant-segmented-item-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        .ant-segmented-item-icon {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
      &.ant-segmented-item-selected {
        color: ${({ theme }) => theme.colors.accentBlue};
      }
      &:hover {
        color: ${({ theme }) => theme.colors.accentBlue} !important;
      }
    }
  }
`;

export const ComparisonFormContainer = styled.form`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  .submit-form-btn {
    max-width: 250px;
  }
`;

export const SelectorContainer = styled.div`
  padding: 0 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .selector-label {
    font-weight: bold;
  }
`;
