import { SetStateAction, useEffect } from "react";

export const intitialDataAttributeName = "data-amaya-tooltip";

const parseFromDataAttrValue = (attrVal: string) => {
  try {
    return JSON.parse(attrVal);
  } catch (_e) {
    return;
  }
};

type HookProps = {
  elementSelector: string;
  targetId: string;
  initialDataElementSelector?: string;
  setElement: (value: SetStateAction<Element | null>) => void;
  rerenderProp?: unknown;
};

/**
 * This hook listen to updates from a DOM element by setting up a MutationObserver to said element via elementSelector.
 * Then it gets the portal target element via targetId.
 * Finally, it sets a state via setElement.
 * Optionally, if initialDataElementSelector is provided
 * then the hook will attempt to return the parsed value of intitialDataAttributeName.
 *
 * Note: a MutationObserver can be taxing.
 * Avoid overly complex componets to portal into targetId
 * and avoid hooking up to frequently changing portal targets.
 *
 * The rerender prop is used to solve an issue where echarts will rerender its components causing the reference
 * to the target element to be stale. Passing in the current echarts instance as the rerender prop will solve this issue.
 */
const usePortalTargetObserver = ({
  elementSelector,
  targetId,
  initialDataElementSelector,
  setElement,
  rerenderProp,
}: HookProps): unknown | undefined => {
  useEffect(() => {
    const tooltipElement = document.querySelector(elementSelector);
    if (tooltipElement) {
      const mutationObserver = new MutationObserver(() => {
        const targetElement = document.getElementById(targetId);
        if (targetElement) setElement(targetElement);
      });

      const config = {
        attributes: true,
        childList: true,
        subtree: true,
      };

      mutationObserver.observe(tooltipElement, config);

      return () => {
        mutationObserver.disconnect();
      };
    }
  }, [rerenderProp]);

  if (initialDataElementSelector) {
    const initialDataElement = document.querySelector(`${elementSelector} ${initialDataElementSelector}`);
    const initialDataStr = initialDataElement ? initialDataElement.getAttribute(intitialDataAttributeName) : undefined;
    return initialDataStr ? parseFromDataAttrValue(initialDataStr) : undefined;
  }
};

export default usePortalTargetObserver;
