import { DownOutlined } from "@ant-design/icons";
import { Button, Modal, Switch } from "antd";
import { useContext, useEffect, useState } from "react";

import { useClustersForHdmComparisonQuery } from "@/api";
import {
  HdmClusterComparisonInput,
  HdmCustomComparisonInput,
  HdmNormalizations,
  useDefaultHdmComparisonOptionsQuery,
  useHdmComparisonOptionsQuery,
  useHdmComparisonTableQuery,
  useHdmTableQuery,
} from "@/api/customerApi";
import DropdownWidget from "@/components/ui/DropdownWidget";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { QUERY_SETTINGS } from "@/constants";
import SCVContext from "@/contexts/SCVContext";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { getCurrentCustomerId } from "@/utils/customers";
import {
  getHDMComparionsAttributesDisplayLabel,
  getHDMTableDefinitions,
  getHDMTableNormalizations,
} from "@/utils/historicDataMatrix/hdmUtils";
import { formatNumber } from "@/utils/numberUtils";
import { snakeCaseToTitleCase } from "@/utils/stringUtils";
import { toModelDescription } from "@/utils/vehicleModel";

import { HDMChartHeader } from "./HDM.styled";
import { HDMComparisonContainer, HDMCoparisonWrapper } from "./HDMComparison.styled";
import HDMComparisonForm from "./HDMComparisonForm";
import { nonSpecificDefaultValues } from "./HDMCustomComparisonForm";
import HDMHeatMap from "./HDMHeatMap";
import { HDMHeatMapAndTitleContainer, HDMHeatMapTitleContainer } from "./HDMHeatMap.styled";
import { HDMAllowedAggregateTypes, HDMSelectedDiagnosticCheck } from "./HistoricDataMatrix";

type HDMComparisonProps = {
  selectedDiagnosticCheck: HDMSelectedDiagnosticCheck;
  selectedTableId: string;
};

export type ComparisonDefintion = {
  customComparison?: HdmCustomComparisonInput;
  clusterComparison?: HdmClusterComparisonInput;
};

export const HDMComparison = ({ selectedDiagnosticCheck, selectedTableId }: HDMComparisonProps) => {
  const { pvin } = useContext(SCVContext);
  const { customerIdentifier } = useCustomerIdentifier();
  const tables = getHDMTableDefinitions(customerIdentifier);
  const selectedTableDefinition = tables?.find((t) => t.table_id === selectedTableId);

  const [settingsOpen, setSettingsOpen] = useState(false);
  const [comparisonDefintion, setComparisonDefintion] = useState<ComparisonDefintion>({
    customComparison: undefined,
    clusterComparison: undefined,
  });
  const [clusterNameSearch, setClusterNameSearch] = useState<string>();

  const [selectedNormalization, setSelectedNormalization] = useState<HdmNormalizations>(HdmNormalizations.Raw);
  const [comparisonMode, setComparisonMode] = useState(false);

  const { data: comparisonData, isLoading: comparisonLoading } = useHdmComparisonOptionsQuery(
    {},
    {
      staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME,
    }
  );
  const { data: defaultComparisonData, isLoading: isDefaultLoading } = useDefaultHdmComparisonOptionsQuery({ pvin });

  const { data: clusters, isFetching: clustersLoading } = useClustersForHdmComparisonQuery(
    {
      //This argument is used in calclualting the number of vehicles in the cluster which is not needed here.
      filter: {},
      customerId: getCurrentCustomerId() ?? "",
      pagination: { pageSize: 100, currentPage: 1 },
      nameSearchText: clusterNameSearch,
    },
    {
      staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME,
    }
  );

  const { data: tableData, isFetching: tableIsFetching } = useHdmTableQuery(
    {
      diagnosticCheckId: selectedDiagnosticCheck.diagnosticCheckId,
      tableId: selectedTableId,
      normalization: selectedNormalization,
    },
    {
      staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME,
    }
  );

  const { data: comparisonTableData, isFetching: comparisonTableIsFetching } = useHdmComparisonTableQuery(
    { ...comparisonDefintion, tableId: selectedTableId, normalization: selectedNormalization },
    {
      enabled: !!comparisonDefintion.clusterComparison || !!comparisonDefintion.customComparison,
      staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME,
    }
  );

  useEffect(() => {
    if (!comparisonTableIsFetching && !comparisonTableData?.hdmComparisonTable?.values.length) {
      setComparisonMode(false);
    }
  }, [comparisonTableData]);

  const tableValues = tableData?.hdmTable?.values ?? [];
  const comparisonTableValues = comparisonTableData?.hdmComparisonTable?.values ?? [];

  useEffect(() => {
    if (defaultComparisonData?.defaultHDMComparisonOptions) {
      const defaultVals = defaultComparisonData.defaultHDMComparisonOptions;
      setComparisonDefintion({
        customComparison: {
          ...nonSpecificDefaultValues,
          batterySize: defaultVals.moduleCount[0],
          model: defaultVals.model[0],
          modelYear: defaultVals.modelYear[0],
          deliveryCountry: defaultVals.deliveryCountry[0],
        },
        clusterComparison: undefined,
      });
    }
  }, [defaultComparisonData]);

  const handleComparisonSettingsChange = (settings: ComparisonDefintion) => {
    setComparisonDefintion({ ...settings });
    setSettingsOpen(false);
  };

  const getTableDisplayValues = (index: number) => {
    const comparisonValue = comparisonTableValues[index];
    const tableValue = tableValues[index];
    if (!comparisonMode) return `${tableValue}`;
    if (!Number(tableValue) || !Number(comparisonValue)) return "";
    const value = comparisonMode ? tableValues[index] - comparisonValue : tableValue;
    return `${
      selectedNormalization === HdmNormalizations.DifferenceFromMean && Number(value) > 0 ? "+" : ""
    }${formatNumber(value)}`;
  };

  const getComparisonDisplayValues = (index: number) => {
    const value = comparisonTableValues[index];
    return `${
      selectedNormalization === HdmNormalizations.DifferenceFromMean && Number(value) > 0 ? "+" : ""
    }${formatNumber(value)}`;
  };

  const heatMapMax = Math.max(...tableValues, ...comparisonTableValues);

  const heatMapMin = Math.min(...tableValues, ...comparisonTableValues);

  const getHDMComparisonAttributeValue = (key: string, value: string) => {
    if (key === "model") {
      return toModelDescription({ modelStyle: value as string }, customerIdentifier.models);
    } else if (key === "clusterId") {
      return clusters?.clusters?.clusters.data.find((c) => c.id === value)?.name ?? "";
    } else if (key === "aggregation") {
      return Object.entries(HDMAllowedAggregateTypes).find(([_, v]) => v === value)?.[0] || "";
    }
    return value;
  };

  const titleElement = (
    <HDMChartHeader>
      <div>
        <div className="widget-title">HDM Table Comparison</div>
        <span className="sub-title">Visualize HDM Tables and compare ot to other vehicles{"'"} readings</span>
      </div>

      <div className="hdm-chart-header-buttons">
        <DropdownWidget
          elements={getHDMTableNormalizations(selectedTableDefinition).map((norm) => ({
            label: snakeCaseToTitleCase(norm),
            key: norm,
          }))}
          onElementSelected={(element) => setSelectedNormalization(element as HdmNormalizations)}
          label={<>{`Values: ${snakeCaseToTitleCase(selectedNormalization)}`}</>}
          buttonSize="small"
          icon={<DownOutlined />}
        />
        <Button
          type="primary"
          className="define-comparison-button"
          onClick={() => setSettingsOpen(true)}
          loading={comparisonLoading || isDefaultLoading}
          disabled={comparisonLoading || isDefaultLoading}
        >
          Define Comparison
        </Button>
      </div>
    </HDMChartHeader>
  );
  return (
    <BasicWidget title={titleElement} style={{ marginTop: "15px" }}>
      <HDMCoparisonWrapper>
        <HDMComparisonContainer>
          <HDMHeatMapAndTitleContainer>
            <HDMHeatMapTitleContainer>
              <div className="selected-table-title-buttons">
                <h4>Selected Table</h4>
                <div>
                  <span className="sub-title" style={{ marginRight: "5px" }}>
                    Comparison
                  </span>
                  <Switch
                    value={comparisonMode}
                    onChange={() => setComparisonMode(!comparisonMode)}
                    disabled={!comparisonTableValues.length}
                  />
                </div>
              </div>
              <span>{selectedDiagnosticCheck.label}</span>
            </HDMHeatMapTitleContainer>
          </HDMHeatMapAndTitleContainer>
          <HDMHeatMapAndTitleContainer>
            <HDMHeatMapTitleContainer>
              <h4>Comparison Set</h4>
              <span>
                {Object.entries(comparisonDefintion?.customComparison ?? {})
                  .filter(([_k, v]) => !!v)
                  .map(
                    ([k, v]) =>
                      `${getHDMComparionsAttributesDisplayLabel(k)}:  ${getHDMComparisonAttributeValue(k, v as string)}`
                  )
                  .join(",  ")}
              </span>
            </HDMHeatMapTitleContainer>
          </HDMHeatMapAndTitleContainer>
        </HDMComparisonContainer>
        <HDMComparisonContainer>
          <HDMHeatMapAndTitleContainer>
            <HDMHeatMap
              values={tableValues}
              isLoading={tableIsFetching}
              max={heatMapMax}
              min={heatMapMin}
              batterySize={defaultComparisonData?.defaultHDMComparisonOptions.moduleCount[0]}
              getDisplayValues={getTableDisplayValues}
              comparisonMode={comparisonMode}
            />
          </HDMHeatMapAndTitleContainer>
          <HDMHeatMapAndTitleContainer>
            <HDMHeatMap
              values={comparisonTableValues}
              isLoading={comparisonTableIsFetching || isDefaultLoading}
              max={heatMapMax}
              min={heatMapMin}
              batterySize={defaultComparisonData?.defaultHDMComparisonOptions.moduleCount[0]}
              getDisplayValues={getComparisonDisplayValues}
            />
          </HDMHeatMapAndTitleContainer>
        </HDMComparisonContainer>
        <Modal open={settingsOpen} onCancel={() => setSettingsOpen(false)} footer={false}>
          <HDMComparisonForm
            comparisonOptions={comparisonData?.hdmComparisonOptions}
            onChange={handleComparisonSettingsChange}
            defaultValues={defaultComparisonData?.defaultHDMComparisonOptions}
            clusters={clusters?.clusters?.clusters.data}
            clusterNameSearch={clusterNameSearch}
            setClusterNameSearch={setClusterNameSearch}
            clustersLoading={clustersLoading}
          />
        </Modal>
      </HDMCoparisonWrapper>
    </BasicWidget>
  );
};
