import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from "@tanstack/react-query";
import { useCustomerApiFetcher } from "@/hooks/useApiFetcher";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  BigInt: number;
  DateTime: string;
  EmailAddress: string;
  Ip: string;
  JSON: string;
  JSONObject: object;
  PhoneNumber: string;
  /** Psuedo-anonymized Vehicle Identification Number. A globally unique identifier for a vehicle valid only within Amaya. */
  Pvin: string;
  /** Generic signed string that can be used anywhere we might need to pass information with claims. */
  SignedId: string;
  Time: string;
  /** A globally-unique identifier for a set of values, where each value corresponds to a PVIN and timestamp. */
  TimeSeriesFieldId: string;
  TimeStamp: string;
  URL: string;
  /** Vehicle Identification Number. A globally unique identifier for a vehicle that is used outside of Amaya. */
  Vin: string;
};

export type AffectedVehicleAttributes = {
  __typename?: "AffectedVehicleAttributes";
  /** Unique identifier for the vehicle (sometimes called PVIN, or pseudo-anonymized VIN) */
  id: Scalars["Pvin"];
  /** Vehicle model */
  model: Scalars["String"];
  /** Vehicle model style */
  modelStyle: Scalars["String"];
  /** The vehicle model year. */
  modelYear: Scalars["Int"];
  /** VIN. Only populated if this feature is enabled for the customer */
  vin?: Maybe<Scalars["Vin"]>;
};

export enum AggregateType {
  /** Count of observed signal values reported within the interval. */
  Count = "COUNT",
  /** The observed signal value with the latest timestamp within the interval. */
  Last = "LAST",
  /** Maximum of all observed signal values within the interval. */
  Max = "MAX",
  /** Arithmetic mean of all observed signal values within the interval. */
  Mean = "MEAN",
  /** 50th percentile of all observed signal values within the interval. May be approximated. */
  Median = "MEDIAN",
  /** Minimum of all observed signal values within the interval. */
  Min = "MIN",
  /** Standard deviation of all observed signal values within the interval. */
  Std = "STD",
  /** Sum of all observed signal values within the interval. */
  Sum = "SUM",
}

export type AllClusterPvins = {
  __typename?: "AllClusterPvins";
  clusters: Array<ClusterPvins>;
};

/** Parent type of what is being passed down to the resolvers, all types are (string | number) [] */
export type AllFilterInputs = {
  __typename?: "AllFilterInputs";
  vehicle?: Maybe<VehicleFilterInputType>;
};

/** Parent type of what is used in the UI for Global Filter, has additional inofrmation that input does not need */
export type AllFilterValues = {
  __typename?: "AllFilterValues";
  vehicle: VehicleFilterValues;
};

export type AnomaliesWithVehicleCountReponse = {
  __typename?: "AnomaliesWithVehicleCountReponse";
  items: Array<AnomalyDetectorWithVehicleData>;
  pagination: PaginationResult;
};

export enum AnomaliesWithVehicleCountSortField {
  AnomalyVehicleCount = "anomalyVehicleCount",
  Created = "created",
  CreatorName = "creatorName",
  DetectorDescription = "detectorDescription",
  DetectorType = "detectorType",
  Name = "name",
}

export type AnomalyAffectedVehicle = {
  __typename?: "AnomalyAffectedVehicle";
  /** The timestamp for when the anomaly was last detected */
  lastDetected?: Maybe<Scalars["DateTime"]>;
  /** The vehicle model */
  model: Scalars["String"];
  /** The vehicle model style */
  modelStyle: Scalars["String"];
  /** The vehicle model year */
  modelYear: Scalars["Int"];
  /** The id of the vehicle */
  pvin: Scalars["Pvin"];
  /** The anomaly current score for the vehicle */
  score?: Maybe<Scalars["Float"]>;
  /** VIN, if enabled for the customer, otherwise id of the vehicle */
  vin?: Maybe<Scalars["Vin"]>;
};

export type AnomalyAffectedVehiclesResponse = {
  __typename?: "AnomalyAffectedVehiclesResponse";
  data: Array<AnomalyAffectedVehicle>;
  pagination: PaginationResult;
};

export enum AnomalyAffectedVehiclesSortField {
  LastDetected = "lastDetected",
  Score = "score",
}

export type AnomalyData = {
  __typename?: "AnomalyData";
  /** Count of vehicles affected by the anomaly */
  anomalyVehicleCount: Scalars["Int"];
};

export type AnomalyDetector = {
  __typename?: "AnomalyDetector";
  /**
   * Anomaly detector configured caution threshold. If not supplied, all anomalous vehicles will be in Warning state instead.
   * This will show in the front end graphs/charts as all red dots/lines (no yellow).
   */
  cautionThreshold?: Maybe<Scalars["Float"]>;
  /** The date this was created */
  created: Scalars["DateTime"];
  /** The user who created this */
  creator?: Maybe<ReadOnlyUser>;
  /** Anomaly detector description */
  description?: Maybe<Scalars["String"]>;
  expression?: Maybe<SavedExpression>;
  /** The version of an external (customer-supplied) Anomaly detector */
  externalVersion?: Maybe<Scalars["String"]>;
  /** Globally unique identifier for the anomaly detector */
  id: Scalars["ID"];
  /** The ids of other time series fields directly depended upon by this detector */
  inputIds: Array<Scalars["TimeSeriesFieldId"]>;
  /** Whether the model was made by the customer */
  isCustomerMLModel?: Maybe<Scalars["Boolean"]>;
  /** The date this was last modified */
  lastModified?: Maybe<Scalars["DateTime"]>;
  /** Anomaly detector name */
  name: Scalars["String"];
  /** Anomaly detector i18n name key, if applicable, otherwise the name. */
  nameKey: Scalars["String"];
  /** The status (if known) of an anomaly detector (eg: Calculating, Available) */
  status?: Maybe<CalculatedStatus>;
  /** An id that can be used to query the values of this anomaly detector as a time series. */
  timeSeriesFieldId: Scalars["TimeSeriesFieldId"];
  /** The type of this detector */
  type?: Maybe<AnomalyDetectorType>;
  /** The Anomaly detector version */
  version: Scalars["Int"];
  /** Anomaly detector configured warning threshold */
  warningThreshold: Scalars["Float"];
};

export enum AnomalyDetectorType {
  CustomerProvided = "CustomerProvided",
  Ml = "ML",
  Pattern = "Pattern",
}

export type AnomalyDetectorWithVehicleData = {
  __typename?: "AnomalyDetectorWithVehicleData";
  data?: Maybe<AnomalyData>;
  detector: AnomalyDetector;
};

export type AnomalyDetectorsFilter = {
  globalFilter?: InputMaybe<FilterInput>;
  status?: InputMaybe<CalculatedStatus>;
  type?: InputMaybe<AnomalyDetectorType>;
};

export type AnomalyDetectorsInput = {
  filter?: InputMaybe<AnomalyDetectorsFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type AnomalySampleVehicle = {
  __typename?: "AnomalySampleVehicle";
  /** Vehicle's anomaly status */
  anomalyState: VehicleAnomalyState;
  /** The datetime of the signal/dataset the zscore was computed from */
  lastDetected?: Maybe<Scalars["DateTime"]>;
  /** Odometer value of the vehicle at the time above */
  odometer?: Maybe<Scalars["Float"]>;
  /** The id of the vehicle */
  pvin: Scalars["Pvin"];
  /** The current zscore for the vehicle */
  score?: Maybe<Scalars["Float"]>;
  /** VIN, if enabled for the customer, otherwise id of the vehicle */
  vin?: Maybe<Scalars["Vin"]>;
};

export type AnomalySampleVehicles = {
  __typename?: "AnomalySampleVehicles";
  data: Array<AnomalySampleVehicle>;
};

export enum AnomalySeverity {
  All = "ALL",
  Caution = "CAUTION",
  Normal = "NORMAL",
  Warning = "WARNING",
}

export type AnomalySeverityCounts = {
  __typename?: "AnomalySeverityCounts";
  all: Scalars["Float"];
  caution: Scalars["Float"];
  normal: Scalars["Float"];
  warning: Scalars["Float"];
};

export type AnomalyThresholds = {
  __typename?: "AnomalyThresholds";
  caution: Scalars["Float"];
  max?: Maybe<Scalars["Float"]>;
  min?: Maybe<Scalars["Float"]>;
  warning: Scalars["Float"];
};

export type AnomalyVehicleCountByCountry = {
  __typename?: "AnomalyVehicleCountByCountry";
  /** The delivery country of the vehicle as an ISO 3166 2-letter code */
  country?: Maybe<Scalars["ID"]>;
  /** The total count of vehicles delivered in this country */
  vehicles?: Maybe<Scalars["Int"]>;
  /**
   * The total count of anomalous vehicles whose current (latest) zscores >= the caution threshold
   * but < warning threshold, if a caution threshold is supplied. Otherwise this would be 0, and all anomalous
   * vehicles would be in Warning state.
   */
  vehiclesCautionCount?: Maybe<Scalars["Int"]>;
  /**
   * The total count of anomalous vehicles whose current (latest) zscores > warning threshold
   * or is_anomalous is True if not using thresholds.
   */
  vehiclesWarningCount?: Maybe<Scalars["Int"]>;
};

export type AnomalyVehicleCountByCountryResponse = {
  __typename?: "AnomalyVehicleCountByCountryResponse";
  /** Total of countries with the number of vehicles delivered in this country. */
  vehiclesByCountry: Array<AnomalyVehicleCountByCountry>;
};

export type AssertionResult = {
  __typename?: "AssertionResult";
  ancestorTitles?: Maybe<Array<Scalars["String"]>>;
  duration?: Maybe<Scalars["Int"]>;
  failureMessages?: Maybe<Array<Scalars["String"]>>;
  fullName: Scalars["String"];
  location?: Maybe<Location>;
  status: Scalars["String"];
  title: Scalars["String"];
};

export type AttributeCount = {
  __typename?: "AttributeCount";
  attribute: Scalars["String"];
  pvinCount: Scalars["Int"];
};

export enum AttributeTypeEnum {
  DeliveryCountry = "DELIVERY_COUNTRY",
  ModelType = "MODEL_TYPE",
  ModelYear = "MODEL_YEAR",
}

export enum CalculatedStatus {
  Available = "AVAILABLE",
  Calculating = "CALCULATING",
  Draft = "DRAFT",
  Error = "ERROR",
}

export type ChargingDurationHistogramBucket = {
  __typename?: "ChargingDurationHistogramBucket";
  /** The ratio of charging session durations that fall in the specified bucket to total number of charging sessions for all vehicles. */
  fleet: Scalars["Float"];
  /** Maximum duration for the bucket, in minutes. If null, includes all charging durations above minDurationMinutes. */
  maxDurationMinutes?: Maybe<Scalars["Int"]>;
  /** Minimum duration for the bucket, in minutes. If null, includes all charging durations below maxDurationMinutes. */
  minDurationMinutes?: Maybe<Scalars["Int"]>;
  /** The ratio of charging session durations that fall in the specified bucket to total number of charging sessions for this vehicle. */
  vehicle: Scalars["Float"];
};

export type ClusterEvent = {
  __typename?: "ClusterEvent";
  deleted?: Maybe<Scalars["Boolean"]>;
  endDate?: Maybe<Scalars["DateTime"]>;
  pvin: Scalars["String"];
  startDate?: Maybe<Scalars["DateTime"]>;
};

/** Metadata for a given list of vehicles */
export type ClusterMetadata = {
  __typename?: "ClusterMetadata";
  componentLocationId?: Maybe<Scalars["String"]>;
  diagnosticCheckId?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["DateTime"]>;
  maintenanceHistoryId?: Maybe<Scalars["String"]>;
  model?: Maybe<Scalars["String"]>;
  modelStyle?: Maybe<Scalars["String"]>;
  modelType: Scalars["String"];
  modelYear: Scalars["Int"];
  pvin: Scalars["String"];
  startDate?: Maybe<Scalars["DateTime"]>;
  vin?: Maybe<Scalars["Vin"]>;
};

export type ClusterMetadataPaginated = {
  __typename?: "ClusterMetadataPaginated";
  data: Array<ClusterMetadata>;
  pagination: PaginationResult;
};

export type ClusterPvins = {
  __typename?: "ClusterPvins";
  clusterId: Scalars["ID"];
  /**
   * The count of all elements in pvins. Use this for faster performance when only the count is needed, without the raw
   * data.
   */
  combinationCount: Scalars["Int"];
  missingPvins: Array<ClusterEvent>;
  pvins: Array<ClusterEvent>;
  /** Total of records with diagnostic check inside the cluster */
  recordWithDiagnosticCheckCount: Scalars["Int"];
  /** Total of records with maintenance history id inside the cluster */
  recordWithMaintenanceHistoryCount: Scalars["Int"];
  /**
   * The count of unique vehicles (pvins) in the elements in pvins. Use this for faster performance when only the count
   * is needed, without the raw data.
   */
  vehicleCount: Scalars["Int"];
};

export type ClusterRedshift = {
  __typename?: "ClusterRedshift";
  endDate?: Maybe<Scalars["DateTime"]>;
  pvin: Scalars["String"];
  startDate?: Maybe<Scalars["DateTime"]>;
};

export type ClusterRedshiftCreateInput = {
  endDate?: InputMaybe<Scalars["DateTime"]>;
  pvin: Scalars["ID"];
  startDate?: InputMaybe<Scalars["DateTime"]>;
};

export enum ClusterSortField {
  Created = "created",
  CreatorName = "creatorName",
  Description = "description",
  Name = "name",
  VehicleCount = "vehicleCount",
}

export type ColumnFilter = {
  group?: InputMaybe<Array<Scalars["String"]>>;
  groupCriteria?: InputMaybe<GroupFilterCriteria>;
  includeNulls?: InputMaybe<Scalars["Boolean"]>;
  key: Scalars["String"];
  range?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  type: FilterType;
};

export type Customer = {
  __typename?: "Customer";
  /** A human-readable name for the customer */
  appVersion?: Maybe<Scalars["String"]>;
  /** URL where the common resources are named by different customers. */
  commonResourcesUrl: Scalars["String"];
  /**
   * If present, any customer API requests should be directed
   * to the specified API URL. If not set, the default global API URL should be used.
   */
  customApiUrl?: Maybe<Scalars["String"]>;
  /** The default fields for display in the event analysis chart. */
  defaultEventAnalysisFields?: Maybe<Array<DefaultTimeSeries>>;
  /** The default faults that will be rendered in the SCV chart for telematicsless customers */
  defaultFaults?: Maybe<Array<DefaultFaults>>;
  /** The default fields for display in the time series chart. */
  defaultTimeSeriesFields?: Maybe<Array<DefaultTimeSeries>>;
  /** Feature configuration for the customer */
  features?: Maybe<Array<Feature>>;
  /** URL for i18n folder */
  i18nFolder: Scalars["String"];
  id: Scalars["String"];
  /** The URI of a logo image for the customer, if configured */
  logoUri?: Maybe<Scalars["String"]>;
  /** A human-readable name for the customer */
  name: Scalars["String"];
};

export enum Datasource_Status {
  Connected = "CONNECTED",
  Intermitent = "INTERMITENT",
  NotConnected = "NOT_CONNECTED",
}

export enum DataSource {
  FaultHistoryData = "FAULT_HISTORY_DATA",
  RepairHistory = "REPAIR_HISTORY",
  TelemetryData = "TELEMETRY_DATA",
}

export type DataSourceStatusInfo = {
  __typename?: "DataSourceStatusInfo";
  /** Data source name */
  dataSource: Scalars["String"];
  /** Last update received from the datasource. */
  lastUpdatedDate: Scalars["DateTime"];
  /** Most recent data date received from the datasource. */
  mostRecentDataDate: Scalars["DateTime"];
};

export type DataSourceVehiclesInfo = {
  __typename?: "DataSourceVehiclesInfo";
  /** Data source name */
  dataSource: DataSource;
  /** Information regarding the vehicles and the last update. */
  vehiclesLastUpdate: Array<VehicleLastUpdate>;
};

export type DateRange = {
  from?: InputMaybe<Scalars["DateTime"]>;
  to?: InputMaybe<Scalars["DateTime"]>;
};

export type DefaultFaults = {
  __typename?: "DefaultFaults";
  code: Scalars["String"];
  source: Scalars["String"];
  troubleCode: Scalars["String"];
};

export type DefaultTimeSeries = {
  __typename?: "DefaultTimeSeries";
  aggregateType: AggregateType;
  id: Scalars["TimeSeriesFieldId"];
  unit: Scalars["String"];
};

export type DeleteDependencyResponse = {
  __typename?: "DeleteDependencyResponse";
  dependencies: Array<Maybe<Dependency>>;
};

export type Dependency = {
  __typename?: "Dependency";
  id: Scalars["ID"];
  name: Scalars["String"];
  owner?: Maybe<Scalars["String"]>;
  type: DependencyType;
};

export enum DependencyType {
  Anomaly = "ANOMALY",
  Event = "EVENT",
  Expression = "EXPRESSION",
}

/** Either the first or last diagnostic check of each vehicle used in the comparison table. */
export enum DiagnosticCheckType {
  First = "First",
  Last = "Last",
}

export type EditClusterMissingPvins = {
  __typename?: "EditClusterMissingPvins";
  existingCombinations: Array<ClusterRedshift>;
  missingPvins: Array<Scalars["String"]>;
};

export type ErrorLog = {
  __typename?: "ErrorLog";
  content: Scalars["String"];
  size: Scalars["Int"];
  taskId: Scalars["String"];
  time: Scalars["String"];
  type: Scalars["String"];
};

export enum ErrorType {
  MinMax = "MinMax",
  Quartile = "Quartile",
  StdDev = "StdDev",
  Vigintile = "Vigintile",
}

export type EventAnalysisQuickActionsConfig = {
  __typename?: "EventAnalysisQuickActionsConfig";
  /** If set to true, the action button for adding a pattern will show a fake image */
  fakePatternEditor?: Maybe<Scalars["Boolean"]>;
};

export type EventDescriptor = {
  __typename?: "EventDescriptor";
  clusterId?: Maybe<Scalars["ID"]>;
  expressionEventId?: Maybe<Scalars["ID"]>;
  fault?: Maybe<FaultEventType>;
  /** The name of the event if applicable, otherwise a key for translation by the client. */
  name: Scalars["String"];
  repairEventType?: Maybe<RepairEventType>;
};

export type EventFilter = {
  clusterId?: InputMaybe<Scalars["ID"]>;
  /**
   * An identifier for a calculated field that is interpreted as an event. Event Filter is now being used for
   * related event ids. definitionFieldId was needed to prevent typescript errors.
   */
  definitionFieldId?: InputMaybe<Scalars["ID"]>;
  /** The ID of a saved expression. The expression must be of boolean type. */
  expressionEventId?: InputMaybe<Scalars["ID"]>;
  fault?: InputMaybe<FaultEvent>;
  repairEventType?: InputMaybe<RepairEventType>;
};

export type EventOccurrence = {
  __typename?: "EventOccurrence";
  /** Date of the most recent event */
  date?: Maybe<Scalars["DateTime"]>;
  /** Number of event occurrences for the vehicle */
  eventCount: Scalars["Int"];
  id: Scalars["ID"];
  /** If true, the most recent event date is inferred (not provided by the customer) */
  isDateInferred?: Maybe<Scalars["Boolean"]>;
  /** Current vehicle odometer reading at the time of the most recent event */
  odometer?: Maybe<Scalars["Float"]>;
  /** The vehicle which this record describes. */
  vehicle?: Maybe<Vehicle>;
};

/** The event occurrence rate for an event over a specified range of odometer values */
export type EventOccurrenceRate = {
  __typename?: "EventOccurrenceRate";
  /**
   * Odometer value for the end of the interval, in km. The start of the interval is determined by subtracting the
   * windowSize that was passed as an argument.
   */
  end: Scalars["Int"];
  /** Number of event occurrences in the interval */
  events: Scalars["Int"];
  /** weightedVehiclesWithEvents divided by weightedTotalVehicles. If insufficient data exists for the interval, null. */
  rate?: Maybe<Scalars["Float"]>;
  /** Number of unique vehicles for which data exists in the specified odometer range. */
  totalVehicles: Scalars["Int"];
  /** Number of unique vehicles with event occurrences in the interval */
  vehiclesWithEvents: Scalars["Int"];
  /**
   * Number of unique vehicles for which data exists in the specified odometer range, weighted by the amount of data
   * we have for them in the range (allowing for fractional vehicles).
   */
  weightedTotalVehicles: Scalars["Float"];
  /**
   * The number of events weighted by fraction of the interval for which we have data for the affected vehicle
   * (allowing for fractional values).
   */
  weightedVehiclesWithEvents: Scalars["Float"];
};

export type EventOccurrenceRateSql = {
  __typename?: "EventOccurrenceRateSQL";
  sql: Scalars["String"];
};

export enum EventOccurrencesSortField {
  Country = "country",
  Date = "date",
  EventCount = "eventCount",
  Odometer = "odometer",
  VehicleModel = "vehicleModel",
  VehicleYear = "vehicleYear",
}

export type EventRate = {
  __typename?: "EventRate";
  /** For each interval i, the number of unique vehicles for which we have data in the interval between x[i] and x[i+1]. */
  countTotal: Array<Scalars["Int"]>;
  /** For each interval i, the number of unique vehicles that experienced the event in the interval between x[i] and x[i+1]. */
  countWithEvent: Array<Scalars["Int"]>;
  /** The independent variable values. This should include one for each interval of the specified size. */
  x: Array<Scalars["Float"]>;
};

export type EventStats = {
  __typename?: "EventStats";
  /** Percentage of cars that both the event and a full repair */
  fullRepairCorrelation?: Maybe<Scalars["Float"]>;
  /** Percentage of cars that both the event and a partial repair */
  partialRepairCorrelation?: Maybe<Scalars["Float"]>;
  totalOccurrences: Scalars["Int"];
  uniqueVehicles: Scalars["Int"];
};

export type EventTimeSeriesSelector = {
  aggregateType: AggregateType;
  errorType: ErrorType;
  fieldId: Scalars["TimeSeriesFieldId"];
};

export enum EventType {
  Cluster = "cluster",
  Expression = "expression",
  Fault = "fault",
  Repair = "repair",
}

export enum ExpressionEventOperator {
  Gt = "GT",
  Gte = "GTE",
  Lt = "LT",
  Lte = "LTE",
}

export type ExpressionEventQuery = {
  __typename?: "ExpressionEventQuery";
  /**
   * X -> AVG odometer or MAX timestamp for group.
   * Y[0] -> Count of elements in group where expression event is true.
   * count[0] -> Count of elements in group
   */
  occurrencesPreview: ExpressionTimeSeriesData;
};

export type ExpressionEventQueryOccurrencesPreviewArgs = {
  expressionId: Scalars["ID"];
  independentVar: TimeSeriesIndependentVar;
  operator: ExpressionEventOperator;
  threshold: Scalars["Float"];
};

export type ExpressionHistogram = {
  __typename?: "ExpressionHistogram";
  /** The maximum y value for each bucket */
  max: Array<Maybe<Scalars["Float"]>>;
  /** The minimum y value for each bucket */
  min: Array<Maybe<Scalars["Float"]>>;
  /** Top and bottom outliers are removed from the min, max, and percent. But are returned separately. */
  outliers?: Maybe<ExpressionHistogramOutliers>;
  /** The fraction of y values between min and max for each bucket */
  pct: Array<Scalars["Float"]>;
};

export type ExpressionHistogramOutliers = {
  __typename?: "ExpressionHistogramOutliers";
  /** The maximum y value for each outlier bucket */
  max?: Maybe<Array<Maybe<Scalars["Float"]>>>;
  /** The minimum y value for each outlier bucket */
  min?: Maybe<Array<Maybe<Scalars["Float"]>>>;
  /** The fraction of y values between min and max for each outlier bucket */
  pct?: Maybe<Array<Maybe<Scalars["Float"]>>>;
};

export type ExpressionTimeSeriesData = {
  __typename?: "ExpressionTimeSeriesData";
  count: Array<Array<Scalars["Float"]>>;
  high: Array<Array<Scalars["Float"]>>;
  low: Array<Array<Scalars["Float"]>>;
  vehicleCount: Array<Array<Scalars["Int"]>>;
  x: Array<Scalars["Float"]>;
  y: Array<Array<Maybe<Scalars["Float"]>>>;
};

export type FailureCountByDistance = {
  __typename?: "FailureCountByDistance";
  bucketNumber: Scalars["Int"];
  distanceEnd: Scalars["Int"];
  distanceStart: Scalars["Int"];
  faultCount: Scalars["Int"];
  faultPvinCount: Scalars["Int"];
  maintenanceCount: Scalars["Int"];
  maintenancePvinCount: Scalars["Int"];
};

export type Fault = {
  __typename?: "Fault";
  /** Category for the fault. Null value means category is unknown. */
  category?: Maybe<Scalars["ID"]>;
  /** The code for the fault */
  code: Scalars["ID"];
  /** The number of the dealership the vehicle was serviced at. */
  dealerNumber?: Maybe<Scalars["String"]>;
  /** The ID of the diagnositc check which sourced this fault */
  diagnositcCheckId?: Maybe<Scalars["String"]>;
  /** The date of the diagnostic check */
  diagnosticDate?: Maybe<Scalars["DateTime"]>;
  /** The time the fault was cleared */
  endTime?: Maybe<Scalars["DateTime"]>;
  /** Custom key-value fields for the fault */
  fields?: Maybe<Array<FaultField>>;
  hardwarePartNumber?: Maybe<Scalars["String"]>;
  hardwareVersion?: Maybe<Scalars["String"]>;
  /** Globally unique identifier for the fault occurrence */
  id: Scalars["String"];
  /** Indicates when fault date/dates is/are inferred */
  isDateInferred?: Maybe<Scalars["Boolean"]>;
  /** The name for the fault */
  name?: Maybe<Scalars["String"]>;
  /** Odometer reading (in km) when the fault was registered */
  odometer: Scalars["Float"];
  softwarePartNumber?: Maybe<Scalars["String"]>;
  softwareVersion?: Maybe<Scalars["String"]>;
  /** The ID of the source of the fault as logged by the vehicle */
  source: Scalars["ID"];
  /** The time the fault started */
  startTime?: Maybe<Scalars["DateTime"]>;
  /** A diagnostic trouble code that accompanied the fault. Sometimes called an error code. */
  troubleCode?: Maybe<Scalars["ID"]>;
  /** Vehicle ID for the fault */
  vehicleId: Scalars["String"];
};

export type FaultEvent = {
  clusterId?: InputMaybe<Scalars["String"]>;
  ecu?: InputMaybe<Scalars["String"]>;
  faultCode: Scalars["String"];
  hardwarePartNumber?: InputMaybe<Scalars["String"]>;
  hardwareVersion?: InputMaybe<Scalars["String"]>;
  softwarePartNumber?: InputMaybe<Scalars["String"]>;
  softwareVersion?: InputMaybe<Scalars["String"]>;
  troubleCode?: InputMaybe<Scalars["String"]>;
};

export type FaultEventType = {
  __typename?: "FaultEventType";
  clusterId?: Maybe<Scalars["String"]>;
  ecu?: Maybe<Scalars["String"]>;
  faultCode: Scalars["String"];
  hardwarePartNumber?: Maybe<Scalars["String"]>;
  hardwareVersion?: Maybe<Scalars["String"]>;
  softwarePartNumber?: Maybe<Scalars["String"]>;
  softwareVersion?: Maybe<Scalars["String"]>;
  troubleCode?: Maybe<Scalars["String"]>;
};

export type FaultField = {
  __typename?: "FaultField";
  id: Scalars["ID"];
  value?: Maybe<Scalars["Any"]>;
};

export enum FaultHistoryColumn {
  Ecu = "ecu",
  FaultCode = "fault_code",
  HardwarePartNumber = "hardware_part_number",
  HardwareVersion = "hardware_version",
  SoftwarePartNumber = "software_part_number",
  SoftwareVersion = "software_version",
  TroubleCode = "trouble_code",
}

export type FaultHistoryFilterData = {
  __typename?: "FaultHistoryFilterData";
  ecu?: Maybe<Scalars["String"]>;
  faultCode?: Maybe<Scalars["String"]>;
  troubleCode?: Maybe<Scalars["String"]>;
};

export type FaultHistoryPaginated = {
  __typename?: "FaultHistoryPaginated";
  data: Array<Fault>;
  pagination?: Maybe<PaginationResult>;
};

export enum FaultHistorySortableColum {
  FaultCode = "faultCode",
  HardwarePartNumber = "hardwarePartNumber",
  HardwareVersion = "hardwareVersion",
  Odometer = "odometer",
  SoftwarePartNumber = "softwarePartNumber",
  SoftwareVersion = "softwareVersion",
  Source = "source",
  StartTime = "startTime",
  TroubleCode = "troubleCode",
}

/** Fault Code and number of vehicles with that code. */
export type FaultStat = {
  __typename?: "FaultStat";
  ecu: Scalars["String"];
  faultCode: Scalars["String"];
  faultCodeAppearances: Scalars["Int"];
  hardwarePartNumber?: Maybe<Scalars["String"]>;
  hardwareVersion?: Maybe<Scalars["String"]>;
  softwarePartNumber?: Maybe<Scalars["String"]>;
  softwareVersion?: Maybe<Scalars["String"]>;
  troubleCode?: Maybe<Scalars["String"]>;
  vehiclesAffected: Scalars["Int"];
};

export type FaultTimeline = {
  __typename?: "FaultTimeline";
  ecu: Scalars["String"];
  faultCode: Scalars["String"];
  series: Array<FaultTimelineSeries>;
  troubleCode?: Maybe<Scalars["String"]>;
};

export type FaultTimelineInput = {
  clusterId?: InputMaybe<Scalars["ID"]>;
  fault: FaultEvent;
  range: Scalars["Int"];
  typeRange: TypeRange;
};

export type FaultTimelineInputPvin = {
  clickedECU: Scalars["String"];
  clickedFaultCode: Scalars["String"];
  clickedXAxis: Scalars["Int"];
  clusterId?: InputMaybe<Scalars["ID"]>;
  fault: FaultEvent;
  includeTroubleCode?: InputMaybe<Scalars["Boolean"]>;
  range: Scalars["Int"];
  typeRange: TypeRange;
};

export type FaultTimelineSeries = {
  __typename?: "FaultTimelineSeries";
  faultCount: Scalars["Int"];
  pvinCount: Scalars["Int"];
  xAxis: Scalars["Int"];
};

export type FaultsByPvinsAffectedPvinsArgs = {
  ecu: Scalars["String"];
  faultCode: Scalars["String"];
  hardwarePartNumber?: InputMaybe<Scalars["String"]>;
  hardwareVersion?: InputMaybe<Scalars["String"]>;
  softwarePartNumber?: InputMaybe<Scalars["String"]>;
  softwareVersion?: InputMaybe<Scalars["String"]>;
  troubleCode?: InputMaybe<Scalars["String"]>;
};

export enum FaultsByPvinsAffectedPvinsSortField {
  Ecu = "ecu",
  FaultCode = "faultCode",
  FaultCodeAppearances = "faultCodeAppearances",
  HardwarePartNumber = "hardwarePartNumber",
  HardwareVersion = "hardwareVersion",
  SoftwarePartNumber = "softwarePartNumber",
  SoftwareVersion = "softwareVersion",
  TroubleCode = "troubleCode",
  VehiclesAffected = "vehiclesAffected",
}

export type FaultsByPvinsAffectedPvinsSortInput = {
  field?: InputMaybe<FaultsByPvinsAffectedPvinsSortField>;
  order?: InputMaybe<SortOrder>;
};

export type Feature = {
  __typename?: "Feature";
  config?: Maybe<FeatureConfig>;
  id: FeatureId;
};

export type FeatureConfig = EventAnalysisQuickActionsConfig;

export enum FeatureId {
  /**
   * Feature for allowing users to create, view, and edit pattern anomalies. This feature will controll all access to the routes
   * for the anomaly pages
   */
  Anomalies = "Anomalies",
  /** Feature in anomaly view for performing cohort comparison. Currently faked. */
  AnomalyCohortComparison = "AnomalyCohortComparison",
  /**
   * Feature flag that will allow repair events that have already been assigned to not be filtered for and to
   * be displayed in the repair event landing page.
   */
  AssignedRepairEvents = "AssignedRepairEvents",
  /** Feature showing a pox plot of vehicle charging duration vs fleet charging duration */
  ChargingDuration = "ChargingDuration",
  /** Feature for the cluster home page and view */
  Clusters = "Clusters",
  /**
   * Feature for showing machine learning anomaly detector models as supplied by the customer.
   * Currently faked.
   */
  CustomerProvidedAnomalyModels = "CustomerProvidedAnomalyModels",
  /**
   * Quick action buttons in the event analysis for adding pattern, etc.
   * Currently faked.
   */
  EventAnalysisQuickActions = "EventAnalysisQuickActions",
  /** Repair concepts and fleet forecast. */
  FinancialAnalysis = "FinancialAnalysis",
  /**
   * "
   * Feature for allowing users to query and interact with HDM Tables in Single Car View and Cluster Home Page
   */
  HdmTables = "HDMTables",
  /**
   * Feature for allowing users to create, view, and edit patterns. This feature will controll all access to the routes
   * for the pattern pages
   */
  Patterns = "Patterns",
  /**
   * Feature flag for refined pattern editor while it is in development. This feature flag will be temporary
   * as the refined pattern editor will be avlaiable to all customers when done.
   */
  RefinedPatternEditor = "RefinedPatternEditor",
  /**
   * Feature for allowing users to see repair events, select the primary attributes, and match the logs
   * with clusters.
   */
  RepairEvents = "RepairEvents",
  /**
   * Feature flag that will disable all telematics components for customers that do not have telematics data or
   * have not given us access to the telematics data.
   */
  Telemetry = "Telemetry",
  /**
   * Feature for allowing users to interact with VINs (instead of PVINs). For now all users have permission
   * to view VINs; in the future this will further be restricted by permissions.
   */
  Vins = "Vins",
}

export type FieldInputWeight = {
  __typename?: "FieldInputWeight";
  id: Scalars["ID"];
  values: Array<Maybe<Scalars["Float"]>>;
};

export type FilterInput = {
  /** All Vehicle Filter Inputs */
  vehicle?: InputMaybe<VehicleFilterInput>;
};

export enum FilterType {
  Date = "Date",
  Group = "Group",
  Range = "Range",
}

/** Anomaly queries that are filtered by the global filter */
export type FilteredQuery = {
  __typename?: "FilteredQuery";
  FaultsByPvinsAffectedPvins: Array<AffectedVehicleAttributes>;
  /**
   * Retrieves all possible values of a column of repair events matching a user input and respecting user exclusions.
   * Note that repair events is a joins table of maintenance_history and pvins.
   */
  RepairEventColumnByUserInput: Array<Maybe<Scalars["String"]>>;
  /** Retrieves the filtered repair events that are not sorted. */
  RepairEvents: RepairEventsPaginated;
  /**
   * For each maintence log id passed as an argument it retrieves the top ten matching clusters based on the
   * most prevelant component location of the cluster
   */
  RepairEventsClustertMatches: Array<RepairEventClusterMatch>;
  /** returns the definition field ids and affected vehicle counts */
  anomaliesWithVehicleCount: AnomaliesWithVehicleCountReponse;
  anomalyAffectedVehicles: AnomalyAffectedVehiclesResponse;
  /**
   * Samples vehicles from the anomaly detector data set, ordered by odometer, NULLs last.
   * Recommended sample size is in the low hundreds (ideal for getting data for a scatter plot).
   * Larger sample sizes run the risk of slower queries or timeouts.
   * Only anomaly status AVAILABLE will return data, if there's any.
   */
  anomalySampleVehicles: AnomalySampleVehicles;
  /**
   * Retrieves anomaly vehicle stats (latest values only) grouped by country.
   * Only anomaly status AVAILABLE will return data, if there's any.
   */
  anomalyVehicleCountByCountry: AnomalyVehicleCountByCountryResponse;
  /** Groups PVINs from the cluster by each attribute value of the specified type */
  attributeCount: Array<AttributeCount>;
  attributeCountPvins: Array<AffectedVehicleAttributes>;
  /** Given a cluster id returns metadata about that cluster */
  clusterMetadata: ClusterMetadataPaginated;
  /** Returns all data sources with the last update received for each vehicle. */
  dataSourceVehiclesHistogram: Array<DataSourceVehiclesInfo>;
  distanceVersusFailureAndRepairCount: Array<FailureCountByDistance>;
  distanceVersusFailureAndRepairCountPvins: Array<AffectedVehicleAttributes>;
  /**
   * The history of occurrences, grouped by vehicle, for the specified event.
   * Optional pagination limit will default to 10.
   */
  eventOccurrences: PaginatedEventOccurrences;
  /** Describes the historic rate of occurrence by odometer. */
  eventOccurrencesRate: Array<EventOccurrenceRate>;
  eventStats: EventStats;
  /**
   * Queries time series aggregate across all filtered vehicles for a certain range of odometer before and after a
   * specified event occurs on each vehicle.
   */
  eventTimeSeriesHistory: TimeSeriesData;
  /**
   * Parent for expression event queries so that they have access to global filter.
   * See ExpressionEventQuery for details about each child.
   */
  expressionEvents?: Maybe<ExpressionEventQuery>;
  /**
   * Retrieves top 10 fault code / ecu combination occurrences based on the first
   * occurrence of a fault code as input on a time / odometer range.
   * Including Trouble code is optional.
   */
  faultTimeline: Array<FaultTimeline>;
  /** Gets the pvins for a selected faultTimeline */
  faultTimelinePvins: Array<AffectedVehicleAttributes>;
  /**
   * Retrieves most repeated fault codes for certain PVINs
   * params are optional ( Top number, and boolean params to include or not
   * ECU and Trouble Code)
   */
  faultsByPvins: PaginatedFaultStats;
  /** Retrieves the filtered preview patterns histogram */
  filteredSavedExpressionsHistogram: ExpressionHistogram;
  /** Retrieves the filtered preview patterns TimeSeries */
  filteredSavedExpressionsTimeSeries: ExpressionTimeSeriesData;
  /** Retrieves the vehicle categorical data (general data of vehicle) */
  filteredVehicleDetails: Array<Vehicle>;
  /** Retreives pvins existent for a cluster. */
  pvinsByCluster: AllClusterPvins;
  relatedEvents: PaginatedRelatedEvents;
  /** Retrieves box plot data needed to get charging Vehicle vs Fleet Data chart. */
  vehicleChargingSessionDurationStats: VehicleBoxPlot;
  /** Retrieves specified time series fields, aggregated for a cohort of vehicles. */
  vehicleCohortTimeSeries: VehicleCohortTimeSeriesData;
  /** Calculates sumamry statistics for the specified time series fields over the filtered vehicle population. */
  vehicleCohortTimeSeriesSummary: Array<VehicleCohortTimeSeriesSummaryData>;
  /** Retrieves vehicle stats grouped by country */
  vehicleCountByCountry: VehicleCountByCountryWithTotalCount;
  /** Retrieves box plot data needed to get speed Vehicle vs Fleet Data chart. */
  vehicleSpeedStats: VehicleBoxPlot;
  /** Retrieves box plot data needed to get temperature Vehicle vs Fleet Data chart. */
  vehicleTemperatureStats: VehicleBoxPlot;
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryFaultsByPvinsAffectedPvinsArgs = {
  clusterId: Scalars["ID"];
  params: FaultsByPvinsAffectedPvinsArgs;
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryRepairEventColumnByUserInputArgs = {
  column: RepairEventsColumns;
  columnFilters?: InputMaybe<Array<ColumnFilter>>;
  input: InputOrMappedValues;
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryRepairEventsArgs = {
  columnFilters?: InputMaybe<Array<ColumnFilter>>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortFilter>>;
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryRepairEventsClustertMatchesArgs = {
  RepairEventIds: Array<InputMaybe<Scalars["String"]>>;
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryAnomaliesWithVehicleCountArgs = {
  id?: InputMaybe<Scalars["String"]>;
  pagination?: InputMaybe<Pagination>;
  sorting?: InputMaybe<SortFilter>;
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryAnomalyAffectedVehiclesArgs = {
  anomalyId: Scalars["ID"];
  anomalyStatus?: InputMaybe<CalculatedStatus>;
  pagination?: InputMaybe<Pagination>;
  sorting?: InputMaybe<SortFilter>;
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryAnomalySampleVehiclesArgs = {
  anomalyId: Scalars["ID"];
  anomalyStatus?: InputMaybe<CalculatedStatus>;
  sampleSize: Scalars["Int"];
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryAnomalyVehicleCountByCountryArgs = {
  anomalyId: Scalars["ID"];
  anomalyStatus?: InputMaybe<CalculatedStatus>;
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryAttributeCountArgs = {
  attribute: AttributeTypeEnum;
  clusterId: Scalars["ID"];
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryAttributeCountPvinsArgs = {
  attribute: AttributeTypeEnum;
  attributeValue: Scalars["String"];
  clusterId: Scalars["ID"];
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryClusterMetadataArgs = {
  clusterId: Scalars["ID"];
  pagination?: InputMaybe<Pagination>;
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryDistanceVersusFailureAndRepairCountArgs = {
  clusterId: Scalars["ID"];
  odometerBounds?: InputMaybe<OdometerBounds>;
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryDistanceVersusFailureAndRepairCountPvinsArgs = {
  clusterId: Scalars["ID"];
  odometerBounds?: InputMaybe<OdometerBounds>;
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryEventOccurrencesArgs = {
  dateRange?: InputMaybe<DateRange>;
  event: EventFilter;
  pagination?: InputMaybe<Pagination>;
  sorters?: InputMaybe<Array<SortFilter>>;
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryEventOccurrencesRateArgs = {
  event: EventFilter;
  intervalSize: Scalars["Int"];
  max: Scalars["Int"];
  min: Scalars["Int"];
  windowSize: Scalars["Int"];
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryEventStatsArgs = {
  eventFilter: EventFilter;
  timeWindow?: InputMaybe<Scalars["Int"]>;
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryEventTimeSeriesHistoryArgs = {
  eventFilter: EventFilter;
  independentInterval: Scalars["Int"];
  independentWindowSize: Scalars["Int"];
  maxIndependentValue: Scalars["Int"];
  minIndependentValue: Scalars["Int"];
  selectors: Array<EventTimeSeriesSelector>;
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryFaultTimelineArgs = {
  input: FaultTimelineInput;
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryFaultTimelinePvinsArgs = {
  input: FaultTimelineInputPvin;
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryFaultsByPvinsArgs = {
  clusterId: Scalars["ID"];
  columnFilters?: InputMaybe<Array<ColumnFilter>>;
  pagination?: InputMaybe<Pagination>;
  params?: InputMaybe<TopFaultParamsInput>;
  sorting?: InputMaybe<FaultsByPvinsAffectedPvinsSortInput>;
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryFilteredSavedExpressionsHistogramArgs = {
  buckets?: InputMaybe<Scalars["Int"]>;
  expression: Scalars["JSONObject"];
  outlierPercentile?: InputMaybe<Scalars["Float"]>;
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryFilteredSavedExpressionsTimeSeriesArgs = {
  expression: Scalars["JSONObject"];
  independentVar: TimeSeriesIndependentVar;
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryFilteredVehicleDetailsArgs = {
  ids: Array<Scalars["ID"]>;
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryPvinsByClusterArgs = {
  clusterIds: Array<Scalars["SignedId"]>;
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryRelatedEventsArgs = {
  eventFilter: EventFilter;
  mileageWindowSize: Scalars["Int"];
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortFilter>>;
  telematicsless: Scalars["Boolean"];
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryVehicleChargingSessionDurationStatsArgs = {
  id: Scalars["ID"];
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryVehicleCohortTimeSeriesArgs = {
  fields: Array<Scalars["TimeSeriesFieldId"]>;
  id?: InputMaybe<Scalars["ID"]>;
  independentVar?: InputMaybe<TimeSeriesIndependentVar>;
  independentVarInterval?: InputMaybe<IntervalInput>;
  independentVarRange?: InputMaybe<IndependentVarRangeInput>;
  timestampEpoch?: InputMaybe<VehicleCohortEpoch>;
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryVehicleCohortTimeSeriesSummaryArgs = {
  fieldIds: Array<Scalars["TimeSeriesFieldId"]>;
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryVehicleCountByCountryArgs = {
  startDate?: InputMaybe<Scalars["DateTime"]>;
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryVehicleSpeedStatsArgs = {
  id: Scalars["ID"];
};

/** Anomaly queries that are filtered by the global filter */
export type FilteredQueryVehicleTemperatureStatsArgs = {
  id: Scalars["ID"];
};

export enum GroupFilterCriteria {
  Excl = "Excl",
  Incl = "Incl",
}

/** Input used to define the comparison HDM Table when using a cluster as the base for the comparison table. */
export type HdmClusterComparisonInput = {
  /** Used to select either the first or last diagnostic check of each pvin in a cluster. */
  DiagnosticCheckType?: InputMaybe<DiagnosticCheckType>;
  /** The aggregation type to be used when aggregating the values for an HDM table. */
  aggregation?: InputMaybe<AggregateType>;
  /** The battery size of the vehicles to be included in the comparison table. */
  batterySize: Scalars["Int"];
  /** ID of the cluster to compare against. */
  clusterId?: InputMaybe<Scalars["String"]>;
};

/** The values used to define what vehicles will be included in the aggregate comparison table. */
export type HdmComparisonOptions = {
  __typename?: "HDMComparisonOptions";
  /** Delivery date */
  deliveryCountry: Array<Maybe<Scalars["String"]>>;
  /** Vehicle model style */
  model: Array<Maybe<Scalars["String"]>>;
  /** Model year */
  modelYear: Array<Scalars["Int"]>;
  /** Module count of the batteries */
  moduleCount: Array<Scalars["Int"]>;
};

/**
 * An array of aggregate values of an HDM table. The index in the array can be used to represent
 * its position in a battery.
 */
export type HdmComparisonTable = {
  __typename?: "HDMComparisonTable";
  values: Array<Scalars["Float"]>;
};

/** The full definition used to construct the comparison HDM Table. */
export type HdmCustomComparisonInput = {
  /** Either the first or last diagnostic check of each vehicle used in the comparison table. */
  DiagnosticCheckType?: InputMaybe<DiagnosticCheckType>;
  /** The aggregation type to be used when aggregating the values for an HDM table. */
  aggregation?: InputMaybe<AggregateType>;
  /** Battery Size of vehicles to include in the comparison table. */
  batterySize: Scalars["Int"];
  /** Delivery Country of vehicles to include in the comparison table. */
  deliveryCountry?: InputMaybe<Scalars["String"]>;
  /** Model of vehicles to include in the comparison table. */
  model?: InputMaybe<Scalars["String"]>;
  /** Model year of vehicles to include in the comparison table. */
  modelYear?: InputMaybe<Scalars["Int"]>;
  /** Either the most recent or first maintenance event of each vehicle used in the comparison table. */
  repairType?: InputMaybe<HdmRepairEventType>;
};

/**
 * The different normalizations allowed when viewing an HDM Table. These normalizations represent
 * selecting from a materialized view table. Raw represents selectign from the main hdm table that the mvs are
 * based off of.
 */
export enum HdmNormalizations {
  /** The value of the cell subtacted by the mean of the whole table. */
  DifferenceFromMean = "difference_from_mean",
  /** The raw value of the cell */
  Raw = "raw",
  /** The value of the cell divided by a constant value provided by the table. */
  ScaledByConstant = "scaled_by_constant",
}

/** Either the first or most recent repair event of each vehicle used in the comparison table. */
export enum HdmRepairEventType {
  First = "First",
  MostRecent = "MostRecent",
}

/** All values of an hdm table, the current odometer and timestamp of when the HDM table was created. */
export type HdmTable = {
  __typename?: "HDMTable";
  /** The odometer value when the hdm table was created. */
  odometer: Scalars["Int"];
  /** The timestamp of the diagnostic start date. */
  timestamp: Scalars["DateTime"];
  /** All values of the hdm table. Their index in the array can be used to determine their position in the 2d chart. */
  values?: Maybe<Array<Scalars["Float"]>>;
};

/** An aggregate value of every index in one hdm table. */
export type HdmTableAggregateValue = {
  __typename?: "HDMTableAggregateValue";
  /** The diagnostic check that is associated with the HDM Table. */
  diagnosticCheckId: Scalars["String"];
  /** The odometer value when the hdm table was created. */
  odometer: Scalars["Int"];
  /** The timestamp of the diagnostic start date. */
  timestamp: Scalars["DateTime"];
  /** A single data point that represents an aggregate value of the whole table. */
  value: Scalars["Float"];
};

/** The zoom argument for the HDM History chart. */
export type HdmZoom = {
  /** the x axis of the chart, either odometer or timestamp. */
  independentVariable: TimeSeriesIndependentVar;
  /** Maximum x value of the chart. */
  max: Scalars["Float"];
  /** Minimum x value of the chart. */
  min: Scalars["Float"];
};

export type HealthCheckResult = {
  __typename?: "HealthCheckResult";
  queryInfo: QueryInfo;
  results?: Maybe<Array<TestResult>>;
  summary: Summary;
};

export type IndependentVarRangeInput = {
  max: Scalars["Float"];
  min: Scalars["Float"];
};

export type InputOrMappedValues = {
  mappedValues?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  rawInput?: InputMaybe<Scalars["String"]>;
};

export type Interval = {
  __typename?: "Interval";
  /** The distance between the beginning and end of an interval. */
  distance: Scalars["Float"];
  /** The units of distance. Only used if the independent variable is a timestamp. */
  timeUnit?: Maybe<TimeIntervalUnit>;
};

export type IntervalInput = {
  /** The distance between the beginning and end of an interval. */
  distance: Scalars["Float"];
  /** The units of distance. Only used if the independent variable is a timestamp. */
  timeUnit?: InputMaybe<TimeIntervalUnit>;
};

export enum Last_Update_Received_Interval {
  MoreThanAMonth = "MORE_THAN_A_MONTH",
  OneMonth = "ONE_MONTH",
  OneWeek = "ONE_WEEK",
  TwoWeeks = "TWO_WEEKS",
}

export type Location = {
  __typename?: "Location";
  column: Scalars["Int"];
  line: Scalars["Int"];
};

export type Mutation = {
  __typename?: "Mutation";
  /** Adds a set of pvins to a determinate cluster id. */
  addPvinsToCluster: EditClusterMissingPvins;
  /** Copies a cluster and its pvins to another cluster. */
  copyCluster?: Maybe<Scalars["SignedId"]>;
  /** Creates a set of pvins in the database for an specified cluster id and cluster id. */
  createClusterPvins?: Maybe<Scalars["SignedId"]>;
  /** Deletes all pvins related to the cluster id. */
  deleteAllPvinsFromCluster?: Maybe<Scalars["SignedId"]>;
  /** Removes a set of pvins to a determinate cluster id. */
  removePvinsFromCluster?: Maybe<Scalars["SignedId"]>;
};

export type MutationAddPvinsToClusterArgs = {
  clusterId: Scalars["SignedId"];
  input: Array<ClusterRedshiftCreateInput>;
};

export type MutationCopyClusterArgs = {
  clusterId: Scalars["SignedId"];
  copyClusterId: Scalars["SignedId"];
};

export type MutationCreateClusterPvinsArgs = {
  clusterId: Scalars["SignedId"];
  input: Array<ClusterRedshiftCreateInput>;
};

export type MutationDeleteAllPvinsFromClusterArgs = {
  clusterId: Scalars["SignedId"];
};

export type MutationRemovePvinsFromClusterArgs = {
  clusterId: Scalars["SignedId"];
  ignoreDateRanges?: InputMaybe<Scalars["Boolean"]>;
  input: Array<ClusterRedshiftCreateInput>;
};

export type OdometerBounds = {
  max: Scalars["Int"];
  min: Scalars["Int"];
};

export type PaginatedEventOccurrences = {
  __typename?: "PaginatedEventOccurrences";
  occurrences: Array<EventOccurrence>;
  pagination: PaginationResult;
};

export type PaginatedFaultStats = {
  __typename?: "PaginatedFaultStats";
  result: Array<FaultStat>;
  totalCount: Scalars["Int"];
};

export type PaginatedRelatedEvents = {
  __typename?: "PaginatedRelatedEvents";
  events: Array<RelatedEvent>;
  pagination: PaginationResult;
};

export type Pagination = {
  currentPage: Scalars["Int"];
  pageSize: Scalars["Int"];
};

export type PaginationResult = {
  __typename?: "PaginationResult";
  currentPage: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalCount: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export enum Permission {
  Admin = "Admin",
  EditClusters = "EditClusters",
  EditCustomEvents = "EditCustomEvents",
  EditExpression = "EditExpression",
  EditExpressionAnomaly = "EditExpressionAnomaly",
  EditExpressionEvent = "EditExpressionEvent",
  EditForecast = "EditForecast",
  EditRepairConcept = "EditRepairConcept",
  ExecuteBackgroundJob = "ExecuteBackgroundJob",
  ExecuteHealthCheck = "ExecuteHealthCheck",
  ReadBackgroundJob = "ReadBackgroundJob",
  ReadClusters = "ReadClusters",
  ReadCustomEvents = "ReadCustomEvents",
  ReadDataSources = "ReadDataSources",
  ReadExpression = "ReadExpression",
  ReadExpressionAnomaly = "ReadExpressionAnomaly",
  ReadExpressionEvent = "ReadExpressionEvent",
  ReadForecast = "ReadForecast",
  ReadRepairConcept = "ReadRepairConcept",
  ReadVehicles = "ReadVehicles",
  Service = "Service",
}

export type Query = {
  __typename?: "Query";
  /**
   * Returns all HDM tables for a specifc pvin/table id combo. Returns the data as selected aggregate value of the whole table, only one value
   * will be returned for each table.
   */
  aggregateHDMTableValues: Array<HdmTableAggregateValue>;
  /** gets all the possible values that can be used in the global filter */
  allFilterValues: AllFilterValues;
  /**
   * Retrieves details for anomaly detectors (including both ML and expression anomaly detectors) matching the
   * specified TimeSeriesFieldIds if one exists, otherwise null.
   */
  anomalyDetectorsForTimeSeriesFieldIds: Array<Maybe<AnomalyDetector>>;
  /** Returns all Data sources names and its status depending on last update. */
  dataSourceStatus: Array<DataSourceStatusInfo>;
  /**
   * Returns the default comparison values. The default values are the attributes that match the vehicle being viewd in SCV
   * or the most prevelant attributes for a cluster.
   */
  defaultHDMComparisonOptions: HdmComparisonOptions;
  /**
   * Verifies a specified set of VehiclePoints against data in the data warehouse, and enriches it with other data.
   * The size of the returned array should match the size of the input points; if any PVINs cannot be verified in
   * the data warehouse, a null entry is returned. In every returned point, the odometer is populated with
   * contemporaneous value corresponding to the timestamp, and vice-versa.
   */
  enrichVehicleTimestampData: Array<Maybe<VehiclePoint>>;
  /** Returns the rate of occurrence of events over the vehicle fleet, in each independent interval of specified size. */
  eventRateHistory: Array<EventRate>;
  /** Retrieves all possible values of a fault history for filtering */
  faultHistoryByUserInput: Array<FaultHistoryFilterData>;
  /**
   * Retrieves all possible values of a fault history column (defined as param) and an input provided.
   * Optional: If you want all possible column values from a specific pvin, you can add it as parameter
   */
  faultHistoryColumnByUserInput: Array<Scalars["String"]>;
  /**
   * Takes in the global filter as an argument and passes the filter down to all children
   * resolvers that are under type FilteredQuery
   */
  filteredQuery: FilteredQuery;
  /** Returns all values that can be used to define a comparison. */
  hdmComparisonOptions: HdmComparisonOptions;
  /**
   * Returns an aggregate of each index in all hdm tables that match the given comparison definition. Each index will be an aggegate value of all
   * elements at that index.
   */
  hdmComparisonTable?: Maybe<HdmComparisonTable>;
  /** Returns a specifc HDM Table for a pvin/diagnostic_check/table ID combination. */
  hdmTable: HdmTable;
  healthCheck: HealthCheckResult;
  /**
   * Retrieves a list of missing pvins inside the Vehicles table
   * receives an array of pvins to search.
   */
  missingPvins: Array<Scalars["String"]>;
  /** Retrieves details for all ML anomaly detectors. */
  mlAnomalyDetectors: Array<AnomalyDetector>;
  /**
   * Searches for pvins that match the input string.
   * Optional limit to limit the number of results returned (default is 20).
   * Used for search auto complete.
   */
  searchVehicles: Array<Maybe<Vehicle>>;
  serverInfo: ServerInfo;
  singleVehicleAnomalyDetector?: Maybe<SingleVehicleAnomalyDetector>;
  /**
   * Query to get anomaly detectors data for a vehicle
   * TODO: Retire and replace with better AD API
   */
  singleVehicleAnomalyDetectors: SingleVehicleAnomaliesResponse;
  /**
   * Retrieves vehicle counts grouped by months since delivery.
   * If bucketSize is specified, each bucket in the result with a min and max will be of the specified size,
   *   in months. Otherwise, a default bucketSize of 1 will be used.
   * If min is specified, the first bucket in the result will include all vehicles with age since delivery less than this,
   *   in months. Otherwise, a default min of 1 will be used.
   * If max is specified, the last bucket in the result will include all vehicles with age since delivery greater than this,
   *   in months. Otherwise, a default max of 60 will be used.
   */
  vehicleAgeHistogram: Array<VehicleHistogramByAge>;
  /** Retrieves the vehicle categorical data (general data of vehicle) */
  vehicleDetails: Array<Vehicle>;
  /**
   * Each element i in the returned collection describes the distribution of monthly driving distances for
   * vehicles with a starting odometer between i*bucketSize and (i+1)*bucketSize kilometers.
   */
  vehicleDrivingDistanceHistograms: Array<VehicleDrivingDistancePerMonth>;
  /** Query to get the fault history of a vehicle. If configured, the returned faults will be limited to certain ECUs. */
  vehicleFaultHistory: FaultHistoryPaginated;
  /**
   * Retrieves vehicle stats grouped by a certain range on mileage.
   * If bucketSize is specified, each bucket in the result with a min and max will be of the specified size,
   *   in kilometers. Otherwise, a default bucketSize of 5000 will be used.
   * If min is specified, the first bucket in the result will include all vehicles with odometer less than this,
   *   in kilometers. Otherwise, a default min of 5000 will be used.
   * If max is specified, the last bucket in the result will include all vehicles with odometer greater than this,
   *   in kilometers. Otherwise, a default max of 45000 will be used.
   */
  vehicleMileageHistogram: Array<VehicleHistogramByMileage>;
  /** Query to get Repair Event History data for a vehicle. returns the RepairEventHistory data */
  vehicleRepairEventHistory: Array<RepairEventHistory>;
  vehicleTimeSeries: TimeSeriesData;
  /** Similar to vehicleTimeSeries but returns only the xAxis data, handy for DTC-only customers */
  vehicleTimeSeriesXAxis: TimeSeriesData;
};

export type QueryAggregateHdmTableValuesArgs = {
  pvin: Scalars["String"];
  tableId: Scalars["String"];
  zoom?: InputMaybe<HdmZoom>;
};

export type QueryAnomalyDetectorsForTimeSeriesFieldIdsArgs = {
  ids: Array<Scalars["TimeSeriesFieldId"]>;
};

export type QueryDefaultHdmComparisonOptionsArgs = {
  clusterId?: InputMaybe<Scalars["String"]>;
  pvin?: InputMaybe<Scalars["String"]>;
};

export type QueryEnrichVehicleTimestampDataArgs = {
  points: Array<VehiclePointInput>;
};

export type QueryEventRateHistoryArgs = {
  eventFilters: Array<EventFilter>;
  independentVariable: TimeSeriesIndependentVar;
  intervalSize: Scalars["Int"];
};

export type QueryFaultHistoryByUserInputArgs = {
  pvin: Scalars["String"];
};

export type QueryFaultHistoryColumnByUserInputArgs = {
  column: FaultHistoryColumn;
  columnFilters?: InputMaybe<Array<ColumnFilter>>;
  input: Scalars["String"];
  pvin?: InputMaybe<Scalars["String"]>;
};

export type QueryFilteredQueryArgs = {
  filter: FilterInput;
};

export type QueryHdmComparisonTableArgs = {
  clusterComparison?: InputMaybe<HdmClusterComparisonInput>;
  clusterId?: InputMaybe<Scalars["String"]>;
  customComparison?: InputMaybe<HdmCustomComparisonInput>;
  normalization?: InputMaybe<HdmNormalizations>;
  pvin?: InputMaybe<Scalars["String"]>;
  tableId: Scalars["String"];
};

export type QueryHdmTableArgs = {
  diagnosticCheckId: Scalars["String"];
  normalization?: InputMaybe<HdmNormalizations>;
  tableId: Scalars["String"];
};

export type QueryHealthCheckArgs = {
  filePatterns?: InputMaybe<Array<Scalars["String"]>>;
  testNamePatterns?: InputMaybe<Array<Scalars["String"]>>;
  testVariables?: InputMaybe<TestVariablesInput>;
};

export type QueryMissingPvinsArgs = {
  pvins: Array<Scalars["ID"]>;
};

export type QuerySearchVehiclesArgs = {
  input: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QuerySingleVehicleAnomalyDetectorArgs = {
  id: Scalars["ID"];
  pvin: Scalars["ID"];
  type: AnomalyDetectorType;
};

export type QuerySingleVehicleAnomalyDetectorsArgs = {
  pagination: Pagination;
  pvin: Scalars["ID"];
  severity?: InputMaybe<AnomalySeverity>;
  status?: InputMaybe<CalculatedStatus>;
};

export type QueryVehicleAgeHistogramArgs = {
  bucketSize?: InputMaybe<Scalars["Int"]>;
  max?: InputMaybe<Scalars["Int"]>;
  min?: InputMaybe<Scalars["Int"]>;
};

export type QueryVehicleDetailsArgs = {
  id: Scalars["ID"];
};

export type QueryVehicleDrivingDistanceHistogramsArgs = {
  bucketSize?: InputMaybe<Scalars["Int"]>;
};

export type QueryVehicleFaultHistoryArgs = {
  columnFilters?: InputMaybe<Array<ColumnFilter>>;
  id: Scalars["ID"];
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortFilter>>;
};

export type QueryVehicleMileageHistogramArgs = {
  bucketSize?: InputMaybe<Scalars["Int"]>;
  max?: InputMaybe<Scalars["Int"]>;
  min?: InputMaybe<Scalars["Int"]>;
};

export type QueryVehicleRepairEventHistoryArgs = {
  id: Scalars["ID"];
};

export type QueryVehicleTimeSeriesArgs = {
  fields: Array<TimeSeriesFieldInput>;
  id: Scalars["ID"];
  independentVar?: InputMaybe<TimeSeriesIndependentVar>;
  independentVarInterval?: InputMaybe<IntervalInput>;
  independentVarRange?: InputMaybe<IndependentVarRangeInput>;
};

export type QueryVehicleTimeSeriesXAxisArgs = {
  id: Scalars["ID"];
  independentVar?: InputMaybe<TimeSeriesIndependentVar>;
  independentVarInterval?: InputMaybe<IntervalInput>;
  independentVarRange?: InputMaybe<IndependentVarRangeInput>;
};

export type QueryInfo = {
  __typename?: "QueryInfo";
  graphQLResolver?: Maybe<Scalars["String"]>;
};

export type ReadOnlyUser = {
  __typename?: "ReadOnlyUser";
  isMe: Scalars["Boolean"];
  name: Scalars["String"];
};

export type RelatedEvent = {
  __typename?: "RelatedEvent";
  event: EventDescriptor;
  totalOccurrences: Scalars["Int"];
  uniqueVehicles: Scalars["Int"];
};

export enum RelatedEventsSortField {
  EventId = "eventId",
  TotalOccurrences = "totalOccurrences",
  UniqueVehicles = "uniqueVehicles",
}

export type RepairEvent = {
  __typename?: "RepairEvent";
  /** The cluster id of the repair event if it has been sorted already. This can be used to see if our recomendations are accurate */
  clusterId?: Maybe<Scalars["ID"]>;
  /** The location in the vehicle where the failure occurred. */
  componentLocationId?: Maybe<Scalars["String"]>;
  /** The id of the diagnostic check for this maintence log. */
  diagnosticCheckId?: Maybe<Scalars["String"]>;
  /** The id of the maintenance history */
  id?: Maybe<Scalars["ID"]>;
  /** Vehicle model */
  model?: Maybe<Scalars["String"]>;
  /** Model year */
  modelYear?: Maybe<Scalars["Int"]>;
  /** Unique identifier for the vehicle (sometimes called PVIN, or pseudo-anonymized VIN) */
  pvin?: Maybe<Scalars["Pvin"]>;
  /** The date the repair ended */
  repairEndDate?: Maybe<Scalars["DateTime"]>;
  /** The date the repair started */
  repairStartDate?: Maybe<Scalars["DateTime"]>;
  /** The vehicle's VIN if enabled */
  vin?: Maybe<Scalars["String"]>;
};

export type RepairEventClusterMatch = {
  __typename?: "RepairEventClusterMatch";
  /** The component location id in the combination present in the recommended cluster */
  componentLocationId: Scalars["String"];
  /** The fault code in the combination present in the recommended cluster */
  faultCode: Scalars["String"];
  /** The frequency that the component location id and fault code combination occurs within the recommended cluster; used as a proxy for probability */
  frequency: Scalars["Float"];
  /** The repair event that the recommendations are generated for */
  percentOfTotal: Scalars["Float"];
  /** Percent of repair events that contain the same component location id and fault code combination within the cluster */
  recommendedCluster: Scalars["ID"];
  /** The id of the recommended cluster */
  recommendedClusterId: Scalars["ID"];
  /** Id of the maintenance history event */
  repairEventId: Scalars["ID"];
};

export type RepairEventHistory = {
  __typename?: "RepairEventHistory";
  /** Repair event end date */
  endDate?: Maybe<Scalars["DateTime"]>;
  /** Globally unique identifier for the repair event occurrence */
  id: Scalars["String"];
  /** KM / Mileage where the repair event was registered. */
  odometer?: Maybe<Scalars["Float"]>;
  /** PVIN for the Repair Event */
  pvin: Scalars["String"];
  /** Repair event start date */
  startDate?: Maybe<Scalars["DateTime"]>;
  /** Repair type. */
  type?: Maybe<RepairEventType>;
};

export enum RepairEventType {
  BatteryFullReplacement = "BATTERY_FULL_REPLACEMENT",
  BatteryModuleReplacement = "BATTERY_MODULE_REPLACEMENT",
}

export enum RepairEventsColumns {
  ClusterId = "cluster_id",
  ComponentLocationId = "component_location_id",
  DiagnosticCheckId = "diagnostic_check_id",
  Id = "id",
  MaintenanceEnd = "maintenance_end",
  MaintenanceStart = "maintenance_start",
  ModelStyle = "model_style",
  ModelYear = "model_year",
  Pvin = "pvin",
  Vin = "vin",
}

export type RepairEventsPaginated = {
  __typename?: "RepairEventsPaginated";
  data: Array<RepairEvent>;
  pagination?: Maybe<PaginationResult>;
};

export type SavedExpression = {
  __typename?: "SavedExpression";
  created?: Maybe<Scalars["DateTime"]>;
  creator?: Maybe<ReadOnlyUser>;
  customerId?: Maybe<Scalars["ID"]>;
  definition?: Maybe<Scalars["JSONObject"]>;
  /**
   * A customer-unique ID for the expression definition. This value will be updated with unique values
   * for `definition`. Use this value to access the calculated results of the expression in
   * customer-specific times series APIs such as `vehicleTimeSeries` and `vehicleCohortTimeSeries`.
   */
  definitionFieldId: Scalars["ID"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  inputIds: Array<Scalars["ID"]>;
  lastModified?: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  readOnly?: Maybe<Scalars["Boolean"]>;
  status?: Maybe<CalculatedStatus>;
  version?: Maybe<Scalars["Int"]>;
};

export type ServerInfo = {
  __typename?: "ServerInfo";
  version: Scalars["String"];
};

export type SingleVehicleAnomaliesInput = {
  pagination?: InputMaybe<Pagination>;
  pvin: Scalars["ID"];
  severity?: InputMaybe<AnomalySeverity>;
  status?: InputMaybe<CalculatedStatus>;
};

export type SingleVehicleAnomaliesResponse = {
  __typename?: "SingleVehicleAnomaliesResponse";
  detectors: Array<SingleVehicleAnomalyDetectorHeaders>;
  pagination: PaginationResult;
  severityCounts: AnomalySeverityCounts;
};

export type SingleVehicleAnomalyDetector = {
  __typename?: "SingleVehicleAnomalyDetector";
  detector: AnomalyDetector;
  vehicleData: VehicleAnomalyDetectorFields;
};

export type SingleVehicleAnomalyDetectorHeaders = {
  __typename?: "SingleVehicleAnomalyDetectorHeaders";
  /** Anomaly detector description */
  description?: Maybe<Scalars["String"]>;
  /** Anomaly detector ID */
  id: Scalars["ID"];
  /** Anomaly detector name */
  name: Scalars["String"];
  /** Anomaly detector latest score */
  score?: Maybe<Scalars["Float"]>;
  /** Anomaly detector severity (for coloring) */
  severity: AnomalySeverity;
  /** Optionally pass a detector source/label to be shown in the detector header. */
  source?: Maybe<Scalars["String"]>;
  /** Anomaly detector type (ML/Pattern/Customer) */
  type: AnomalyDetectorType;
};

export type SortFilter = {
  field: Scalars["String"];
  order: SortOrder;
};

export type SortFilterResult = {
  __typename?: "SortFilterResult";
  field: Scalars["String"];
  order: SortOrder;
};

export enum SortOrder {
  Ascend = "ascend",
  Descend = "descend",
}

export type SpeedHistogramBucket = {
  __typename?: "SpeedHistogramBucket";
  /** The ratio of speed samples that fall in the specified bucket to total number of speed samples for all vehicles. */
  fleet: Scalars["Float"];
  /** Maximum range speed for the bucket. If null, includes all temperatures above max speed. */
  maxSpeed?: Maybe<Scalars["Int"]>;
  /** Minimum range speed for the bucket. If null, includes all speeds below minimun speed. */
  minSpeed?: Maybe<Scalars["Int"]>;
  /** The ratio of speed samples that fall in the specified bucket to total number of speed samples for this vehicle. */
  vehicle: Scalars["Float"];
};

export type SqlProviderInternal = {
  parameters: Array<Scalars["String"]>;
  sql: Scalars["String"];
};

export type Summary = {
  __typename?: "Summary";
  numFailedTestSuites: Scalars["Int"];
  numFailedTests: Scalars["Int"];
  numPassedTestSuites: Scalars["Int"];
  numPassedTests: Scalars["Int"];
  numPendingTestSuites: Scalars["Int"];
  numPendingTests: Scalars["Int"];
  numTodoTests: Scalars["Int"];
  numTotalTestSuites: Scalars["Int"];
  numTotalTests: Scalars["Int"];
  startTime: Scalars["String"];
  success: Scalars["Boolean"];
};

export type TemperatureHistogramBucket = {
  __typename?: "TemperatureHistogramBucket";
  /** The ratio of ambient temperature samples that fall in the specified bucket to total number of temperature samples for all vehicles. */
  fleet: Scalars["Float"];
  /** Maximum range temperature for the bucket. If null, includes all temperatures above minTemperature */
  maxTemperature?: Maybe<Scalars["Int"]>;
  /** Minimum range temperature for the bucket. If null, includes all temperatures below maxTemperature. */
  minTemperature?: Maybe<Scalars["Int"]>;
  /** The ratio of ambient temperature samples that fall in the specified bucket to total number of temperature samples for this vehicle. */
  vehicle: Scalars["Float"];
};

export type Test = {
  __typename?: "Test";
  errorLogs?: Maybe<Array<Maybe<ErrorLog>>>;
  name: Scalars["String"];
  status?: Maybe<Scalars["String"]>;
  testDuration?: Maybe<Scalars["Int"]>;
};

export type TestResult = {
  __typename?: "TestResult";
  assertions?: Maybe<Array<AssertionResult>>;
  fileName: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
  tests?: Maybe<Array<Test>>;
};

export type TestVariablesInput = {
  anomalyDetectorId?: InputMaybe<Scalars["String"]>;
  patternId?: InputMaybe<Scalars["String"]>;
  pvin?: InputMaybe<Scalars["String"]>;
};

export enum TimeIntervalUnit {
  Day = "Day",
  DayOfMonth = "DayOfMonth",
  Hour = "Hour",
  HourOfDay = "HourOfDay",
  Minute = "Minute",
  MinuteOfHour = "MinuteOfHour",
  Month = "Month",
  Year = "Year",
}

export enum TimeSeriesComparisonGroup {
  BottomQuartile = "BOTTOM_QUARTILE",
  Mean = "MEAN",
  Median = "MEDIAN",
  Stddev = "STDDEV",
  TopQuartile = "TOP_QUARTILE",
}

export type TimeSeriesData = {
  __typename?: "TimeSeriesData";
  count?: Maybe<Array<Array<Maybe<Scalars["Int"]>>>>;
  high?: Maybe<Array<Array<Maybe<Scalars["Float"]>>>>;
  /** The aggregation interval applied. This is needed if the server calculates a default value. */
  independentVarInterval?: Maybe<Interval>;
  low?: Maybe<Array<Array<Maybe<Scalars["Float"]>>>>;
  /** The series of independent variable values. */
  x: Array<Scalars["Float"]>;
  /** Value of each measure */
  y: Array<Array<Maybe<Scalars["Float"]>>>;
};

export type TimeSeriesField = {
  __typename?: "TimeSeriesField";
  /**
   * The types of aggregation operations supported by the system when computing data for any interval size
   * (including 1 minute).
   */
  aggregationTypes?: Maybe<Array<AggregateType>>;
  displayName?: Maybe<Scalars["String"]>;
  /**
   * If applicable, the id of the underlying entity which generates the time series. This could be e.g. an expression id
   * or an anomaly detector id.
   */
  entityId?: Maybe<Scalars["String"]>;
  family: TimeSeriesFieldFamily;
  id: Scalars["TimeSeriesFieldId"];
  inputIds: Array<Scalars["TimeSeriesFieldId"]>;
  name?: Maybe<Scalars["String"]>;
  /**
   * The types of aggregation operations supported by the system when computing data for 1-minute intervals.
   * Suitable for e.g. use in expression definitions.
   */
  oneMinuteAggregationTypes?: Maybe<Array<AggregateType>>;
  type?: Maybe<TimeSeriesFieldDataType>;
  unit?: Maybe<Scalars["String"]>;
};

export enum TimeSeriesFieldDataType {
  Boolean = "BOOLEAN",
  Float = "FLOAT",
  Integer = "INTEGER",
  String = "STRING",
}

export enum TimeSeriesFieldFamily {
  AnomalyScore = "ANOMALY_SCORE",
  Expression = "EXPRESSION",
  Feature = "FEATURE",
  Signal = "SIGNAL",
}

export type TimeSeriesFieldInput = {
  /** The type of aggregation performed to reduce the time series values to the specified interval. */
  aggregateType: AggregateType;
  id: Scalars["TimeSeriesFieldId"];
};

export enum TimeSeriesIndependentVar {
  Mileage = "MILEAGE",
  Time = "TIME",
}

/** Input to get Top Fault Codes combined (or not) with trouble code and ECU */
export type TopFaultParamsInput = {
  includeHardwarePartNumber?: InputMaybe<Scalars["Boolean"]>;
  includeHardwareVersion?: InputMaybe<Scalars["Boolean"]>;
  includeSoftwarePartNumber?: InputMaybe<Scalars["Boolean"]>;
  includeSoftwareVersion?: InputMaybe<Scalars["Boolean"]>;
  includeTroubleCode?: InputMaybe<Scalars["Boolean"]>;
};

export enum TypeRange {
  Odometer = "ODOMETER",
  Time = "TIME",
}

export enum UserEventsSortField {
  LastModified = "lastModified",
  Name = "name",
  Status = "status",
}

export type Vehicle = {
  __typename?: "Vehicle";
  /** Country code */
  country?: Maybe<Scalars["String"]>;
  /** Delivery date */
  deliveryDate?: Maybe<Scalars["DateTime"]>;
  /** Unique identifier for the vehicle (sometimes called PVIN, or pseudo-anonymized VIN) */
  id: Scalars["Pvin"];
  /** Vehicle dealer identifier */
  importer?: Maybe<Scalars["ID"]>;
  /** Last diagnostic check date */
  lastDiagnosticCheckDate?: Maybe<Scalars["DateTime"]>;
  /** Last reading date */
  lastReadingDate?: Maybe<Scalars["DateTime"]>;
  /** Vehicle model */
  model?: Maybe<Scalars["String"]>;
  /** Vehicle model style */
  modelStyle?: Maybe<Scalars["String"]>;
  /** KM / Mileage on last reading */
  odometer?: Maybe<Scalars["Float"]>;
  /** Production date */
  productionDate?: Maybe<Scalars["DateTime"]>;
  /** VIN. Only populated if this feature is enabled for the customer */
  vin?: Maybe<Scalars["Vin"]>;
  /** Model year */
  year?: Maybe<Scalars["Int"]>;
};

export type VehicleAnomalyDetectorFields = {
  __typename?: "VehicleAnomalyDetectorFields";
  /** Number of times the score went above the configured warning threshold */
  alertsCount?: Maybe<Scalars["Int"]>;
  /** Count of days where score was over the configured warning threshold */
  anomalousDaysCount?: Maybe<Scalars["Int"]>;
  /** Anomaly detector signal fields ML weights result */
  fieldsWeights?: Maybe<Array<FieldInputWeight>>;
  /** The timestamp for when the anomaly was firstDetected */
  firstDetected?: Maybe<Scalars["DateTime"]>;
  /** Anomaly detector score history */
  history: TimeSeriesData;
  /** Anomaly detector current score */
  score?: Maybe<Scalars["Float"]>;
};

export enum VehicleAnomalyState {
  Caution = "CAUTION",
  NotAnomalous = "NOT_ANOMALOUS",
  Warning = "WARNING",
}

export type VehicleBoxPlot = {
  __typename?: "VehicleBoxPlot";
  fleet?: Maybe<VehicleBoxPlotValues>;
  vehicle?: Maybe<VehicleBoxPlotValues>;
};

export type VehicleBoxPlotValues = {
  __typename?: "VehicleBoxPlotValues";
  category: Scalars["String"];
  median: Scalars["Float"];
  p5: Scalars["Float"];
  p25: Scalars["Float"];
  p75: Scalars["Float"];
  p95: Scalars["Float"];
};

export enum VehicleCohortEpoch {
  DaysSinceDelivery = "DaysSinceDelivery",
  DaysSinceProduction = "DaysSinceProduction",
}

export type VehicleCohortTimeSeriesData = {
  __typename?: "VehicleCohortTimeSeriesData";
  /** An array of arrays containing the 5th percentile of the requested field values. */
  bottom5Perc: Array<Array<Maybe<Scalars["Float"]>>>;
  /** An array of arrays containing the 25th percentile of the requested field values. */
  bottomQuartile: Array<Array<Maybe<Scalars["Float"]>>>;
  /** The cohort comparison filter criteria */
  cohortFilter?: Maybe<VehicleFilterValues>;
  /** The aggregation interval applied. This is needed if the server calculates a default value. */
  independentVarInterval?: Maybe<Interval>;
  /** An array of arrays containing the max of the requested field values. */
  max: Array<Array<Maybe<Scalars["Float"]>>>;
  /** An array of arrays containing the mean of the requested field values. */
  mean: Array<Array<Maybe<Scalars["Float"]>>>;
  /** An array of arrays containing the median of the requested field values. */
  median: Array<Array<Maybe<Scalars["Float"]>>>;
  /** An array of arrays containing the min of the requested field values. */
  min: Array<Array<Maybe<Scalars["Float"]>>>;
  /** An array of arrays containing the stddev of the requested field values. */
  stddev: Array<Array<Maybe<Scalars["Float"]>>>;
  /** An array of arrays containing the 95th percentile of the requested field values. */
  top5Perc: Array<Array<Maybe<Scalars["Float"]>>>;
  /** An array of arrays containing the 75th percentile of the requested field values. */
  topQuartile: Array<Array<Maybe<Scalars["Float"]>>>;
  /** The series of independent variable values. */
  x: Array<Scalars["Float"]>;
};

export type VehicleCohortTimeSeriesSummaryData = {
  __typename?: "VehicleCohortTimeSeriesSummaryData";
  bottomDecile?: Maybe<Scalars["Float"]>;
  bottomQuartile?: Maybe<Scalars["Float"]>;
  bottomVigintile?: Maybe<Scalars["Float"]>;
  max?: Maybe<Scalars["Float"]>;
  mean?: Maybe<Scalars["Float"]>;
  median?: Maybe<Scalars["Float"]>;
  min?: Maybe<Scalars["Float"]>;
  standardDeviation?: Maybe<Scalars["Float"]>;
  topDecile?: Maybe<Scalars["Float"]>;
  topQuartile?: Maybe<Scalars["Float"]>;
  topVigintile?: Maybe<Scalars["Float"]>;
};

export type VehicleCountByCountry = {
  __typename?: "VehicleCountByCountry";
  /** The delivery country of the vehicle as an ISO 3166 2-letter code */
  country?: Maybe<Scalars["ID"]>;
  /** The total count of vehicles delivered in this country */
  vehicles?: Maybe<Scalars["Int"]>;
  /** The total count of vehicles with maintenance events for all vehicles delivered in this country, of all types */
  vehiclesWithMaintenanceEvents?: Maybe<Scalars["Int"]>;
};

export type VehicleCountByCountryWithTotalCount = {
  __typename?: "VehicleCountByCountryWithTotalCount";
  /** Total Vehicles on the customer */
  totalVehicles: Scalars["Int"];
  /** Total of countries with the number of vehicles delivered in this country. */
  vehiclesByCountry: Array<VehicleCountByCountry>;
};

export type VehicleDrivingDistancePerMonth = {
  __typename?: "VehicleDrivingDistancePerMonth";
  /**
   * Each element i is the fraction of the fleet that drives between i*interval and (i+1)*interval
   * kilometers per month
   */
  distances: Array<Maybe<Scalars["Float"]>>;
};

/** Vehicle input that is being used as variable for the queries */
export type VehicleFilterInput = {
  /** array of car delivery countries */
  deliveryCountry?: InputMaybe<Array<Scalars["String"]>>;
  /** array of car models */
  model?: InputMaybe<Array<Scalars["String"]>>;
  /** array of car model styles */
  modelStyle?: InputMaybe<Array<Scalars["String"]>>;
  /** array of car models years */
  modelYear?: InputMaybe<Array<Scalars["Int"]>>;
};

/** type version of what is being passed down to resolvers */
export type VehicleFilterInputType = {
  __typename?: "VehicleFilterInputType";
  /** array of car delivery countries */
  deliveryCountry?: Maybe<Array<Scalars["String"]>>;
  /** array of car models */
  model?: Maybe<Array<Scalars["String"]>>;
  /** array of car models styles */
  modelStyle?: Maybe<Array<Scalars["String"]>>;
  /** array of car models years */
  modelYear?: Maybe<Array<Scalars["Int"]>>;
};

/** return type of vehicle values for the UI */
export type VehicleFilterValues = {
  __typename?: "VehicleFilterValues";
  /** array of car delivery countries */
  deliveryCountry: Array<Scalars["String"]>;
  /** array of car models */
  model: Array<Scalars["String"]>;
  /** array of car models styles */
  modelStyle: Array<Scalars["String"]>;
  /** array of car models years */
  modelYear: Array<Scalars["Int"]>;
};

export type VehicleHistogramByAge = {
  __typename?: "VehicleHistogramByAge";
  /** Number of months since delivery */
  months: Scalars["Int"];
  vehicleCount: Scalars["Int"];
};

export type VehicleHistogramByMileage = {
  __typename?: "VehicleHistogramByMileage";
  /** Maximum mileage of the bucket on Mileage medition */
  maxMileage?: Maybe<Scalars["Int"]>;
  /** Minimum mileage of the bucket on Mileage medition */
  minMileage?: Maybe<Scalars["Int"]>;
  /** Number of vehicle with the odometer mileage inside min and max parameters. */
  vehicleCount: Scalars["Int"];
};

export type VehicleHistogramByModelYear = {
  __typename?: "VehicleHistogramByModelYear";
  vehicleCount: Scalars["Int"];
  year: Scalars["Int"];
};

export type VehicleIdentifier = {
  __typename?: "VehicleIdentifier";
  /** Unique identifier for the vehicle (sometimes called PVIN, or pseudo-anonymized VIN) */
  id: Scalars["Pvin"];
  /** VIN. Only populated if this feature is enabled for the customer */
  vin?: Maybe<Scalars["Vin"]>;
};

export type VehicleLastUpdate = {
  __typename?: "VehicleLastUpdate";
  /** Interval (one week, two weeks, one month, or more than one month) where it is the last update of the vehicle in the Datasource */
  interval: Last_Update_Received_Interval;
  /** Number of vehicles inside the interval. */
  vehicleCount: Scalars["Int"];
};

/** The output equivalent of VehiclePointInput */
export type VehiclePoint = {
  __typename?: "VehiclePoint";
  odometer?: Maybe<Scalars["Float"]>;
  pvin: Scalars["ID"];
  timestamp?: Maybe<Scalars["DateTime"]>;
};

/**
 * Describes a specific moment for a PVIN that can be defined by either timestamp or mileage (or both).
 * These are the typical line items associated with a user-defined event.
 */
export type VehiclePointInput = {
  /** The odometer value to which this tuple applies. This or timestamp must be non-null. */
  odometer?: InputMaybe<Scalars["Float"]>;
  /** The id of the vehicle */
  pvin: Scalars["ID"];
  /** The timestamp to which this tuple applies. This or odometer must be non-null. */
  timestamp?: InputMaybe<Scalars["DateTime"]>;
};

export type VehicleCountPerInterval = {
  __typename?: "vehicleCountPerInterval";
  /** The ending km or days since delivery of the interval */
  endInterval: Scalars["Int"];
  /** The number of vehicles that exist anywhere in the interval */
  numVehicles: Scalars["Int"];
};

export type DistanceVersusFailureAndRepairCountQueryVariables = Exact<{
  clusterId: Scalars["ID"];
  filter: FilterInput;
  odometerBounds?: InputMaybe<OdometerBounds>;
}>;

export type DistanceVersusFailureAndRepairCountQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    distanceVersusFailureAndRepairCount: Array<{
      __typename?: "FailureCountByDistance";
      distanceStart: number;
      distanceEnd: number;
      faultCount: number;
      faultPvinCount: number;
      maintenanceCount: number;
      maintenancePvinCount: number;
      bucketNumber: number;
    }>;
  };
};

export type DistanceVersusFailureAndRepairCountPvinsQueryVariables = Exact<{
  clusterId: Scalars["ID"];
  filter: FilterInput;
  odometerBounds?: InputMaybe<OdometerBounds>;
}>;

export type DistanceVersusFailureAndRepairCountPvinsQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    distanceVersusFailureAndRepairCountPvins: Array<{
      __typename?: "AffectedVehicleAttributes";
      id: string;
      vin?: string | null;
      model: string;
      modelStyle: string;
      modelYear: number;
    }>;
  };
};

export type AttributeCountQueryVariables = Exact<{
  clusterId: Scalars["ID"];
  filter: FilterInput;
  attribute: AttributeTypeEnum;
}>;

export type AttributeCountQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    attributeCount: Array<{ __typename?: "AttributeCount"; attribute: string; pvinCount: number }>;
  };
};

export type AttributeCountPvinsQueryVariables = Exact<{
  clusterId: Scalars["ID"];
  filter: FilterInput;
  attribute: AttributeTypeEnum;
  attributeValue: Scalars["String"];
}>;

export type AttributeCountPvinsQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    attributeCountPvins: Array<{
      __typename?: "AffectedVehicleAttributes";
      id: string;
      vin?: string | null;
      model: string;
      modelStyle: string;
      modelYear: number;
    }>;
  };
};

export type VehicleChargingSessionDurationStatsQueryVariables = Exact<{
  filter: FilterInput;
  vehicleId: Scalars["ID"];
}>;

export type VehicleChargingSessionDurationStatsQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    vehicleChargingSessionDurationStats: {
      __typename?: "VehicleBoxPlot";
      vehicle?: {
        __typename?: "VehicleBoxPlotValues";
        p5: number;
        p25: number;
        median: number;
        p75: number;
        p95: number;
      } | null;
      fleet?: {
        __typename?: "VehicleBoxPlotValues";
        p5: number;
        p25: number;
        median: number;
        p75: number;
        p95: number;
      } | null;
    };
  };
};

export type VehicleHistoricalDataAnalysisQueryVariables = Exact<{
  vehicleId: Scalars["ID"];
  independentVar?: InputMaybe<TimeSeriesIndependentVar>;
  independentVarInterval?: InputMaybe<IntervalInput>;
  independentVarRange?: InputMaybe<IndependentVarRangeInput>;
  withXAxis: Scalars["Boolean"];
}>;

export type VehicleHistoricalDataAnalysisQuery = {
  __typename?: "Query";
  vehicleTimeSeriesXAxis?: {
    __typename?: "TimeSeriesData";
    x: Array<number>;
    independentVarInterval?: { __typename?: "Interval"; distance: number; timeUnit?: TimeIntervalUnit | null } | null;
  };
  vehicleFaultHistory: {
    __typename?: "FaultHistoryPaginated";
    data: Array<{
      __typename?: "Fault";
      vehicleId: string;
      code: string;
      troubleCode?: string | null;
      category?: string | null;
      odometer: number;
      id: string;
      source: string;
      startTime?: string | null;
      endTime?: string | null;
      isDateInferred?: boolean | null;
      fields?: Array<{ __typename?: "FaultField"; id: string; value?: any | null }> | null;
    }>;
  };
  vehicleRepairEventHistory: Array<{
    __typename?: "RepairEventHistory";
    id: string;
    pvin: string;
    odometer?: number | null;
    type?: RepairEventType | null;
    endDate?: string | null;
  }>;
};

export type VehicleSpeedStatsQueryVariables = Exact<{
  filter: FilterInput;
  vehicleId: Scalars["ID"];
}>;

export type VehicleSpeedStatsQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    vehicleSpeedStats: {
      __typename?: "VehicleBoxPlot";
      vehicle?: {
        __typename?: "VehicleBoxPlotValues";
        p5: number;
        p25: number;
        median: number;
        p75: number;
        p95: number;
      } | null;
      fleet?: {
        __typename?: "VehicleBoxPlotValues";
        p5: number;
        p25: number;
        median: number;
        p75: number;
        p95: number;
      } | null;
    };
  };
};

export type VehicleTemperatureStatsQueryVariables = Exact<{
  filter: FilterInput;
  vehicleId: Scalars["ID"];
}>;

export type VehicleTemperatureStatsQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    vehicleTemperatureStats: {
      __typename?: "VehicleBoxPlot";
      vehicle?: {
        __typename?: "VehicleBoxPlotValues";
        p5: number;
        p25: number;
        median: number;
        p75: number;
        p95: number;
      } | null;
      fleet?: {
        __typename?: "VehicleBoxPlotValues";
        p5: number;
        p25: number;
        median: number;
        p75: number;
        p95: number;
      } | null;
    };
  };
};

export type GetEventStatsQueryVariables = Exact<{
  eventFilter: EventFilter;
  timeWindow?: InputMaybe<Scalars["Int"]>;
  filter: FilterInput;
}>;

export type GetEventStatsQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    eventStats: {
      __typename?: "EventStats";
      totalOccurrences: number;
      uniqueVehicles: number;
      fullRepairCorrelation?: number | null;
      partialRepairCorrelation?: number | null;
    };
  };
};

export type VehicleCountForFleetIntelQueryVariables = Exact<{
  filter: FilterInput;
  startDate?: InputMaybe<Scalars["DateTime"]>;
}>;

export type VehicleCountForFleetIntelQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    vehicleCountByCountry: {
      __typename?: "VehicleCountByCountryWithTotalCount";
      totalVehicles: number;
      vehiclesByCountry: Array<{
        __typename?: "VehicleCountByCountry";
        country?: string | null;
        vehicles?: number | null;
        vehiclesWithMaintenanceEvents?: number | null;
      }>;
    };
  };
};

export type AllFilterValuesQueryVariables = Exact<{ [key: string]: never }>;

export type AllFilterValuesQuery = {
  __typename?: "Query";
  allFilterValues: {
    __typename?: "AllFilterValues";
    vehicle: {
      __typename?: "VehicleFilterValues";
      model: Array<string>;
      modelStyle: Array<string>;
      modelYear: Array<number>;
      deliveryCountry: Array<string>;
    };
  };
};

export type FaultHistoryColumnByUserInputQueryVariables = Exact<{
  input: Scalars["String"];
  column: FaultHistoryColumn;
  pvin?: InputMaybe<Scalars["String"]>;
  columnFilters?: InputMaybe<Array<ColumnFilter> | ColumnFilter>;
}>;

export type FaultHistoryColumnByUserInputQuery = { __typename?: "Query"; faultHistoryColumnByUserInput: Array<string> };

export type AnomalyAffectedVehiclesQueryVariables = Exact<{
  anomalyId: Scalars["ID"];
  anomalyStatus?: InputMaybe<CalculatedStatus>;
  pagination?: InputMaybe<Pagination>;
  sorting?: InputMaybe<SortFilter>;
  filter: FilterInput;
}>;

export type AnomalyAffectedVehiclesQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    anomalyAffectedVehicles: {
      __typename?: "AnomalyAffectedVehiclesResponse";
      data: Array<{
        __typename?: "AnomalyAffectedVehicle";
        pvin: string;
        vin?: string | null;
        model: string;
        modelStyle: string;
        modelYear: number;
        score?: number | null;
        lastDetected?: string | null;
      }>;
      pagination: { __typename?: "PaginationResult"; currentPage: number; pageSize: number; totalCount: number };
    };
  };
};

export type AnomalySampleVehiclesQueryVariables = Exact<{
  anomalyId: Scalars["ID"];
  anomalyStatus?: InputMaybe<CalculatedStatus>;
  sampleSize: Scalars["Int"];
  filter: FilterInput;
}>;

export type AnomalySampleVehiclesQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    anomalySampleVehicles: {
      __typename?: "AnomalySampleVehicles";
      data: Array<{
        __typename?: "AnomalySampleVehicle";
        pvin: string;
        vin?: string | null;
        score?: number | null;
        lastDetected?: string | null;
        odometer?: number | null;
        anomalyState: VehicleAnomalyState;
      }>;
    };
  };
};

export type AnomalyVehicleCountForFleetIntelQueryVariables = Exact<{
  anomalyId: Scalars["ID"];
  anomalyStatus?: InputMaybe<CalculatedStatus>;
  filter: FilterInput;
}>;

export type AnomalyVehicleCountForFleetIntelQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    anomalyVehicleCountByCountry: {
      __typename?: "AnomalyVehicleCountByCountryResponse";
      vehiclesByCountry: Array<{
        __typename?: "AnomalyVehicleCountByCountry";
        country?: string | null;
        vehicles?: number | null;
        vehiclesCautionCount?: number | null;
        vehiclesWarningCount?: number | null;
      }>;
    };
  };
};

export type AnomalySummaryQueryVariables = Exact<{
  filter: FilterInput;
  pagination: Pagination;
  sorting?: InputMaybe<SortFilter>;
  id?: InputMaybe<Scalars["String"]>;
}>;

export type AnomalySummaryQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    anomaliesWithVehicleCount: {
      __typename?: "AnomaliesWithVehicleCountReponse";
      items: Array<{
        __typename?: "AnomalyDetectorWithVehicleData";
        detector: {
          __typename?: "AnomalyDetector";
          id: string;
          name: string;
          description?: string | null;
          version: number;
          created: string;
          lastModified?: string | null;
          type?: AnomalyDetectorType | null;
          status?: CalculatedStatus | null;
          cautionThreshold?: number | null;
          warningThreshold: number;
          inputIds: Array<string>;
          nameKey: string;
          timeSeriesFieldId: string;
          isCustomerMLModel?: boolean | null;
          externalVersion?: string | null;
          creator?: { __typename?: "ReadOnlyUser"; isMe: boolean; name: string } | null;
          expression?: { __typename?: "SavedExpression"; id: string; name: string } | null;
        };
        data?: { __typename?: "AnomalyData"; anomalyVehicleCount: number } | null;
      }>;
      pagination: { __typename?: "PaginationResult"; currentPage: number; pageSize: number; totalCount: number };
    };
  };
};

export type SingleVehicleAnomalyDetectorQueryVariables = Exact<{
  id: Scalars["ID"];
  pvin: Scalars["ID"];
  type: AnomalyDetectorType;
}>;

export type SingleVehicleAnomalyDetectorQuery = {
  __typename?: "Query";
  singleVehicleAnomalyDetector?: {
    __typename?: "SingleVehicleAnomalyDetector";
    detector: {
      __typename?: "AnomalyDetector";
      cautionThreshold?: number | null;
      created: string;
      lastModified?: string | null;
      description?: string | null;
      id: string;
      isCustomerMLModel?: boolean | null;
      type?: AnomalyDetectorType | null;
      name: string;
      nameKey: string;
      version: number;
      warningThreshold: number;
      timeSeriesFieldId: string;
      inputIds: Array<string>;
      creator?: { __typename?: "ReadOnlyUser"; isMe: boolean; name: string } | null;
      expression?: { __typename?: "SavedExpression"; id: string; name: string } | null;
    };
    vehicleData: {
      __typename?: "VehicleAnomalyDetectorFields";
      alertsCount?: number | null;
      anomalousDaysCount?: number | null;
      firstDetected?: string | null;
      score?: number | null;
      fieldsWeights?: Array<{ __typename?: "FieldInputWeight"; id: string; values: Array<number | null> }> | null;
      history: {
        __typename?: "TimeSeriesData";
        count?: Array<Array<number | null>> | null;
        high?: Array<Array<number | null>> | null;
        low?: Array<Array<number | null>> | null;
        x: Array<number>;
        y: Array<Array<number | null>>;
        independentVarInterval?: {
          __typename?: "Interval";
          distance: number;
          timeUnit?: TimeIntervalUnit | null;
        } | null;
      };
    };
  } | null;
};

export type SingleVehicleAnomalyDetectorsQueryVariables = Exact<{
  pagination: Pagination;
  pvin: Scalars["ID"];
  severity?: InputMaybe<AnomalySeverity>;
  status?: InputMaybe<CalculatedStatus>;
}>;

export type SingleVehicleAnomalyDetectorsQuery = {
  __typename?: "Query";
  singleVehicleAnomalyDetectors: {
    __typename?: "SingleVehicleAnomaliesResponse";
    detectors: Array<{
      __typename?: "SingleVehicleAnomalyDetectorHeaders";
      description?: string | null;
      id: string;
      name: string;
      score?: number | null;
      severity: AnomalySeverity;
      type: AnomalyDetectorType;
      source?: string | null;
    }>;
    pagination: {
      __typename?: "PaginationResult";
      currentPage: number;
      pageSize: number;
      totalCount: number;
      totalPages: number;
    };
    severityCounts: {
      __typename?: "AnomalySeverityCounts";
      all: number;
      caution: number;
      normal: number;
      warning: number;
    };
  };
};

export type FaultsByPvinsQueryVariables = Exact<{
  clusterId: Scalars["ID"];
  filter: FilterInput;
  params?: InputMaybe<TopFaultParamsInput>;
  columnFilters?: InputMaybe<Array<ColumnFilter> | ColumnFilter>;
  pagination?: InputMaybe<Pagination>;
  sorting?: InputMaybe<FaultsByPvinsAffectedPvinsSortInput>;
}>;

export type FaultsByPvinsQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    faultsByPvins: {
      __typename?: "PaginatedFaultStats";
      totalCount: number;
      result: Array<{
        __typename?: "FaultStat";
        faultCode: string;
        faultCodeAppearances: number;
        vehiclesAffected: number;
        ecu: string;
        troubleCode?: string | null;
        softwareVersion?: string | null;
        hardwareVersion?: string | null;
        softwarePartNumber?: string | null;
        hardwarePartNumber?: string | null;
      }>;
    };
  };
};

export type FaultsByPvinsAffectedPvinsQueryVariables = Exact<{
  clusterId: Scalars["ID"];
  filter: FilterInput;
  params: FaultsByPvinsAffectedPvinsArgs;
}>;

export type FaultsByPvinsAffectedPvinsQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    FaultsByPvinsAffectedPvins: Array<{
      __typename?: "AffectedVehicleAttributes";
      id: string;
      vin?: string | null;
      model: string;
      modelStyle: string;
      modelYear: number;
    }>;
  };
};

export type VehicleMetadataQueryVariables = Exact<{
  filter: FilterInput;
  clusterId: Scalars["ID"];
  pagination?: InputMaybe<Pagination>;
}>;

export type VehicleMetadataQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    clusterMetadata: {
      __typename?: "ClusterMetadataPaginated";
      data: Array<{
        __typename?: "ClusterMetadata";
        pvin: string;
        vin?: string | null;
        modelYear: number;
        model?: string | null;
        modelStyle?: string | null;
        modelType: string;
        startDate?: string | null;
        endDate?: string | null;
        diagnosticCheckId?: string | null;
        maintenanceHistoryId?: string | null;
        componentLocationId?: string | null;
      }>;
      pagination: {
        __typename?: "PaginationResult";
        currentPage: number;
        pageSize: number;
        totalCount: number;
        totalPages: number;
      };
    };
  };
};

export type ExpressionEventOccurrencesPreviewQueryVariables = Exact<{
  expressionId: Scalars["ID"];
  operator: ExpressionEventOperator;
  threshold: Scalars["Float"];
  independentVar: TimeSeriesIndependentVar;
  filter: FilterInput;
}>;

export type ExpressionEventOccurrencesPreviewQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    expressionEvents?: {
      __typename?: "ExpressionEventQuery";
      occurrencesPreview: {
        __typename?: "ExpressionTimeSeriesData";
        x: Array<number>;
        y: Array<Array<number | null>>;
        count: Array<Array<number>>;
      };
    } | null;
  };
};

export type RelatedEventsQueryVariables = Exact<{
  filter: FilterInput;
  eventFilter: EventFilter;
  mileageWindowSize: Scalars["Int"];
  sort?: InputMaybe<Array<SortFilter> | SortFilter>;
  pagination?: InputMaybe<Pagination>;
  telematicsless: Scalars["Boolean"];
}>;

export type RelatedEventsQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    relatedEvents: {
      __typename?: "PaginatedRelatedEvents";
      pagination: {
        __typename?: "PaginationResult";
        currentPage: number;
        pageSize: number;
        totalCount: number;
        totalPages: number;
      };
      events: Array<{
        __typename?: "RelatedEvent";
        totalOccurrences: number;
        uniqueVehicles: number;
        event: {
          __typename?: "EventDescriptor";
          expressionEventId?: string | null;
          repairEventType?: RepairEventType | null;
          name: string;
          fault?: {
            __typename?: "FaultEventType";
            faultCode: string;
            ecu?: string | null;
            troubleCode?: string | null;
          } | null;
        };
      }>;
    };
  };
};

export type EventOccurrencesQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  eventFilter: EventFilter;
  sorters?: InputMaybe<Array<SortFilter> | SortFilter>;
  filter: FilterInput;
}>;

export type EventOccurrencesQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    eventOccurrences: {
      __typename?: "PaginatedEventOccurrences";
      occurrences: Array<{
        __typename?: "EventOccurrence";
        id: string;
        odometer?: number | null;
        date?: string | null;
        eventCount: number;
        isDateInferred?: boolean | null;
        vehicle?: {
          __typename?: "Vehicle";
          id: string;
          vin?: string | null;
          country?: string | null;
          model?: string | null;
          modelStyle?: string | null;
          odometer?: number | null;
          year?: number | null;
          lastReadingDate?: string | null;
        } | null;
      }>;
      pagination: {
        __typename?: "PaginationResult";
        currentPage: number;
        pageSize: number;
        totalCount: number;
        totalPages: number;
      };
    };
  };
};

export type EventOccurrencesRateQueryVariables = Exact<{
  eventFilter: EventFilter;
  min: Scalars["Int"];
  max: Scalars["Int"];
  intervalSize: Scalars["Int"];
  windowSize: Scalars["Int"];
  filter: FilterInput;
}>;

export type EventOccurrencesRateQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    eventOccurrencesRate: Array<{ __typename?: "EventOccurrenceRate"; end: number; rate?: number | null }>;
  };
};

export type EventTimeSeriesHistoryQueryVariables = Exact<{
  eventFilter: EventFilter;
  fieldIds: Array<Scalars["TimeSeriesFieldId"]> | Scalars["TimeSeriesFieldId"];
  selectors: Array<EventTimeSeriesSelector> | EventTimeSeriesSelector;
  minIndependentValue: Scalars["Int"];
  maxIndependentValue: Scalars["Int"];
  independentWindowSize: Scalars["Int"];
  independentInterval: Scalars["Int"];
  filter: FilterInput;
}>;

export type EventTimeSeriesHistoryQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    eventTimeSeriesHistory: {
      __typename?: "TimeSeriesData";
      count?: Array<Array<number | null>> | null;
      high?: Array<Array<number | null>> | null;
      low?: Array<Array<number | null>> | null;
      x: Array<number>;
      y: Array<Array<number | null>>;
    };
    vehicleCohortTimeSeriesSummary: Array<{
      __typename?: "VehicleCohortTimeSeriesSummaryData";
      bottomQuartile?: number | null;
      bottomDecile?: number | null;
      bottomVigintile?: number | null;
      min?: number | null;
      max?: number | null;
      mean?: number | null;
      median?: number | null;
      standardDeviation?: number | null;
      topQuartile?: number | null;
      topDecile?: number | null;
      topVigintile?: number | null;
    }>;
  };
};

export type FaultTimelineQueryVariables = Exact<{
  input: FaultTimelineInput;
  filter: FilterInput;
}>;

export type FaultTimelineQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    faultTimeline: Array<{
      __typename?: "FaultTimeline";
      faultCode: string;
      ecu: string;
      troubleCode?: string | null;
      series: Array<{ __typename?: "FaultTimelineSeries"; xAxis: number; faultCount: number; pvinCount: number }>;
    }>;
  };
};

export type FaultTimelinePvinQueryVariables = Exact<{
  input: FaultTimelineInputPvin;
  filter: FilterInput;
}>;

export type FaultTimelinePvinQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    faultTimelinePvins: Array<{
      __typename?: "AffectedVehicleAttributes";
      id: string;
      vin?: string | null;
      model: string;
      modelStyle: string;
      modelYear: number;
    }>;
  };
};

export type ForecastFleetInputDataQueryVariables = Exact<{
  eventFilters: Array<EventFilter> | EventFilter;
  odometerSampleSize: Scalars["Int"];
}>;

export type ForecastFleetInputDataQuery = {
  __typename?: "Query";
  vehicleDrivingDistanceHistograms: Array<{
    __typename?: "VehicleDrivingDistancePerMonth";
    distances: Array<number | null>;
  }>;
  vehicleMileageHistogram: Array<{
    __typename?: "VehicleHistogramByMileage";
    maxMileage?: number | null;
    minMileage?: number | null;
    vehicleCount: number;
  }>;
  vehicleAgeHistogram: Array<{ __typename?: "VehicleHistogramByAge"; months: number; vehicleCount: number }>;
  eventRateHistory: Array<{
    __typename?: "EventRate";
    x: Array<number>;
    countWithEvent: Array<number>;
    countTotal: Array<number>;
  }>;
};

export type HdmTableQueryVariables = Exact<{
  diagnosticCheckId: Scalars["String"];
  tableId: Scalars["String"];
  normalization?: InputMaybe<HdmNormalizations>;
}>;

export type HdmTableQuery = {
  __typename?: "Query";
  hdmTable: { __typename?: "HDMTable"; values?: Array<number> | null; odometer: number; timestamp: string };
};

export type HdmTableAggregateValueQueryVariables = Exact<{
  pvin: Scalars["String"];
  tableId: Scalars["String"];
  zoom?: InputMaybe<HdmZoom>;
}>;

export type HdmTableAggregateValueQuery = {
  __typename?: "Query";
  aggregateHDMTableValues: Array<{
    __typename?: "HDMTableAggregateValue";
    diagnosticCheckId: string;
    odometer: number;
    timestamp: string;
    value: number;
  }>;
};

export type HdmComparisonOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type HdmComparisonOptionsQuery = {
  __typename?: "Query";
  hdmComparisonOptions: {
    __typename?: "HDMComparisonOptions";
    model: Array<string | null>;
    modelYear: Array<number>;
    deliveryCountry: Array<string | null>;
    moduleCount: Array<number>;
  };
};

export type HdmComparisonTableQueryVariables = Exact<{
  customComparison?: InputMaybe<HdmCustomComparisonInput>;
  clusterComparison?: InputMaybe<HdmClusterComparisonInput>;
  tableId: Scalars["String"];
  pvin?: InputMaybe<Scalars["String"]>;
  clusterId?: InputMaybe<Scalars["String"]>;
  normalization?: InputMaybe<HdmNormalizations>;
}>;

export type HdmComparisonTableQuery = {
  __typename?: "Query";
  hdmComparisonTable?: { __typename?: "HDMComparisonTable"; values: Array<number> } | null;
};

export type DefaultHdmComparisonOptionsQueryVariables = Exact<{
  pvin?: InputMaybe<Scalars["String"]>;
  clusterId?: InputMaybe<Scalars["String"]>;
}>;

export type DefaultHdmComparisonOptionsQuery = {
  __typename?: "Query";
  defaultHDMComparisonOptions: {
    __typename?: "HDMComparisonOptions";
    model: Array<string | null>;
    modelYear: Array<number>;
    deliveryCountry: Array<string | null>;
    moduleCount: Array<number>;
  };
};

export type HomeCustomerQueryVariables = Exact<{ [key: string]: never }>;

export type HomeCustomerQuery = {
  __typename?: "Query";
  dataSourceStatus: Array<{
    __typename?: "DataSourceStatusInfo";
    dataSource: string;
    lastUpdatedDate: string;
    mostRecentDataDate: string;
  }>;
};

export type HomeRecentVehicleDetailsQueryVariables = Exact<{
  filter: FilterInput;
  ids: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type HomeRecentVehicleDetailsQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    filteredVehicleDetails: Array<{
      __typename?: "Vehicle";
      id: string;
      vin?: string | null;
      country?: string | null;
      odometer?: number | null;
      model?: string | null;
      modelStyle?: string | null;
      year?: number | null;
    }>;
  };
};

export type GetFilteredExpressionHistogramQueryVariables = Exact<{
  filter: FilterInput;
  buckets?: InputMaybe<Scalars["Int"]>;
  expression: Scalars["JSONObject"];
  outlierPercentile?: InputMaybe<Scalars["Float"]>;
}>;

export type GetFilteredExpressionHistogramQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    filteredSavedExpressionsHistogram: {
      __typename?: "ExpressionHistogram";
      max: Array<number | null>;
      min: Array<number | null>;
      pct: Array<number>;
      outliers?: {
        __typename?: "ExpressionHistogramOutliers";
        min?: Array<number | null> | null;
        max?: Array<number | null> | null;
        pct?: Array<number | null> | null;
      } | null;
    };
  };
};

export type GetFilteredExpressionTimeSeriesQueryVariables = Exact<{
  filter: FilterInput;
  expression: Scalars["JSONObject"];
  independentVar: TimeSeriesIndependentVar;
}>;

export type GetFilteredExpressionTimeSeriesQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    filteredSavedExpressionsTimeSeries: {
      __typename?: "ExpressionTimeSeriesData";
      high: Array<Array<number>>;
      low: Array<Array<number>>;
      x: Array<number>;
      y: Array<Array<number | null>>;
      vehicleCount: Array<Array<number>>;
    };
  };
};

export type RepairEventsQueryVariables = Exact<{
  filter: FilterInput;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortFilter> | SortFilter>;
  columnFilters?: InputMaybe<Array<ColumnFilter> | ColumnFilter>;
}>;

export type RepairEventsQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    RepairEvents: {
      __typename?: "RepairEventsPaginated";
      data: Array<{
        __typename?: "RepairEvent";
        pvin?: string | null;
        vin?: string | null;
        model?: string | null;
        modelYear?: number | null;
        repairStartDate?: string | null;
        repairEndDate?: string | null;
        componentLocationId?: string | null;
        id?: string | null;
        diagnosticCheckId?: string | null;
        clusterId?: string | null;
      }>;
      pagination?: {
        __typename?: "PaginationResult";
        currentPage: number;
        pageSize: number;
        totalCount: number;
      } | null;
    };
  };
};

export type RepairEventsClusterMatchesQueryVariables = Exact<{
  filter: FilterInput;
  RepairEventIds: Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>;
}>;

export type RepairEventsClusterMatchesQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    RepairEventsClustertMatches: Array<{
      __typename?: "RepairEventClusterMatch";
      repairEventId: string;
      percentOfTotal: number;
      recommendedCluster: string;
      recommendedClusterId: string;
      faultCode: string;
      componentLocationId: string;
      frequency: number;
    }>;
  };
};

export type RepairEventsColumnByUserInputQueryVariables = Exact<{
  filter: FilterInput;
  input: InputOrMappedValues;
  column: RepairEventsColumns;
  columnFilters?: InputMaybe<Array<ColumnFilter> | ColumnFilter>;
}>;

export type RepairEventsColumnByUserInputQuery = {
  __typename?: "Query";
  filteredQuery: { __typename?: "FilteredQuery"; RepairEventColumnByUserInput: Array<string | null> };
};

export type EnrichVehicleTimestampDataQueryVariables = Exact<{
  points: Array<VehiclePointInput> | VehiclePointInput;
}>;

export type EnrichVehicleTimestampDataQuery = {
  __typename?: "Query";
  enrichVehicleTimestampData: Array<{
    __typename?: "VehiclePoint";
    pvin: string;
    timestamp?: string | null;
    odometer?: number | null;
  } | null>;
};

export type VehicleFaultHistoryQueryVariables = Exact<{
  id: Scalars["ID"];
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortFilter> | SortFilter>;
  columnFilters?: InputMaybe<Array<ColumnFilter> | ColumnFilter>;
}>;

export type VehicleFaultHistoryQuery = {
  __typename?: "Query";
  vehicleFaultHistory: {
    __typename?: "FaultHistoryPaginated";
    data: Array<{
      __typename?: "Fault";
      vehicleId: string;
      code: string;
      troubleCode?: string | null;
      category?: string | null;
      odometer: number;
      id: string;
      source: string;
      startTime?: string | null;
      endTime?: string | null;
      isDateInferred?: boolean | null;
      softwareVersion?: string | null;
      hardwareVersion?: string | null;
      softwarePartNumber?: string | null;
      hardwarePartNumber?: string | null;
      diagnositcCheckId?: string | null;
      dealerNumber?: string | null;
      diagnosticDate?: string | null;
      fields?: Array<{ __typename?: "FaultField"; id: string; value?: any | null }> | null;
    }>;
    pagination?: { __typename?: "PaginationResult"; currentPage: number; pageSize: number; totalCount: number } | null;
  };
};

export type FaultHistoryColumnsQueryVariables = Exact<{
  input: Scalars["String"];
  column: FaultHistoryColumn;
  pvin?: InputMaybe<Scalars["String"]>;
  columnFilters?: InputMaybe<Array<ColumnFilter> | ColumnFilter>;
}>;

export type FaultHistoryColumnsQuery = { __typename?: "Query"; faultHistoryColumnByUserInput: Array<string> };

export type FaultHistoryColumnsFilterQueryVariables = Exact<{
  pvin: Scalars["String"];
}>;

export type FaultHistoryColumnsFilterQuery = {
  __typename?: "Query";
  faultHistoryByUserInput: Array<{
    __typename?: "FaultHistoryFilterData";
    ecu?: string | null;
    faultCode?: string | null;
    troubleCode?: string | null;
  }>;
};

export type VehicleDetailsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type VehicleDetailsQuery = {
  __typename?: "Query";
  vehicleDetails: Array<{
    __typename?: "Vehicle";
    id: string;
    vin?: string | null;
    model?: string | null;
    modelStyle?: string | null;
    country?: string | null;
    year?: number | null;
    odometer?: number | null;
    lastReadingDate?: string | null;
    lastDiagnosticCheckDate?: string | null;
    productionDate?: string | null;
    deliveryDate?: string | null;
    importer?: string | null;
  }>;
};

export type VehicleTimeSeriesQueryVariables = Exact<{
  id: Scalars["ID"];
  fields: Array<TimeSeriesFieldInput> | TimeSeriesFieldInput;
  independentVar?: InputMaybe<TimeSeriesIndependentVar>;
  independentVarInterval?: InputMaybe<IntervalInput>;
  independentVarRange?: InputMaybe<IndependentVarRangeInput>;
  withComparison: Scalars["Boolean"];
  comparisonFields: Array<Scalars["TimeSeriesFieldId"]> | Scalars["TimeSeriesFieldId"];
  filter: FilterInput;
  allTimeSeriesFieldIds: Array<Scalars["TimeSeriesFieldId"]> | Scalars["TimeSeriesFieldId"];
  timestampEpoch?: InputMaybe<VehicleCohortEpoch>;
}>;

export type VehicleTimeSeriesQuery = {
  __typename?: "Query";
  vehicleTimeSeries: {
    __typename?: "TimeSeriesData";
    x: Array<number>;
    y: Array<Array<number | null>>;
    independentVarInterval?: { __typename?: "Interval"; distance: number; timeUnit?: TimeIntervalUnit | null } | null;
  };
  filteredQuery: {
    __typename?: "FilteredQuery";
    vehicleCohortTimeSeries?: {
      __typename?: "VehicleCohortTimeSeriesData";
      x: Array<number>;
      min: Array<Array<number | null>>;
      max: Array<Array<number | null>>;
      mean: Array<Array<number | null>>;
      median: Array<Array<number | null>>;
      bottomQuartile: Array<Array<number | null>>;
      topQuartile: Array<Array<number | null>>;
      bottom5Perc: Array<Array<number | null>>;
      top5Perc: Array<Array<number | null>>;
      stddev: Array<Array<number | null>>;
      independentVarInterval?: { __typename?: "Interval"; distance: number; timeUnit?: TimeIntervalUnit | null } | null;
      cohortFilter?: {
        __typename?: "VehicleFilterValues";
        model: Array<string>;
        modelYear: Array<number>;
        deliveryCountry: Array<string>;
        modelStyle: Array<string>;
      } | null;
    };
  };
  anomalyDetectorsForTimeSeriesFieldIds: Array<{
    __typename?: "AnomalyDetector";
    id: string;
    name: string;
    cautionThreshold?: number | null;
    warningThreshold: number;
    type?: AnomalyDetectorType | null;
    isCustomerMLModel?: boolean | null;
  } | null>;
};

export type HealthCheckQueryVariables = Exact<{
  testNamePatterns?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  filePatterns?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  testVariables?: InputMaybe<TestVariablesInput>;
}>;

export type HealthCheckQuery = {
  __typename?: "Query";
  healthCheck: {
    __typename?: "HealthCheckResult";
    summary: {
      __typename?: "Summary";
      numFailedTestSuites: number;
      numFailedTests: number;
      numPassedTestSuites: number;
      numPassedTests: number;
      numPendingTestSuites: number;
      numPendingTests: number;
      numTodoTests: number;
      numTotalTestSuites: number;
      numTotalTests: number;
      startTime: string;
      success: boolean;
    };
    queryInfo: { __typename?: "QueryInfo"; graphQLResolver?: string | null };
    results?: Array<{
      __typename?: "TestResult";
      fileName: string;
      message?: string | null;
      assertions?: Array<{
        __typename?: "AssertionResult";
        ancestorTitles?: Array<string> | null;
        duration?: number | null;
        failureMessages?: Array<string> | null;
        fullName: string;
        status: string;
        title: string;
        location?: { __typename?: "Location"; column: number; line: number } | null;
      }> | null;
      tests?: Array<{
        __typename?: "Test";
        name: string;
        status?: string | null;
        testDuration?: number | null;
        errorLogs?: Array<{
          __typename?: "ErrorLog";
          content: string;
          size: number;
          taskId: string;
          time: string;
          type: string;
        } | null> | null;
      }> | null;
    }> | null;
  };
};

export type MissingPvinsQueryVariables = Exact<{
  pvins: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type MissingPvinsQuery = { __typename?: "Query"; missingPvins: Array<string> };

export type CreateClusterInRedshiftMutationVariables = Exact<{
  clusterId: Scalars["SignedId"];
  input: Array<ClusterRedshiftCreateInput> | ClusterRedshiftCreateInput;
}>;

export type CreateClusterInRedshiftMutation = { __typename?: "Mutation"; createClusterPvins?: string | null };

export type PvinsByClusterQueryVariables = Exact<{
  filter: FilterInput;
  clusterIds: Array<Scalars["SignedId"]> | Scalars["SignedId"];
}>;

export type PvinsByClusterQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    pvinsByCluster: {
      __typename?: "AllClusterPvins";
      clusters: Array<{
        __typename?: "ClusterPvins";
        clusterId: string;
        vehicleCount: number;
        combinationCount: number;
        recordWithDiagnosticCheckCount: number;
        recordWithMaintenanceHistoryCount: number;
        pvins: Array<{ __typename?: "ClusterEvent"; pvin: string; startDate?: string | null; endDate?: string | null }>;
        missingPvins: Array<{
          __typename?: "ClusterEvent";
          pvin: string;
          startDate?: string | null;
          endDate?: string | null;
        }>;
      }>;
    };
  };
};

export type PvinCountsByClusterQueryVariables = Exact<{
  filter: FilterInput;
  clusterIds: Array<Scalars["SignedId"]> | Scalars["SignedId"];
}>;

export type PvinCountsByClusterQuery = {
  __typename?: "Query";
  filteredQuery: {
    __typename?: "FilteredQuery";
    pvinsByCluster: {
      __typename?: "AllClusterPvins";
      clusters: Array<{
        __typename?: "ClusterPvins";
        clusterId: string;
        vehicleCount: number;
        combinationCount: number;
        recordWithDiagnosticCheckCount: number;
        recordWithMaintenanceHistoryCount: number;
      }>;
    };
  };
};

export type DeleteClusterMutationVariables = Exact<{
  clusterId: Scalars["SignedId"];
}>;

export type DeleteClusterMutation = { __typename?: "Mutation"; deleteAllPvinsFromCluster?: string | null };

export type AddPvinsToClusterMutationVariables = Exact<{
  clusterId: Scalars["SignedId"];
  input: Array<ClusterRedshiftCreateInput> | ClusterRedshiftCreateInput;
}>;

export type AddPvinsToClusterMutation = {
  __typename?: "Mutation";
  addPvinsToCluster: {
    __typename?: "EditClusterMissingPvins";
    missingPvins: Array<string>;
    existingCombinations: Array<{
      __typename?: "ClusterRedshift";
      pvin: string;
      startDate?: string | null;
      endDate?: string | null;
    }>;
  };
};

export type RemovePvinsFromClusterMutationVariables = Exact<{
  clusterId: Scalars["SignedId"];
  input: Array<ClusterRedshiftCreateInput> | ClusterRedshiftCreateInput;
}>;

export type RemovePvinsFromClusterMutation = { __typename?: "Mutation"; removePvinsFromCluster?: string | null };

export type CopyClusterMutationVariables = Exact<{
  clusterId: Scalars["SignedId"];
  copyClusterId: Scalars["SignedId"];
}>;

export type CopyClusterMutation = { __typename?: "Mutation"; copyCluster?: string | null };

export type SearchVehiclesQueryVariables = Exact<{
  input: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type SearchVehiclesQuery = {
  __typename?: "Query";
  searchVehicles: Array<{
    __typename?: "Vehicle";
    id: string;
    vin?: string | null;
    country?: string | null;
    deliveryDate?: string | null;
    importer?: string | null;
    lastDiagnosticCheckDate?: string | null;
    lastReadingDate?: string | null;
    model?: string | null;
    modelStyle?: string | null;
    odometer?: number | null;
    productionDate?: string | null;
    year?: number | null;
  } | null>;
};

export type MlAnomalyDetectorsQueryVariables = Exact<{ [key: string]: never }>;

export type MlAnomalyDetectorsQuery = {
  __typename?: "Query";
  mlAnomalyDetectors: Array<{
    __typename?: "AnomalyDetector";
    id: string;
    timeSeriesFieldId: string;
    name: string;
    inputIds: Array<string>;
  }>;
};

export const DistanceVersusFailureAndRepairCountDocument = `
    query DistanceVersusFailureAndRepairCount($clusterId: ID!, $filter: FilterInput!, $odometerBounds: OdometerBounds) {
  filteredQuery(filter: $filter) {
    distanceVersusFailureAndRepairCount(
      clusterId: $clusterId
      odometerBounds: $odometerBounds
    ) {
      distanceStart
      distanceEnd
      faultCount
      faultPvinCount
      maintenanceCount
      maintenancePvinCount
      bucketNumber
    }
  }
}
    `;
export const useDistanceVersusFailureAndRepairCountQuery = <
  TData = DistanceVersusFailureAndRepairCountQuery,
  TError = unknown
>(
  variables: DistanceVersusFailureAndRepairCountQueryVariables,
  options?: UseQueryOptions<DistanceVersusFailureAndRepairCountQuery, TError, TData>
) =>
  useQuery<DistanceVersusFailureAndRepairCountQuery, TError, TData>(
    ["DistanceVersusFailureAndRepairCount", variables],
    useCustomerApiFetcher<DistanceVersusFailureAndRepairCountQuery, DistanceVersusFailureAndRepairCountQueryVariables>(
      DistanceVersusFailureAndRepairCountDocument
    ).bind(null, variables),
    options
  );

useDistanceVersusFailureAndRepairCountQuery.getKey = (variables: DistanceVersusFailureAndRepairCountQueryVariables) => [
  "DistanceVersusFailureAndRepairCount",
  variables,
];
export const DistanceVersusFailureAndRepairCountPvinsDocument = `
    query DistanceVersusFailureAndRepairCountPvins($clusterId: ID!, $filter: FilterInput!, $odometerBounds: OdometerBounds) {
  filteredQuery(filter: $filter) {
    distanceVersusFailureAndRepairCountPvins(
      clusterId: $clusterId
      odometerBounds: $odometerBounds
    ) {
      id
      vin
      model
      modelStyle
      modelYear
    }
  }
}
    `;
export const useDistanceVersusFailureAndRepairCountPvinsQuery = <
  TData = DistanceVersusFailureAndRepairCountPvinsQuery,
  TError = unknown
>(
  variables: DistanceVersusFailureAndRepairCountPvinsQueryVariables,
  options?: UseQueryOptions<DistanceVersusFailureAndRepairCountPvinsQuery, TError, TData>
) =>
  useQuery<DistanceVersusFailureAndRepairCountPvinsQuery, TError, TData>(
    ["DistanceVersusFailureAndRepairCountPvins", variables],
    useCustomerApiFetcher<
      DistanceVersusFailureAndRepairCountPvinsQuery,
      DistanceVersusFailureAndRepairCountPvinsQueryVariables
    >(DistanceVersusFailureAndRepairCountPvinsDocument).bind(null, variables),
    options
  );

useDistanceVersusFailureAndRepairCountPvinsQuery.getKey = (
  variables: DistanceVersusFailureAndRepairCountPvinsQueryVariables
) => ["DistanceVersusFailureAndRepairCountPvins", variables];
export const AttributeCountDocument = `
    query AttributeCount($clusterId: ID!, $filter: FilterInput!, $attribute: AttributeTypeEnum!) {
  filteredQuery(filter: $filter) {
    attributeCount(clusterId: $clusterId, attribute: $attribute) {
      attribute
      pvinCount
    }
  }
}
    `;
export const useAttributeCountQuery = <TData = AttributeCountQuery, TError = unknown>(
  variables: AttributeCountQueryVariables,
  options?: UseQueryOptions<AttributeCountQuery, TError, TData>
) =>
  useQuery<AttributeCountQuery, TError, TData>(
    ["AttributeCount", variables],
    useCustomerApiFetcher<AttributeCountQuery, AttributeCountQueryVariables>(AttributeCountDocument).bind(
      null,
      variables
    ),
    options
  );

useAttributeCountQuery.getKey = (variables: AttributeCountQueryVariables) => ["AttributeCount", variables];
export const AttributeCountPvinsDocument = `
    query AttributeCountPvins($clusterId: ID!, $filter: FilterInput!, $attribute: AttributeTypeEnum!, $attributeValue: String!) {
  filteredQuery(filter: $filter) {
    attributeCountPvins(
      clusterId: $clusterId
      attribute: $attribute
      attributeValue: $attributeValue
    ) {
      id
      vin
      model
      modelStyle
      modelYear
    }
  }
}
    `;
export const useAttributeCountPvinsQuery = <TData = AttributeCountPvinsQuery, TError = unknown>(
  variables: AttributeCountPvinsQueryVariables,
  options?: UseQueryOptions<AttributeCountPvinsQuery, TError, TData>
) =>
  useQuery<AttributeCountPvinsQuery, TError, TData>(
    ["AttributeCountPvins", variables],
    useCustomerApiFetcher<AttributeCountPvinsQuery, AttributeCountPvinsQueryVariables>(
      AttributeCountPvinsDocument
    ).bind(null, variables),
    options
  );

useAttributeCountPvinsQuery.getKey = (variables: AttributeCountPvinsQueryVariables) => [
  "AttributeCountPvins",
  variables,
];
export const VehicleChargingSessionDurationStatsDocument = `
    query vehicleChargingSessionDurationStats($filter: FilterInput!, $vehicleId: ID!) {
  filteredQuery(filter: $filter) {
    vehicleChargingSessionDurationStats(id: $vehicleId) {
      vehicle {
        p5
        p25
        median
        p75
        p95
      }
      fleet {
        p5
        p25
        median
        p75
        p95
      }
    }
  }
}
    `;
export const useVehicleChargingSessionDurationStatsQuery = <
  TData = VehicleChargingSessionDurationStatsQuery,
  TError = unknown
>(
  variables: VehicleChargingSessionDurationStatsQueryVariables,
  options?: UseQueryOptions<VehicleChargingSessionDurationStatsQuery, TError, TData>
) =>
  useQuery<VehicleChargingSessionDurationStatsQuery, TError, TData>(
    ["vehicleChargingSessionDurationStats", variables],
    useCustomerApiFetcher<VehicleChargingSessionDurationStatsQuery, VehicleChargingSessionDurationStatsQueryVariables>(
      VehicleChargingSessionDurationStatsDocument
    ).bind(null, variables),
    options
  );

useVehicleChargingSessionDurationStatsQuery.getKey = (variables: VehicleChargingSessionDurationStatsQueryVariables) => [
  "vehicleChargingSessionDurationStats",
  variables,
];
export const VehicleHistoricalDataAnalysisDocument = `
    query vehicleHistoricalDataAnalysis($vehicleId: ID!, $independentVar: TimeSeriesIndependentVar, $independentVarInterval: IntervalInput, $independentVarRange: IndependentVarRangeInput, $withXAxis: Boolean!) {
  vehicleTimeSeriesXAxis(
    id: $vehicleId
    independentVar: $independentVar
    independentVarInterval: $independentVarInterval
    independentVarRange: $independentVarRange
  ) @include(if: $withXAxis) {
    independentVarInterval {
      distance
      timeUnit
    }
    x
  }
  vehicleFaultHistory(id: $vehicleId) {
    data {
      vehicleId
      code
      troubleCode
      category
      odometer
      id
      source
      startTime
      endTime
      isDateInferred
      fields {
        id
        value
      }
    }
  }
  vehicleRepairEventHistory(id: $vehicleId) {
    id
    pvin
    odometer
    type
    endDate
  }
}
    `;
export const useVehicleHistoricalDataAnalysisQuery = <TData = VehicleHistoricalDataAnalysisQuery, TError = unknown>(
  variables: VehicleHistoricalDataAnalysisQueryVariables,
  options?: UseQueryOptions<VehicleHistoricalDataAnalysisQuery, TError, TData>
) =>
  useQuery<VehicleHistoricalDataAnalysisQuery, TError, TData>(
    ["vehicleHistoricalDataAnalysis", variables],
    useCustomerApiFetcher<VehicleHistoricalDataAnalysisQuery, VehicleHistoricalDataAnalysisQueryVariables>(
      VehicleHistoricalDataAnalysisDocument
    ).bind(null, variables),
    options
  );

useVehicleHistoricalDataAnalysisQuery.getKey = (variables: VehicleHistoricalDataAnalysisQueryVariables) => [
  "vehicleHistoricalDataAnalysis",
  variables,
];
export const VehicleSpeedStatsDocument = `
    query vehicleSpeedStats($filter: FilterInput!, $vehicleId: ID!) {
  filteredQuery(filter: $filter) {
    vehicleSpeedStats(id: $vehicleId) {
      vehicle {
        p5
        p25
        median
        p75
        p95
      }
      fleet {
        p5
        p25
        median
        p75
        p95
      }
    }
  }
}
    `;
export const useVehicleSpeedStatsQuery = <TData = VehicleSpeedStatsQuery, TError = unknown>(
  variables: VehicleSpeedStatsQueryVariables,
  options?: UseQueryOptions<VehicleSpeedStatsQuery, TError, TData>
) =>
  useQuery<VehicleSpeedStatsQuery, TError, TData>(
    ["vehicleSpeedStats", variables],
    useCustomerApiFetcher<VehicleSpeedStatsQuery, VehicleSpeedStatsQueryVariables>(VehicleSpeedStatsDocument).bind(
      null,
      variables
    ),
    options
  );

useVehicleSpeedStatsQuery.getKey = (variables: VehicleSpeedStatsQueryVariables) => ["vehicleSpeedStats", variables];
export const VehicleTemperatureStatsDocument = `
    query vehicleTemperatureStats($filter: FilterInput!, $vehicleId: ID!) {
  filteredQuery(filter: $filter) {
    vehicleTemperatureStats(id: $vehicleId) {
      vehicle {
        p5
        p25
        median
        p75
        p95
      }
      fleet {
        p5
        p25
        median
        p75
        p95
      }
    }
  }
}
    `;
export const useVehicleTemperatureStatsQuery = <TData = VehicleTemperatureStatsQuery, TError = unknown>(
  variables: VehicleTemperatureStatsQueryVariables,
  options?: UseQueryOptions<VehicleTemperatureStatsQuery, TError, TData>
) =>
  useQuery<VehicleTemperatureStatsQuery, TError, TData>(
    ["vehicleTemperatureStats", variables],
    useCustomerApiFetcher<VehicleTemperatureStatsQuery, VehicleTemperatureStatsQueryVariables>(
      VehicleTemperatureStatsDocument
    ).bind(null, variables),
    options
  );

useVehicleTemperatureStatsQuery.getKey = (variables: VehicleTemperatureStatsQueryVariables) => [
  "vehicleTemperatureStats",
  variables,
];
export const GetEventStatsDocument = `
    query getEventStats($eventFilter: EventFilter!, $timeWindow: Int, $filter: FilterInput!) {
  filteredQuery(filter: $filter) {
    eventStats(eventFilter: $eventFilter, timeWindow: $timeWindow) {
      totalOccurrences
      uniqueVehicles
      fullRepairCorrelation
      partialRepairCorrelation
    }
  }
}
    `;
export const useGetEventStatsQuery = <TData = GetEventStatsQuery, TError = unknown>(
  variables: GetEventStatsQueryVariables,
  options?: UseQueryOptions<GetEventStatsQuery, TError, TData>
) =>
  useQuery<GetEventStatsQuery, TError, TData>(
    ["getEventStats", variables],
    useCustomerApiFetcher<GetEventStatsQuery, GetEventStatsQueryVariables>(GetEventStatsDocument).bind(null, variables),
    options
  );

useGetEventStatsQuery.getKey = (variables: GetEventStatsQueryVariables) => ["getEventStats", variables];
export const VehicleCountForFleetIntelDocument = `
    query vehicleCountForFleetIntel($filter: FilterInput!, $startDate: DateTime) {
  filteredQuery(filter: $filter) {
    vehicleCountByCountry(startDate: $startDate) {
      totalVehicles
      vehiclesByCountry {
        country
        vehicles
        vehiclesWithMaintenanceEvents
      }
    }
  }
}
    `;
export const useVehicleCountForFleetIntelQuery = <TData = VehicleCountForFleetIntelQuery, TError = unknown>(
  variables: VehicleCountForFleetIntelQueryVariables,
  options?: UseQueryOptions<VehicleCountForFleetIntelQuery, TError, TData>
) =>
  useQuery<VehicleCountForFleetIntelQuery, TError, TData>(
    ["vehicleCountForFleetIntel", variables],
    useCustomerApiFetcher<VehicleCountForFleetIntelQuery, VehicleCountForFleetIntelQueryVariables>(
      VehicleCountForFleetIntelDocument
    ).bind(null, variables),
    options
  );

useVehicleCountForFleetIntelQuery.getKey = (variables: VehicleCountForFleetIntelQueryVariables) => [
  "vehicleCountForFleetIntel",
  variables,
];
export const AllFilterValuesDocument = `
    query allFilterValues {
  allFilterValues {
    vehicle {
      model
      modelStyle
      modelYear
      deliveryCountry
    }
  }
}
    `;
export const useAllFilterValuesQuery = <TData = AllFilterValuesQuery, TError = unknown>(
  variables?: AllFilterValuesQueryVariables,
  options?: UseQueryOptions<AllFilterValuesQuery, TError, TData>
) =>
  useQuery<AllFilterValuesQuery, TError, TData>(
    variables === undefined ? ["allFilterValues"] : ["allFilterValues", variables],
    useCustomerApiFetcher<AllFilterValuesQuery, AllFilterValuesQueryVariables>(AllFilterValuesDocument).bind(
      null,
      variables
    ),
    options
  );

useAllFilterValuesQuery.getKey = (variables?: AllFilterValuesQueryVariables) =>
  variables === undefined ? ["allFilterValues"] : ["allFilterValues", variables];
export const FaultHistoryColumnByUserInputDocument = `
    query faultHistoryColumnByUserInput($input: String!, $column: FaultHistoryColumn!, $pvin: String, $columnFilters: [ColumnFilter!]) {
  faultHistoryColumnByUserInput(
    input: $input
    column: $column
    pvin: $pvin
    columnFilters: $columnFilters
  )
}
    `;
export const useFaultHistoryColumnByUserInputQuery = <TData = FaultHistoryColumnByUserInputQuery, TError = unknown>(
  variables: FaultHistoryColumnByUserInputQueryVariables,
  options?: UseQueryOptions<FaultHistoryColumnByUserInputQuery, TError, TData>
) =>
  useQuery<FaultHistoryColumnByUserInputQuery, TError, TData>(
    ["faultHistoryColumnByUserInput", variables],
    useCustomerApiFetcher<FaultHistoryColumnByUserInputQuery, FaultHistoryColumnByUserInputQueryVariables>(
      FaultHistoryColumnByUserInputDocument
    ).bind(null, variables),
    options
  );

useFaultHistoryColumnByUserInputQuery.getKey = (variables: FaultHistoryColumnByUserInputQueryVariables) => [
  "faultHistoryColumnByUserInput",
  variables,
];
export const AnomalyAffectedVehiclesDocument = `
    query anomalyAffectedVehicles($anomalyId: ID!, $anomalyStatus: CalculatedStatus, $pagination: Pagination, $sorting: SortFilter, $filter: FilterInput!) {
  filteredQuery(filter: $filter) {
    anomalyAffectedVehicles(
      anomalyId: $anomalyId
      anomalyStatus: $anomalyStatus
      pagination: $pagination
      sorting: $sorting
    ) {
      data {
        pvin
        vin
        model
        modelStyle
        modelYear
        score
        lastDetected
      }
      pagination {
        currentPage
        pageSize
        totalCount
      }
    }
  }
}
    `;
export const useAnomalyAffectedVehiclesQuery = <TData = AnomalyAffectedVehiclesQuery, TError = unknown>(
  variables: AnomalyAffectedVehiclesQueryVariables,
  options?: UseQueryOptions<AnomalyAffectedVehiclesQuery, TError, TData>
) =>
  useQuery<AnomalyAffectedVehiclesQuery, TError, TData>(
    ["anomalyAffectedVehicles", variables],
    useCustomerApiFetcher<AnomalyAffectedVehiclesQuery, AnomalyAffectedVehiclesQueryVariables>(
      AnomalyAffectedVehiclesDocument
    ).bind(null, variables),
    options
  );

useAnomalyAffectedVehiclesQuery.getKey = (variables: AnomalyAffectedVehiclesQueryVariables) => [
  "anomalyAffectedVehicles",
  variables,
];
export const AnomalySampleVehiclesDocument = `
    query anomalySampleVehicles($anomalyId: ID!, $anomalyStatus: CalculatedStatus, $sampleSize: Int!, $filter: FilterInput!) {
  filteredQuery(filter: $filter) {
    anomalySampleVehicles(
      anomalyId: $anomalyId
      anomalyStatus: $anomalyStatus
      sampleSize: $sampleSize
    ) {
      data {
        pvin
        vin
        score
        lastDetected
        odometer
        anomalyState
      }
    }
  }
}
    `;
export const useAnomalySampleVehiclesQuery = <TData = AnomalySampleVehiclesQuery, TError = unknown>(
  variables: AnomalySampleVehiclesQueryVariables,
  options?: UseQueryOptions<AnomalySampleVehiclesQuery, TError, TData>
) =>
  useQuery<AnomalySampleVehiclesQuery, TError, TData>(
    ["anomalySampleVehicles", variables],
    useCustomerApiFetcher<AnomalySampleVehiclesQuery, AnomalySampleVehiclesQueryVariables>(
      AnomalySampleVehiclesDocument
    ).bind(null, variables),
    options
  );

useAnomalySampleVehiclesQuery.getKey = (variables: AnomalySampleVehiclesQueryVariables) => [
  "anomalySampleVehicles",
  variables,
];
export const AnomalyVehicleCountForFleetIntelDocument = `
    query anomalyVehicleCountForFleetIntel($anomalyId: ID!, $anomalyStatus: CalculatedStatus, $filter: FilterInput!) {
  filteredQuery(filter: $filter) {
    anomalyVehicleCountByCountry(
      anomalyId: $anomalyId
      anomalyStatus: $anomalyStatus
    ) {
      vehiclesByCountry {
        country
        vehicles
        vehiclesCautionCount
        vehiclesWarningCount
      }
    }
  }
}
    `;
export const useAnomalyVehicleCountForFleetIntelQuery = <
  TData = AnomalyVehicleCountForFleetIntelQuery,
  TError = unknown
>(
  variables: AnomalyVehicleCountForFleetIntelQueryVariables,
  options?: UseQueryOptions<AnomalyVehicleCountForFleetIntelQuery, TError, TData>
) =>
  useQuery<AnomalyVehicleCountForFleetIntelQuery, TError, TData>(
    ["anomalyVehicleCountForFleetIntel", variables],
    useCustomerApiFetcher<AnomalyVehicleCountForFleetIntelQuery, AnomalyVehicleCountForFleetIntelQueryVariables>(
      AnomalyVehicleCountForFleetIntelDocument
    ).bind(null, variables),
    options
  );

useAnomalyVehicleCountForFleetIntelQuery.getKey = (variables: AnomalyVehicleCountForFleetIntelQueryVariables) => [
  "anomalyVehicleCountForFleetIntel",
  variables,
];
export const AnomalySummaryDocument = `
    query AnomalySummary($filter: FilterInput!, $pagination: Pagination!, $sorting: SortFilter, $id: String) {
  filteredQuery(filter: $filter) {
    anomaliesWithVehicleCount(pagination: $pagination, sorting: $sorting, id: $id) {
      items {
        detector {
          id
          name
          description
          version
          created
          lastModified
          creator {
            isMe
            name
          }
          type
          status
          type
          expression {
            id
            name
          }
          cautionThreshold
          warningThreshold
          inputIds
          nameKey
          timeSeriesFieldId
          isCustomerMLModel
          externalVersion
        }
        data {
          anomalyVehicleCount
        }
      }
      pagination {
        currentPage
        pageSize
        totalCount
      }
    }
  }
}
    `;
export const useAnomalySummaryQuery = <TData = AnomalySummaryQuery, TError = unknown>(
  variables: AnomalySummaryQueryVariables,
  options?: UseQueryOptions<AnomalySummaryQuery, TError, TData>
) =>
  useQuery<AnomalySummaryQuery, TError, TData>(
    ["AnomalySummary", variables],
    useCustomerApiFetcher<AnomalySummaryQuery, AnomalySummaryQueryVariables>(AnomalySummaryDocument).bind(
      null,
      variables
    ),
    options
  );

useAnomalySummaryQuery.getKey = (variables: AnomalySummaryQueryVariables) => ["AnomalySummary", variables];
export const SingleVehicleAnomalyDetectorDocument = `
    query singleVehicleAnomalyDetector($id: ID!, $pvin: ID!, $type: AnomalyDetectorType!) {
  singleVehicleAnomalyDetector(id: $id, pvin: $pvin, type: $type) {
    detector {
      cautionThreshold
      creator {
        isMe
        name
      }
      created
      lastModified
      description
      id
      isCustomerMLModel
      type
      name
      nameKey
      version
      warningThreshold
      timeSeriesFieldId
      inputIds
      expression {
        id
        name
      }
    }
    vehicleData {
      alertsCount
      anomalousDaysCount
      firstDetected
      fieldsWeights {
        id
        values
      }
      history {
        count
        high
        independentVarInterval {
          distance
          timeUnit
        }
        low
        x
        y
      }
      score
    }
  }
}
    `;
export const useSingleVehicleAnomalyDetectorQuery = <TData = SingleVehicleAnomalyDetectorQuery, TError = unknown>(
  variables: SingleVehicleAnomalyDetectorQueryVariables,
  options?: UseQueryOptions<SingleVehicleAnomalyDetectorQuery, TError, TData>
) =>
  useQuery<SingleVehicleAnomalyDetectorQuery, TError, TData>(
    ["singleVehicleAnomalyDetector", variables],
    useCustomerApiFetcher<SingleVehicleAnomalyDetectorQuery, SingleVehicleAnomalyDetectorQueryVariables>(
      SingleVehicleAnomalyDetectorDocument
    ).bind(null, variables),
    options
  );

useSingleVehicleAnomalyDetectorQuery.getKey = (variables: SingleVehicleAnomalyDetectorQueryVariables) => [
  "singleVehicleAnomalyDetector",
  variables,
];
export const SingleVehicleAnomalyDetectorsDocument = `
    query singleVehicleAnomalyDetectors($pagination: Pagination!, $pvin: ID!, $severity: AnomalySeverity, $status: CalculatedStatus) {
  singleVehicleAnomalyDetectors(
    pagination: $pagination
    pvin: $pvin
    severity: $severity
    status: $status
  ) {
    detectors {
      description
      id
      name
      score
      severity
      type
      source
    }
    pagination {
      currentPage
      pageSize
      totalCount
      totalPages
    }
    severityCounts {
      all
      caution
      normal
      warning
    }
  }
}
    `;
export const useSingleVehicleAnomalyDetectorsQuery = <TData = SingleVehicleAnomalyDetectorsQuery, TError = unknown>(
  variables: SingleVehicleAnomalyDetectorsQueryVariables,
  options?: UseQueryOptions<SingleVehicleAnomalyDetectorsQuery, TError, TData>
) =>
  useQuery<SingleVehicleAnomalyDetectorsQuery, TError, TData>(
    ["singleVehicleAnomalyDetectors", variables],
    useCustomerApiFetcher<SingleVehicleAnomalyDetectorsQuery, SingleVehicleAnomalyDetectorsQueryVariables>(
      SingleVehicleAnomalyDetectorsDocument
    ).bind(null, variables),
    options
  );

useSingleVehicleAnomalyDetectorsQuery.getKey = (variables: SingleVehicleAnomalyDetectorsQueryVariables) => [
  "singleVehicleAnomalyDetectors",
  variables,
];
export const FaultsByPvinsDocument = `
    query FaultsByPvins($clusterId: ID!, $filter: FilterInput!, $params: TopFaultParamsInput, $columnFilters: [ColumnFilter!], $pagination: Pagination, $sorting: FaultsByPvinsAffectedPvinsSortInput) {
  filteredQuery(filter: $filter) {
    faultsByPvins(
      clusterId: $clusterId
      params: $params
      columnFilters: $columnFilters
      pagination: $pagination
      sorting: $sorting
    ) {
      result {
        faultCode
        faultCodeAppearances
        vehiclesAffected
        ecu
        troubleCode
        softwareVersion
        hardwareVersion
        softwarePartNumber
        hardwarePartNumber
      }
      totalCount
    }
  }
}
    `;
export const useFaultsByPvinsQuery = <TData = FaultsByPvinsQuery, TError = unknown>(
  variables: FaultsByPvinsQueryVariables,
  options?: UseQueryOptions<FaultsByPvinsQuery, TError, TData>
) =>
  useQuery<FaultsByPvinsQuery, TError, TData>(
    ["FaultsByPvins", variables],
    useCustomerApiFetcher<FaultsByPvinsQuery, FaultsByPvinsQueryVariables>(FaultsByPvinsDocument).bind(null, variables),
    options
  );

useFaultsByPvinsQuery.getKey = (variables: FaultsByPvinsQueryVariables) => ["FaultsByPvins", variables];
export const FaultsByPvinsAffectedPvinsDocument = `
    query FaultsByPvinsAffectedPvins($clusterId: ID!, $filter: FilterInput!, $params: FaultsByPvinsAffectedPvinsArgs!) {
  filteredQuery(filter: $filter) {
    FaultsByPvinsAffectedPvins(clusterId: $clusterId, params: $params) {
      id
      vin
      model
      modelStyle
      modelYear
    }
  }
}
    `;
export const useFaultsByPvinsAffectedPvinsQuery = <TData = FaultsByPvinsAffectedPvinsQuery, TError = unknown>(
  variables: FaultsByPvinsAffectedPvinsQueryVariables,
  options?: UseQueryOptions<FaultsByPvinsAffectedPvinsQuery, TError, TData>
) =>
  useQuery<FaultsByPvinsAffectedPvinsQuery, TError, TData>(
    ["FaultsByPvinsAffectedPvins", variables],
    useCustomerApiFetcher<FaultsByPvinsAffectedPvinsQuery, FaultsByPvinsAffectedPvinsQueryVariables>(
      FaultsByPvinsAffectedPvinsDocument
    ).bind(null, variables),
    options
  );

useFaultsByPvinsAffectedPvinsQuery.getKey = (variables: FaultsByPvinsAffectedPvinsQueryVariables) => [
  "FaultsByPvinsAffectedPvins",
  variables,
];
export const VehicleMetadataDocument = `
    query VehicleMetadata($filter: FilterInput!, $clusterId: ID!, $pagination: Pagination) {
  filteredQuery(filter: $filter) {
    clusterMetadata(clusterId: $clusterId, pagination: $pagination) {
      data {
        pvin
        vin
        modelYear
        model
        modelStyle
        modelType
        startDate
        endDate
        diagnosticCheckId
        maintenanceHistoryId
        componentLocationId
      }
      pagination {
        currentPage
        pageSize
        totalCount
        totalPages
      }
    }
  }
}
    `;
export const useVehicleMetadataQuery = <TData = VehicleMetadataQuery, TError = unknown>(
  variables: VehicleMetadataQueryVariables,
  options?: UseQueryOptions<VehicleMetadataQuery, TError, TData>
) =>
  useQuery<VehicleMetadataQuery, TError, TData>(
    ["VehicleMetadata", variables],
    useCustomerApiFetcher<VehicleMetadataQuery, VehicleMetadataQueryVariables>(VehicleMetadataDocument).bind(
      null,
      variables
    ),
    options
  );

useVehicleMetadataQuery.getKey = (variables: VehicleMetadataQueryVariables) => ["VehicleMetadata", variables];
export const ExpressionEventOccurrencesPreviewDocument = `
    query expressionEventOccurrencesPreview($expressionId: ID!, $operator: ExpressionEventOperator!, $threshold: Float!, $independentVar: TimeSeriesIndependentVar!, $filter: FilterInput!) {
  filteredQuery(filter: $filter) {
    expressionEvents {
      occurrencesPreview(
        expressionId: $expressionId
        operator: $operator
        threshold: $threshold
        independentVar: $independentVar
      ) {
        x
        y
        count
      }
    }
  }
}
    `;
export const useExpressionEventOccurrencesPreviewQuery = <
  TData = ExpressionEventOccurrencesPreviewQuery,
  TError = unknown
>(
  variables: ExpressionEventOccurrencesPreviewQueryVariables,
  options?: UseQueryOptions<ExpressionEventOccurrencesPreviewQuery, TError, TData>
) =>
  useQuery<ExpressionEventOccurrencesPreviewQuery, TError, TData>(
    ["expressionEventOccurrencesPreview", variables],
    useCustomerApiFetcher<ExpressionEventOccurrencesPreviewQuery, ExpressionEventOccurrencesPreviewQueryVariables>(
      ExpressionEventOccurrencesPreviewDocument
    ).bind(null, variables),
    options
  );

useExpressionEventOccurrencesPreviewQuery.getKey = (variables: ExpressionEventOccurrencesPreviewQueryVariables) => [
  "expressionEventOccurrencesPreview",
  variables,
];
export const RelatedEventsDocument = `
    query relatedEvents($filter: FilterInput!, $eventFilter: EventFilter!, $mileageWindowSize: Int!, $sort: [SortFilter!], $pagination: Pagination, $telematicsless: Boolean!) {
  filteredQuery(filter: $filter) {
    relatedEvents(
      eventFilter: $eventFilter
      mileageWindowSize: $mileageWindowSize
      sort: $sort
      pagination: $pagination
      telematicsless: $telematicsless
    ) {
      pagination {
        currentPage
        pageSize
        totalCount
        totalPages
      }
      events {
        event {
          expressionEventId
          fault {
            faultCode
            ecu
            troubleCode
          }
          repairEventType
          name
        }
        totalOccurrences
        uniqueVehicles
      }
    }
  }
}
    `;
export const useRelatedEventsQuery = <TData = RelatedEventsQuery, TError = unknown>(
  variables: RelatedEventsQueryVariables,
  options?: UseQueryOptions<RelatedEventsQuery, TError, TData>
) =>
  useQuery<RelatedEventsQuery, TError, TData>(
    ["relatedEvents", variables],
    useCustomerApiFetcher<RelatedEventsQuery, RelatedEventsQueryVariables>(RelatedEventsDocument).bind(null, variables),
    options
  );

useRelatedEventsQuery.getKey = (variables: RelatedEventsQueryVariables) => ["relatedEvents", variables];
export const EventOccurrencesDocument = `
    query eventOccurrences($pagination: Pagination, $eventFilter: EventFilter!, $sorters: [SortFilter!], $filter: FilterInput!) {
  filteredQuery(filter: $filter) {
    eventOccurrences(
      pagination: $pagination
      event: $eventFilter
      sorters: $sorters
    ) {
      occurrences {
        id
        vehicle {
          id
          vin
          country
          model
          modelStyle
          odometer
          year
          lastReadingDate
        }
        odometer
        date
        eventCount
        isDateInferred
      }
      pagination {
        currentPage
        pageSize
        totalCount
        totalPages
      }
    }
  }
}
    `;
export const useEventOccurrencesQuery = <TData = EventOccurrencesQuery, TError = unknown>(
  variables: EventOccurrencesQueryVariables,
  options?: UseQueryOptions<EventOccurrencesQuery, TError, TData>
) =>
  useQuery<EventOccurrencesQuery, TError, TData>(
    ["eventOccurrences", variables],
    useCustomerApiFetcher<EventOccurrencesQuery, EventOccurrencesQueryVariables>(EventOccurrencesDocument).bind(
      null,
      variables
    ),
    options
  );

useEventOccurrencesQuery.getKey = (variables: EventOccurrencesQueryVariables) => ["eventOccurrences", variables];
export const EventOccurrencesRateDocument = `
    query eventOccurrencesRate($eventFilter: EventFilter!, $min: Int!, $max: Int!, $intervalSize: Int!, $windowSize: Int!, $filter: FilterInput!) {
  filteredQuery(filter: $filter) {
    eventOccurrencesRate(
      event: $eventFilter
      min: $min
      max: $max
      intervalSize: $intervalSize
      windowSize: $windowSize
    ) {
      end
      rate
    }
  }
}
    `;
export const useEventOccurrencesRateQuery = <TData = EventOccurrencesRateQuery, TError = unknown>(
  variables: EventOccurrencesRateQueryVariables,
  options?: UseQueryOptions<EventOccurrencesRateQuery, TError, TData>
) =>
  useQuery<EventOccurrencesRateQuery, TError, TData>(
    ["eventOccurrencesRate", variables],
    useCustomerApiFetcher<EventOccurrencesRateQuery, EventOccurrencesRateQueryVariables>(
      EventOccurrencesRateDocument
    ).bind(null, variables),
    options
  );

useEventOccurrencesRateQuery.getKey = (variables: EventOccurrencesRateQueryVariables) => [
  "eventOccurrencesRate",
  variables,
];
export const EventTimeSeriesHistoryDocument = `
    query eventTimeSeriesHistory($eventFilter: EventFilter!, $fieldIds: [TimeSeriesFieldId!]!, $selectors: [EventTimeSeriesSelector!]!, $minIndependentValue: Int!, $maxIndependentValue: Int!, $independentWindowSize: Int!, $independentInterval: Int!, $filter: FilterInput!) {
  filteredQuery(filter: $filter) {
    eventTimeSeriesHistory(
      eventFilter: $eventFilter
      selectors: $selectors
      minIndependentValue: $minIndependentValue
      maxIndependentValue: $maxIndependentValue
      independentWindowSize: $independentWindowSize
      independentInterval: $independentInterval
    ) {
      count
      high
      low
      x
      y
    }
    vehicleCohortTimeSeriesSummary(fieldIds: $fieldIds) {
      bottomQuartile
      bottomDecile
      bottomVigintile
      min
      max
      mean
      median
      standardDeviation
      topQuartile
      topDecile
      topVigintile
    }
  }
}
    `;
export const useEventTimeSeriesHistoryQuery = <TData = EventTimeSeriesHistoryQuery, TError = unknown>(
  variables: EventTimeSeriesHistoryQueryVariables,
  options?: UseQueryOptions<EventTimeSeriesHistoryQuery, TError, TData>
) =>
  useQuery<EventTimeSeriesHistoryQuery, TError, TData>(
    ["eventTimeSeriesHistory", variables],
    useCustomerApiFetcher<EventTimeSeriesHistoryQuery, EventTimeSeriesHistoryQueryVariables>(
      EventTimeSeriesHistoryDocument
    ).bind(null, variables),
    options
  );

useEventTimeSeriesHistoryQuery.getKey = (variables: EventTimeSeriesHistoryQueryVariables) => [
  "eventTimeSeriesHistory",
  variables,
];
export const FaultTimelineDocument = `
    query FaultTimeline($input: FaultTimelineInput!, $filter: FilterInput!) {
  filteredQuery(filter: $filter) {
    faultTimeline(input: $input) {
      faultCode
      ecu
      troubleCode
      series {
        xAxis
        faultCount
        pvinCount
      }
    }
  }
}
    `;
export const useFaultTimelineQuery = <TData = FaultTimelineQuery, TError = unknown>(
  variables: FaultTimelineQueryVariables,
  options?: UseQueryOptions<FaultTimelineQuery, TError, TData>
) =>
  useQuery<FaultTimelineQuery, TError, TData>(
    ["FaultTimeline", variables],
    useCustomerApiFetcher<FaultTimelineQuery, FaultTimelineQueryVariables>(FaultTimelineDocument).bind(null, variables),
    options
  );

useFaultTimelineQuery.getKey = (variables: FaultTimelineQueryVariables) => ["FaultTimeline", variables];
export const FaultTimelinePvinDocument = `
    query FaultTimelinePvin($input: FaultTimelineInputPvin!, $filter: FilterInput!) {
  filteredQuery(filter: $filter) {
    faultTimelinePvins(input: $input) {
      id
      vin
      model
      modelStyle
      modelYear
    }
  }
}
    `;
export const useFaultTimelinePvinQuery = <TData = FaultTimelinePvinQuery, TError = unknown>(
  variables: FaultTimelinePvinQueryVariables,
  options?: UseQueryOptions<FaultTimelinePvinQuery, TError, TData>
) =>
  useQuery<FaultTimelinePvinQuery, TError, TData>(
    ["FaultTimelinePvin", variables],
    useCustomerApiFetcher<FaultTimelinePvinQuery, FaultTimelinePvinQueryVariables>(FaultTimelinePvinDocument).bind(
      null,
      variables
    ),
    options
  );

useFaultTimelinePvinQuery.getKey = (variables: FaultTimelinePvinQueryVariables) => ["FaultTimelinePvin", variables];
export const ForecastFleetInputDataDocument = `
    query forecastFleetInputData($eventFilters: [EventFilter!]!, $odometerSampleSize: Int!) {
  vehicleDrivingDistanceHistograms(bucketSize: $odometerSampleSize) {
    distances
  }
  vehicleMileageHistogram(
    bucketSize: $odometerSampleSize
    min: $odometerSampleSize
  ) {
    maxMileage
    minMileage
    vehicleCount
  }
  vehicleAgeHistogram(bucketSize: 1, min: 1, max: 60) {
    months
    vehicleCount
  }
  eventRateHistory(
    eventFilters: $eventFilters
    independentVariable: MILEAGE
    intervalSize: $odometerSampleSize
  ) {
    x
    countWithEvent
    countTotal
  }
}
    `;
export const useForecastFleetInputDataQuery = <TData = ForecastFleetInputDataQuery, TError = unknown>(
  variables: ForecastFleetInputDataQueryVariables,
  options?: UseQueryOptions<ForecastFleetInputDataQuery, TError, TData>
) =>
  useQuery<ForecastFleetInputDataQuery, TError, TData>(
    ["forecastFleetInputData", variables],
    useCustomerApiFetcher<ForecastFleetInputDataQuery, ForecastFleetInputDataQueryVariables>(
      ForecastFleetInputDataDocument
    ).bind(null, variables),
    options
  );

useForecastFleetInputDataQuery.getKey = (variables: ForecastFleetInputDataQueryVariables) => [
  "forecastFleetInputData",
  variables,
];
export const HdmTableDocument = `
    query HDMTable($diagnosticCheckId: String!, $tableId: String!, $normalization: HDMNormalizations) {
  hdmTable(
    diagnosticCheckId: $diagnosticCheckId
    tableId: $tableId
    normalization: $normalization
  ) {
    values
    odometer
    timestamp
  }
}
    `;
export const useHdmTableQuery = <TData = HdmTableQuery, TError = unknown>(
  variables: HdmTableQueryVariables,
  options?: UseQueryOptions<HdmTableQuery, TError, TData>
) =>
  useQuery<HdmTableQuery, TError, TData>(
    ["HDMTable", variables],
    useCustomerApiFetcher<HdmTableQuery, HdmTableQueryVariables>(HdmTableDocument).bind(null, variables),
    options
  );

useHdmTableQuery.getKey = (variables: HdmTableQueryVariables) => ["HDMTable", variables];
export const HdmTableAggregateValueDocument = `
    query HDMTableAggregateValue($pvin: String!, $tableId: String!, $zoom: HDMZoom) {
  aggregateHDMTableValues(pvin: $pvin, tableId: $tableId, zoom: $zoom) {
    diagnosticCheckId
    odometer
    timestamp
    value
  }
}
    `;
export const useHdmTableAggregateValueQuery = <TData = HdmTableAggregateValueQuery, TError = unknown>(
  variables: HdmTableAggregateValueQueryVariables,
  options?: UseQueryOptions<HdmTableAggregateValueQuery, TError, TData>
) =>
  useQuery<HdmTableAggregateValueQuery, TError, TData>(
    ["HDMTableAggregateValue", variables],
    useCustomerApiFetcher<HdmTableAggregateValueQuery, HdmTableAggregateValueQueryVariables>(
      HdmTableAggregateValueDocument
    ).bind(null, variables),
    options
  );

useHdmTableAggregateValueQuery.getKey = (variables: HdmTableAggregateValueQueryVariables) => [
  "HDMTableAggregateValue",
  variables,
];
export const HdmComparisonOptionsDocument = `
    query HDMComparisonOptions {
  hdmComparisonOptions {
    model
    modelYear
    deliveryCountry
    moduleCount
  }
}
    `;
export const useHdmComparisonOptionsQuery = <TData = HdmComparisonOptionsQuery, TError = unknown>(
  variables?: HdmComparisonOptionsQueryVariables,
  options?: UseQueryOptions<HdmComparisonOptionsQuery, TError, TData>
) =>
  useQuery<HdmComparisonOptionsQuery, TError, TData>(
    variables === undefined ? ["HDMComparisonOptions"] : ["HDMComparisonOptions", variables],
    useCustomerApiFetcher<HdmComparisonOptionsQuery, HdmComparisonOptionsQueryVariables>(
      HdmComparisonOptionsDocument
    ).bind(null, variables),
    options
  );

useHdmComparisonOptionsQuery.getKey = (variables?: HdmComparisonOptionsQueryVariables) =>
  variables === undefined ? ["HDMComparisonOptions"] : ["HDMComparisonOptions", variables];
export const HdmComparisonTableDocument = `
    query HDMComparisonTable($customComparison: HDMCustomComparisonInput, $clusterComparison: HDMClusterComparisonInput, $tableId: String!, $pvin: String, $clusterId: String, $normalization: HDMNormalizations) {
  hdmComparisonTable(
    customComparison: $customComparison
    clusterComparison: $clusterComparison
    tableId: $tableId
    pvin: $pvin
    clusterId: $clusterId
    normalization: $normalization
  ) {
    values
  }
}
    `;
export const useHdmComparisonTableQuery = <TData = HdmComparisonTableQuery, TError = unknown>(
  variables: HdmComparisonTableQueryVariables,
  options?: UseQueryOptions<HdmComparisonTableQuery, TError, TData>
) =>
  useQuery<HdmComparisonTableQuery, TError, TData>(
    ["HDMComparisonTable", variables],
    useCustomerApiFetcher<HdmComparisonTableQuery, HdmComparisonTableQueryVariables>(HdmComparisonTableDocument).bind(
      null,
      variables
    ),
    options
  );

useHdmComparisonTableQuery.getKey = (variables: HdmComparisonTableQueryVariables) => ["HDMComparisonTable", variables];
export const DefaultHdmComparisonOptionsDocument = `
    query DefaultHDMComparisonOptions($pvin: String, $clusterId: String) {
  defaultHDMComparisonOptions(pvin: $pvin, clusterId: $clusterId) {
    model
    modelYear
    deliveryCountry
    moduleCount
  }
}
    `;
export const useDefaultHdmComparisonOptionsQuery = <TData = DefaultHdmComparisonOptionsQuery, TError = unknown>(
  variables?: DefaultHdmComparisonOptionsQueryVariables,
  options?: UseQueryOptions<DefaultHdmComparisonOptionsQuery, TError, TData>
) =>
  useQuery<DefaultHdmComparisonOptionsQuery, TError, TData>(
    variables === undefined ? ["DefaultHDMComparisonOptions"] : ["DefaultHDMComparisonOptions", variables],
    useCustomerApiFetcher<DefaultHdmComparisonOptionsQuery, DefaultHdmComparisonOptionsQueryVariables>(
      DefaultHdmComparisonOptionsDocument
    ).bind(null, variables),
    options
  );

useDefaultHdmComparisonOptionsQuery.getKey = (variables?: DefaultHdmComparisonOptionsQueryVariables) =>
  variables === undefined ? ["DefaultHDMComparisonOptions"] : ["DefaultHDMComparisonOptions", variables];
export const HomeCustomerDocument = `
    query homeCustomer {
  dataSourceStatus {
    dataSource
    lastUpdatedDate
    mostRecentDataDate
  }
}
    `;
export const useHomeCustomerQuery = <TData = HomeCustomerQuery, TError = unknown>(
  variables?: HomeCustomerQueryVariables,
  options?: UseQueryOptions<HomeCustomerQuery, TError, TData>
) =>
  useQuery<HomeCustomerQuery, TError, TData>(
    variables === undefined ? ["homeCustomer"] : ["homeCustomer", variables],
    useCustomerApiFetcher<HomeCustomerQuery, HomeCustomerQueryVariables>(HomeCustomerDocument).bind(null, variables),
    options
  );

useHomeCustomerQuery.getKey = (variables?: HomeCustomerQueryVariables) =>
  variables === undefined ? ["homeCustomer"] : ["homeCustomer", variables];
export const HomeRecentVehicleDetailsDocument = `
    query homeRecentVehicleDetails($filter: FilterInput!, $ids: [ID!]!) {
  filteredQuery(filter: $filter) {
    filteredVehicleDetails(ids: $ids) {
      id
      vin
      country
      odometer
      model
      model
      modelStyle
      year
    }
  }
}
    `;
export const useHomeRecentVehicleDetailsQuery = <TData = HomeRecentVehicleDetailsQuery, TError = unknown>(
  variables: HomeRecentVehicleDetailsQueryVariables,
  options?: UseQueryOptions<HomeRecentVehicleDetailsQuery, TError, TData>
) =>
  useQuery<HomeRecentVehicleDetailsQuery, TError, TData>(
    ["homeRecentVehicleDetails", variables],
    useCustomerApiFetcher<HomeRecentVehicleDetailsQuery, HomeRecentVehicleDetailsQueryVariables>(
      HomeRecentVehicleDetailsDocument
    ).bind(null, variables),
    options
  );

useHomeRecentVehicleDetailsQuery.getKey = (variables: HomeRecentVehicleDetailsQueryVariables) => [
  "homeRecentVehicleDetails",
  variables,
];
export const GetFilteredExpressionHistogramDocument = `
    query getFilteredExpressionHistogram($filter: FilterInput!, $buckets: Int, $expression: JSONObject!, $outlierPercentile: Float) {
  filteredQuery(filter: $filter) {
    filteredSavedExpressionsHistogram(
      buckets: $buckets
      outlierPercentile: $outlierPercentile
      expression: $expression
    ) {
      max
      min
      pct
      outliers {
        min
        max
        pct
      }
    }
  }
}
    `;
export const useGetFilteredExpressionHistogramQuery = <TData = GetFilteredExpressionHistogramQuery, TError = unknown>(
  variables: GetFilteredExpressionHistogramQueryVariables,
  options?: UseQueryOptions<GetFilteredExpressionHistogramQuery, TError, TData>
) =>
  useQuery<GetFilteredExpressionHistogramQuery, TError, TData>(
    ["getFilteredExpressionHistogram", variables],
    useCustomerApiFetcher<GetFilteredExpressionHistogramQuery, GetFilteredExpressionHistogramQueryVariables>(
      GetFilteredExpressionHistogramDocument
    ).bind(null, variables),
    options
  );

useGetFilteredExpressionHistogramQuery.getKey = (variables: GetFilteredExpressionHistogramQueryVariables) => [
  "getFilteredExpressionHistogram",
  variables,
];
export const GetFilteredExpressionTimeSeriesDocument = `
    query getFilteredExpressionTimeSeries($filter: FilterInput!, $expression: JSONObject!, $independentVar: TimeSeriesIndependentVar!) {
  filteredQuery(filter: $filter) {
    filteredSavedExpressionsTimeSeries(
      expression: $expression
      independentVar: $independentVar
    ) {
      high
      low
      x
      y
      vehicleCount
    }
  }
}
    `;
export const useGetFilteredExpressionTimeSeriesQuery = <TData = GetFilteredExpressionTimeSeriesQuery, TError = unknown>(
  variables: GetFilteredExpressionTimeSeriesQueryVariables,
  options?: UseQueryOptions<GetFilteredExpressionTimeSeriesQuery, TError, TData>
) =>
  useQuery<GetFilteredExpressionTimeSeriesQuery, TError, TData>(
    ["getFilteredExpressionTimeSeries", variables],
    useCustomerApiFetcher<GetFilteredExpressionTimeSeriesQuery, GetFilteredExpressionTimeSeriesQueryVariables>(
      GetFilteredExpressionTimeSeriesDocument
    ).bind(null, variables),
    options
  );

useGetFilteredExpressionTimeSeriesQuery.getKey = (variables: GetFilteredExpressionTimeSeriesQueryVariables) => [
  "getFilteredExpressionTimeSeries",
  variables,
];
export const RepairEventsDocument = `
    query RepairEvents($filter: FilterInput!, $pagination: Pagination, $sort: [SortFilter!], $columnFilters: [ColumnFilter!]) {
  filteredQuery(filter: $filter) {
    RepairEvents(
      pagination: $pagination
      sort: $sort
      columnFilters: $columnFilters
    ) {
      data {
        pvin
        vin
        model
        modelYear
        repairStartDate
        repairEndDate
        componentLocationId
        id
        diagnosticCheckId
        clusterId
      }
      pagination {
        currentPage
        pageSize
        totalCount
      }
    }
  }
}
    `;
export const useRepairEventsQuery = <TData = RepairEventsQuery, TError = unknown>(
  variables: RepairEventsQueryVariables,
  options?: UseQueryOptions<RepairEventsQuery, TError, TData>
) =>
  useQuery<RepairEventsQuery, TError, TData>(
    ["RepairEvents", variables],
    useCustomerApiFetcher<RepairEventsQuery, RepairEventsQueryVariables>(RepairEventsDocument).bind(null, variables),
    options
  );

useRepairEventsQuery.getKey = (variables: RepairEventsQueryVariables) => ["RepairEvents", variables];
export const RepairEventsClusterMatchesDocument = `
    query RepairEventsClusterMatches($filter: FilterInput!, $RepairEventIds: [String]!) {
  filteredQuery(filter: $filter) {
    RepairEventsClustertMatches(RepairEventIds: $RepairEventIds) {
      repairEventId
      percentOfTotal
      recommendedCluster
      recommendedClusterId
      faultCode
      componentLocationId
      frequency
    }
  }
}
    `;
export const useRepairEventsClusterMatchesQuery = <TData = RepairEventsClusterMatchesQuery, TError = unknown>(
  variables: RepairEventsClusterMatchesQueryVariables,
  options?: UseQueryOptions<RepairEventsClusterMatchesQuery, TError, TData>
) =>
  useQuery<RepairEventsClusterMatchesQuery, TError, TData>(
    ["RepairEventsClusterMatches", variables],
    useCustomerApiFetcher<RepairEventsClusterMatchesQuery, RepairEventsClusterMatchesQueryVariables>(
      RepairEventsClusterMatchesDocument
    ).bind(null, variables),
    options
  );

useRepairEventsClusterMatchesQuery.getKey = (variables: RepairEventsClusterMatchesQueryVariables) => [
  "RepairEventsClusterMatches",
  variables,
];
export const RepairEventsColumnByUserInputDocument = `
    query RepairEventsColumnByUserInput($filter: FilterInput!, $input: InputOrMappedValues!, $column: RepairEventsColumns!, $columnFilters: [ColumnFilter!]) {
  filteredQuery(filter: $filter) {
    RepairEventColumnByUserInput(
      input: $input
      column: $column
      columnFilters: $columnFilters
    )
  }
}
    `;
export const useRepairEventsColumnByUserInputQuery = <TData = RepairEventsColumnByUserInputQuery, TError = unknown>(
  variables: RepairEventsColumnByUserInputQueryVariables,
  options?: UseQueryOptions<RepairEventsColumnByUserInputQuery, TError, TData>
) =>
  useQuery<RepairEventsColumnByUserInputQuery, TError, TData>(
    ["RepairEventsColumnByUserInput", variables],
    useCustomerApiFetcher<RepairEventsColumnByUserInputQuery, RepairEventsColumnByUserInputQueryVariables>(
      RepairEventsColumnByUserInputDocument
    ).bind(null, variables),
    options
  );

useRepairEventsColumnByUserInputQuery.getKey = (variables: RepairEventsColumnByUserInputQueryVariables) => [
  "RepairEventsColumnByUserInput",
  variables,
];
export const EnrichVehicleTimestampDataDocument = `
    query enrichVehicleTimestampData($points: [VehiclePointInput!]!) {
  enrichVehicleTimestampData(points: $points) {
    pvin
    timestamp
    odometer
  }
}
    `;
export const useEnrichVehicleTimestampDataQuery = <TData = EnrichVehicleTimestampDataQuery, TError = unknown>(
  variables: EnrichVehicleTimestampDataQueryVariables,
  options?: UseQueryOptions<EnrichVehicleTimestampDataQuery, TError, TData>
) =>
  useQuery<EnrichVehicleTimestampDataQuery, TError, TData>(
    ["enrichVehicleTimestampData", variables],
    useCustomerApiFetcher<EnrichVehicleTimestampDataQuery, EnrichVehicleTimestampDataQueryVariables>(
      EnrichVehicleTimestampDataDocument
    ).bind(null, variables),
    options
  );

useEnrichVehicleTimestampDataQuery.getKey = (variables: EnrichVehicleTimestampDataQueryVariables) => [
  "enrichVehicleTimestampData",
  variables,
];
export const VehicleFaultHistoryDocument = `
    query vehicleFaultHistory($id: ID!, $pagination: Pagination, $sort: [SortFilter!], $columnFilters: [ColumnFilter!]) {
  vehicleFaultHistory(
    id: $id
    pagination: $pagination
    sort: $sort
    columnFilters: $columnFilters
  ) {
    data {
      vehicleId
      code
      troubleCode
      category
      odometer
      id
      source
      startTime
      endTime
      isDateInferred
      softwareVersion
      hardwareVersion
      softwarePartNumber
      hardwarePartNumber
      diagnositcCheckId
      dealerNumber
      diagnosticDate
      fields {
        id
        value
      }
    }
    pagination {
      currentPage
      pageSize
      totalCount
    }
  }
}
    `;
export const useVehicleFaultHistoryQuery = <TData = VehicleFaultHistoryQuery, TError = unknown>(
  variables: VehicleFaultHistoryQueryVariables,
  options?: UseQueryOptions<VehicleFaultHistoryQuery, TError, TData>
) =>
  useQuery<VehicleFaultHistoryQuery, TError, TData>(
    ["vehicleFaultHistory", variables],
    useCustomerApiFetcher<VehicleFaultHistoryQuery, VehicleFaultHistoryQueryVariables>(
      VehicleFaultHistoryDocument
    ).bind(null, variables),
    options
  );

useVehicleFaultHistoryQuery.getKey = (variables: VehicleFaultHistoryQueryVariables) => [
  "vehicleFaultHistory",
  variables,
];
export const FaultHistoryColumnsDocument = `
    query faultHistoryColumns($input: String!, $column: FaultHistoryColumn!, $pvin: String, $columnFilters: [ColumnFilter!]) {
  faultHistoryColumnByUserInput(
    input: $input
    column: $column
    pvin: $pvin
    columnFilters: $columnFilters
  )
}
    `;
export const useFaultHistoryColumnsQuery = <TData = FaultHistoryColumnsQuery, TError = unknown>(
  variables: FaultHistoryColumnsQueryVariables,
  options?: UseQueryOptions<FaultHistoryColumnsQuery, TError, TData>
) =>
  useQuery<FaultHistoryColumnsQuery, TError, TData>(
    ["faultHistoryColumns", variables],
    useCustomerApiFetcher<FaultHistoryColumnsQuery, FaultHistoryColumnsQueryVariables>(
      FaultHistoryColumnsDocument
    ).bind(null, variables),
    options
  );

useFaultHistoryColumnsQuery.getKey = (variables: FaultHistoryColumnsQueryVariables) => [
  "faultHistoryColumns",
  variables,
];
export const FaultHistoryColumnsFilterDocument = `
    query faultHistoryColumnsFilter($pvin: String!) {
  faultHistoryByUserInput(pvin: $pvin) {
    ecu
    faultCode
    troubleCode
  }
}
    `;
export const useFaultHistoryColumnsFilterQuery = <TData = FaultHistoryColumnsFilterQuery, TError = unknown>(
  variables: FaultHistoryColumnsFilterQueryVariables,
  options?: UseQueryOptions<FaultHistoryColumnsFilterQuery, TError, TData>
) =>
  useQuery<FaultHistoryColumnsFilterQuery, TError, TData>(
    ["faultHistoryColumnsFilter", variables],
    useCustomerApiFetcher<FaultHistoryColumnsFilterQuery, FaultHistoryColumnsFilterQueryVariables>(
      FaultHistoryColumnsFilterDocument
    ).bind(null, variables),
    options
  );

useFaultHistoryColumnsFilterQuery.getKey = (variables: FaultHistoryColumnsFilterQueryVariables) => [
  "faultHistoryColumnsFilter",
  variables,
];
export const VehicleDetailsDocument = `
    query vehicleDetails($id: ID!) {
  vehicleDetails(id: $id) {
    id
    vin
    model
    modelStyle
    country
    year
    odometer
    lastReadingDate
    lastDiagnosticCheckDate
    productionDate
    deliveryDate
    importer
  }
}
    `;
export const useVehicleDetailsQuery = <TData = VehicleDetailsQuery, TError = unknown>(
  variables: VehicleDetailsQueryVariables,
  options?: UseQueryOptions<VehicleDetailsQuery, TError, TData>
) =>
  useQuery<VehicleDetailsQuery, TError, TData>(
    ["vehicleDetails", variables],
    useCustomerApiFetcher<VehicleDetailsQuery, VehicleDetailsQueryVariables>(VehicleDetailsDocument).bind(
      null,
      variables
    ),
    options
  );

useVehicleDetailsQuery.getKey = (variables: VehicleDetailsQueryVariables) => ["vehicleDetails", variables];
export const VehicleTimeSeriesDocument = `
    query vehicleTimeSeries($id: ID!, $fields: [TimeSeriesFieldInput!]!, $independentVar: TimeSeriesIndependentVar, $independentVarInterval: IntervalInput, $independentVarRange: IndependentVarRangeInput, $withComparison: Boolean!, $comparisonFields: [TimeSeriesFieldId!]!, $filter: FilterInput!, $allTimeSeriesFieldIds: [TimeSeriesFieldId!]!, $timestampEpoch: VehicleCohortEpoch) {
  vehicleTimeSeries(
    id: $id
    fields: $fields
    independentVar: $independentVar
    independentVarInterval: $independentVarInterval
    independentVarRange: $independentVarRange
  ) {
    independentVarInterval {
      distance
      timeUnit
    }
    x
    y
  }
  filteredQuery(filter: $filter) {
    vehicleCohortTimeSeries(
      id: $id
      fields: $comparisonFields
      independentVar: $independentVar
      independentVarInterval: $independentVarInterval
      independentVarRange: $independentVarRange
      timestampEpoch: $timestampEpoch
    ) @include(if: $withComparison) {
      independentVarInterval {
        distance
        timeUnit
      }
      cohortFilter {
        model
        modelYear
        deliveryCountry
        modelStyle
      }
      x
      min
      max
      mean
      median
      bottomQuartile
      topQuartile
      bottom5Perc
      top5Perc
      stddev
    }
  }
  anomalyDetectorsForTimeSeriesFieldIds(ids: $allTimeSeriesFieldIds) {
    id
    name
    cautionThreshold
    warningThreshold
    type
    isCustomerMLModel
  }
}
    `;
export const useVehicleTimeSeriesQuery = <TData = VehicleTimeSeriesQuery, TError = unknown>(
  variables: VehicleTimeSeriesQueryVariables,
  options?: UseQueryOptions<VehicleTimeSeriesQuery, TError, TData>
) =>
  useQuery<VehicleTimeSeriesQuery, TError, TData>(
    ["vehicleTimeSeries", variables],
    useCustomerApiFetcher<VehicleTimeSeriesQuery, VehicleTimeSeriesQueryVariables>(VehicleTimeSeriesDocument).bind(
      null,
      variables
    ),
    options
  );

useVehicleTimeSeriesQuery.getKey = (variables: VehicleTimeSeriesQueryVariables) => ["vehicleTimeSeries", variables];
export const HealthCheckDocument = `
    query HealthCheck($testNamePatterns: [String!], $filePatterns: [String!], $testVariables: TestVariablesInput) {
  healthCheck(
    testNamePatterns: $testNamePatterns
    filePatterns: $filePatterns
    testVariables: $testVariables
  ) {
    summary {
      numFailedTestSuites
      numFailedTests
      numPassedTestSuites
      numPassedTests
      numPendingTestSuites
      numPendingTests
      numTodoTests
      numTotalTestSuites
      numTotalTests
      startTime
      success
    }
    queryInfo {
      graphQLResolver
    }
    results {
      assertions {
        ancestorTitles
        duration
        failureMessages
        fullName
        location {
          column
          line
        }
        status
        title
      }
      fileName
      message
      tests {
        errorLogs {
          content
          size
          taskId
          time
          type
        }
        name
        status
        testDuration
      }
    }
  }
}
    `;
export const useHealthCheckQuery = <TData = HealthCheckQuery, TError = unknown>(
  variables?: HealthCheckQueryVariables,
  options?: UseQueryOptions<HealthCheckQuery, TError, TData>
) =>
  useQuery<HealthCheckQuery, TError, TData>(
    variables === undefined ? ["HealthCheck"] : ["HealthCheck", variables],
    useCustomerApiFetcher<HealthCheckQuery, HealthCheckQueryVariables>(HealthCheckDocument).bind(null, variables),
    options
  );

useHealthCheckQuery.getKey = (variables?: HealthCheckQueryVariables) =>
  variables === undefined ? ["HealthCheck"] : ["HealthCheck", variables];
export const MissingPvinsDocument = `
    query MissingPvins($pvins: [ID!]!) {
  missingPvins(pvins: $pvins)
}
    `;
export const useMissingPvinsQuery = <TData = MissingPvinsQuery, TError = unknown>(
  variables: MissingPvinsQueryVariables,
  options?: UseQueryOptions<MissingPvinsQuery, TError, TData>
) =>
  useQuery<MissingPvinsQuery, TError, TData>(
    ["MissingPvins", variables],
    useCustomerApiFetcher<MissingPvinsQuery, MissingPvinsQueryVariables>(MissingPvinsDocument).bind(null, variables),
    options
  );

useMissingPvinsQuery.getKey = (variables: MissingPvinsQueryVariables) => ["MissingPvins", variables];
export const CreateClusterInRedshiftDocument = `
    mutation CreateClusterInRedshift($clusterId: SignedId!, $input: [ClusterRedshiftCreateInput!]!) {
  createClusterPvins(clusterId: $clusterId, input: $input)
}
    `;
export const useCreateClusterInRedshiftMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateClusterInRedshiftMutation,
    TError,
    CreateClusterInRedshiftMutationVariables,
    TContext
  >
) =>
  useMutation<CreateClusterInRedshiftMutation, TError, CreateClusterInRedshiftMutationVariables, TContext>(
    ["CreateClusterInRedshift"],
    useCustomerApiFetcher<CreateClusterInRedshiftMutation, CreateClusterInRedshiftMutationVariables>(
      CreateClusterInRedshiftDocument
    ),
    options
  );
export const PvinsByClusterDocument = `
    query PvinsByCluster($filter: FilterInput!, $clusterIds: [SignedId!]!) {
  filteredQuery(filter: $filter) {
    pvinsByCluster(clusterIds: $clusterIds) {
      clusters {
        clusterId
        pvins {
          pvin
          startDate
          endDate
        }
        missingPvins {
          pvin
          startDate
          endDate
        }
        vehicleCount
        combinationCount
        recordWithDiagnosticCheckCount
        recordWithMaintenanceHistoryCount
      }
    }
  }
}
    `;
export const usePvinsByClusterQuery = <TData = PvinsByClusterQuery, TError = unknown>(
  variables: PvinsByClusterQueryVariables,
  options?: UseQueryOptions<PvinsByClusterQuery, TError, TData>
) =>
  useQuery<PvinsByClusterQuery, TError, TData>(
    ["PvinsByCluster", variables],
    useCustomerApiFetcher<PvinsByClusterQuery, PvinsByClusterQueryVariables>(PvinsByClusterDocument).bind(
      null,
      variables
    ),
    options
  );

usePvinsByClusterQuery.getKey = (variables: PvinsByClusterQueryVariables) => ["PvinsByCluster", variables];
export const PvinCountsByClusterDocument = `
    query PvinCountsByCluster($filter: FilterInput!, $clusterIds: [SignedId!]!) {
  filteredQuery(filter: $filter) {
    pvinsByCluster(clusterIds: $clusterIds) {
      clusters {
        clusterId
        vehicleCount
        combinationCount
        recordWithDiagnosticCheckCount
        recordWithMaintenanceHistoryCount
      }
    }
  }
}
    `;
export const usePvinCountsByClusterQuery = <TData = PvinCountsByClusterQuery, TError = unknown>(
  variables: PvinCountsByClusterQueryVariables,
  options?: UseQueryOptions<PvinCountsByClusterQuery, TError, TData>
) =>
  useQuery<PvinCountsByClusterQuery, TError, TData>(
    ["PvinCountsByCluster", variables],
    useCustomerApiFetcher<PvinCountsByClusterQuery, PvinCountsByClusterQueryVariables>(
      PvinCountsByClusterDocument
    ).bind(null, variables),
    options
  );

usePvinCountsByClusterQuery.getKey = (variables: PvinCountsByClusterQueryVariables) => [
  "PvinCountsByCluster",
  variables,
];
export const DeleteClusterDocument = `
    mutation DeleteCluster($clusterId: SignedId!) {
  deleteAllPvinsFromCluster(clusterId: $clusterId)
}
    `;
export const useDeleteClusterMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<DeleteClusterMutation, TError, DeleteClusterMutationVariables, TContext>
) =>
  useMutation<DeleteClusterMutation, TError, DeleteClusterMutationVariables, TContext>(
    ["DeleteCluster"],
    useCustomerApiFetcher<DeleteClusterMutation, DeleteClusterMutationVariables>(DeleteClusterDocument),
    options
  );
export const AddPvinsToClusterDocument = `
    mutation AddPvinsToCluster($clusterId: SignedId!, $input: [ClusterRedshiftCreateInput!]!) {
  addPvinsToCluster(clusterId: $clusterId, input: $input) {
    missingPvins
    existingCombinations {
      pvin
      startDate
      endDate
    }
  }
}
    `;
export const useAddPvinsToClusterMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<AddPvinsToClusterMutation, TError, AddPvinsToClusterMutationVariables, TContext>
) =>
  useMutation<AddPvinsToClusterMutation, TError, AddPvinsToClusterMutationVariables, TContext>(
    ["AddPvinsToCluster"],
    useCustomerApiFetcher<AddPvinsToClusterMutation, AddPvinsToClusterMutationVariables>(AddPvinsToClusterDocument),
    options
  );
export const RemovePvinsFromClusterDocument = `
    mutation RemovePvinsFromCluster($clusterId: SignedId!, $input: [ClusterRedshiftCreateInput!]!) {
  removePvinsFromCluster(clusterId: $clusterId, input: $input)
}
    `;
export const useRemovePvinsFromClusterMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RemovePvinsFromClusterMutation,
    TError,
    RemovePvinsFromClusterMutationVariables,
    TContext
  >
) =>
  useMutation<RemovePvinsFromClusterMutation, TError, RemovePvinsFromClusterMutationVariables, TContext>(
    ["RemovePvinsFromCluster"],
    useCustomerApiFetcher<RemovePvinsFromClusterMutation, RemovePvinsFromClusterMutationVariables>(
      RemovePvinsFromClusterDocument
    ),
    options
  );
export const CopyClusterDocument = `
    mutation CopyCluster($clusterId: SignedId!, $copyClusterId: SignedId!) {
  copyCluster(clusterId: $clusterId, copyClusterId: $copyClusterId)
}
    `;
export const useCopyClusterMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<CopyClusterMutation, TError, CopyClusterMutationVariables, TContext>
) =>
  useMutation<CopyClusterMutation, TError, CopyClusterMutationVariables, TContext>(
    ["CopyCluster"],
    useCustomerApiFetcher<CopyClusterMutation, CopyClusterMutationVariables>(CopyClusterDocument),
    options
  );
export const SearchVehiclesDocument = `
    query SearchVehicles($input: String!, $limit: Int) {
  searchVehicles(input: $input, limit: $limit) {
    id
    vin
    country
    deliveryDate
    importer
    lastDiagnosticCheckDate
    lastReadingDate
    model
    modelStyle
    odometer
    productionDate
    year
  }
}
    `;
export const useSearchVehiclesQuery = <TData = SearchVehiclesQuery, TError = unknown>(
  variables: SearchVehiclesQueryVariables,
  options?: UseQueryOptions<SearchVehiclesQuery, TError, TData>
) =>
  useQuery<SearchVehiclesQuery, TError, TData>(
    ["SearchVehicles", variables],
    useCustomerApiFetcher<SearchVehiclesQuery, SearchVehiclesQueryVariables>(SearchVehiclesDocument).bind(
      null,
      variables
    ),
    options
  );

useSearchVehiclesQuery.getKey = (variables: SearchVehiclesQueryVariables) => ["SearchVehicles", variables];
export const MlAnomalyDetectorsDocument = `
    query MlAnomalyDetectors {
  mlAnomalyDetectors {
    id
    timeSeriesFieldId
    name
    inputIds
  }
}
    `;
export const useMlAnomalyDetectorsQuery = <TData = MlAnomalyDetectorsQuery, TError = unknown>(
  variables?: MlAnomalyDetectorsQueryVariables,
  options?: UseQueryOptions<MlAnomalyDetectorsQuery, TError, TData>
) =>
  useQuery<MlAnomalyDetectorsQuery, TError, TData>(
    variables === undefined ? ["MlAnomalyDetectors"] : ["MlAnomalyDetectors", variables],
    useCustomerApiFetcher<MlAnomalyDetectorsQuery, MlAnomalyDetectorsQueryVariables>(MlAnomalyDetectorsDocument).bind(
      null,
      variables
    ),
    options
  );

useMlAnomalyDetectorsQuery.getKey = (variables?: MlAnomalyDetectorsQueryVariables) =>
  variables === undefined ? ["MlAnomalyDetectors"] : ["MlAnomalyDetectors", variables];
