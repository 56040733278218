import i18next from "i18next";

import { HdmTableAggregateValue, HdmTableAggregateValueQuery, TimeSeriesIndependentVar } from "@/api/customerApi";
import { intitialDataAttributeName } from "@/hooks/usePortalTargetObserver";
import { defaultTheme } from "@/styles/Global.styled";
import { DataMatrixDefinition } from "@/utils/types/commonDataDictionary";

import { xAxisLabelFormatter } from "../chartElementLibrary/ChartLibraryBuildUtils";
import { SCVHDMToolTip } from "./HDMHistoryChartToolTip";
import { HDMSelectedDiagnosticCheck } from "./HistoricDataMatrix";
import { initialHDMHistoryChartTooltipDataElementId } from "./HistoricDataMatrixHistoryChart";

type HdmHistoryConfigArgs = {
  xAxis: {
    label: string;
    key: TimeSeriesIndependentVar;
  };
  data: HdmTableAggregateValueQuery | undefined;
  zoom:
    | {
        min: number;
        max: number;
      }
    | undefined;
  selectedDiagnosticCheck?: HDMSelectedDiagnosticCheck;
  selectedTableDefintion: DataMatrixDefinition | undefined;
  tooltipPortalTargetId: string;
};

const buildChartSeriesValue = (
  hdmTable: HdmTableAggregateValue,
  xAxis: {
    label: string;
    key: TimeSeriesIndependentVar;
  },
  color: string
) => {
  return {
    value: [
      xAxis.key === TimeSeriesIndependentVar.Time ? new Date(hdmTable.timestamp).getTime() : hdmTable.odometer,
      hdmTable.value,
      {
        diagnosticCheckId: hdmTable.diagnosticCheckId,
        label: `${i18next.t("columnTitle.DIAGNOSTIC_CHECK_ID")}: ${hdmTable.timestamp} (${hdmTable.diagnosticCheckId})`,
      },
      hdmTable.timestamp,
      hdmTable.odometer,
    ],
    itemStyle: { color, opacity: 1 },
  };
};

const onTooltip = (param: Object, tooltipPortalTargetId: string) => {
  const currentParam = param as unknown as { data: { value: any[] } };

  const [x, y, diagnosticCheck, timestamp, odometer] = currentParam.data.value;

  const hdmBaseData: SCVHDMToolTip = {
    x,
    y,
    diagnosticCheckId: diagnosticCheck.diagnosticCheckId,
    timestamp,
    odometer,
  };

  return `
  <div class="tooltip-container">
      <div id="${initialHDMHistoryChartTooltipDataElementId}" ${intitialDataAttributeName}=${JSON.stringify(
    hdmBaseData
  )}>
      </div>
    <div id=${tooltipPortalTargetId} class="portal-target"/>
  </div>
  `;
};
export const getHDMHistoryChartConfig = ({
  xAxis,
  data,
  zoom,
  selectedDiagnosticCheck,
  selectedTableDefintion,
  tooltipPortalTargetId,
}: HdmHistoryConfigArgs) => {
  return {
    animation: false,
    tooltip: {
      trigger: "item",
      className: "hdm-history-tooltip-container",
      formatter: (params: any) => onTooltip(params, tooltipPortalTargetId),
    },
    // zoom tool is there but it's not visible
    toolbox: {
      orient: "vertical",
      itemSize: 20,
      top: -275,
      itemGap: 15,
      feature: {
        dataZoom: { iconStyle: { opacity: 0 } },
        restore: {},
      },
      show: true,
    },
    dataZoom: [
      {
        type: "inside",
        zoomOnMouseWheel: "shift",
        preventDefaultMouseMove: false,
        disabled: false,
        xAxisIndex: [0, 1],
        show: false,
      },
    ],
    grid: { left: 75, right: 50, bottom: 50 },
    xAxis: {
      type: xAxis.key === TimeSeriesIndependentVar.Time ? "time" : "value",
      data: data?.aggregateHDMTableValues.map((hdmTable) =>
        xAxis.key === TimeSeriesIndependentVar.Time ? new Date(hdmTable.timestamp).getTime() : hdmTable.odometer
      ),
      min: zoom?.min,
      max: zoom?.max,
      axisLabel: {
        formatter: (value: any) =>
          xAxisLabelFormatter(
            value,
            xAxis.key === TimeSeriesIndependentVar.Time
              ? TimeSeriesIndependentVar.Time
              : TimeSeriesIndependentVar.Mileage,
            undefined
          ),
      },
    },
    yAxis: {
      type: "value",
      name: `${selectedTableDefintion?.value_unit ?? ""}`,
    },
    series: [
      {
        type: "scatter",
        data: data?.aggregateHDMTableValues
          .filter((hdmTable) => hdmTable.diagnosticCheckId !== selectedDiagnosticCheck?.diagnosticCheckId)
          .map((hdmTable) => buildChartSeriesValue(hdmTable, xAxis, defaultTheme.colors.accentBlue)),
        zLevel: 1,
        emphasis: {
          itemStyle: {
            color: defaultTheme.colors.success,
          },
        },
      },
      {
        type: "scatter",
        data: data?.aggregateHDMTableValues
          .filter((hdmTable) => hdmTable.diagnosticCheckId === selectedDiagnosticCheck?.diagnosticCheckId)
          .map((hdmTable) => buildChartSeriesValue(hdmTable, xAxis, defaultTheme.colors.success)),
        zLevel: 10,
        emphasis: {
          itemStyle: {
            color: defaultTheme.colors.success,
          },
        },
      },
    ],
  };
};
