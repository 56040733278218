import styled from "styled-components";

export const DetectorHeaderContainer = styled.div`
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.defaultWhite};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  .ctas-container {
    display: flex;
    margin-left: auto;
    .cta-btn {
      min-width: 110px;
    }
  }
`;

export const DetectorTitleContainer = styled.div`
  width: 450px;
  min-height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;
`;

export const DetectorAttributeContainer = styled.div`
  min-width: 155px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  border-left: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

export const DetectorDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  .pattern-link-container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
`;
