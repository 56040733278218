import { Table } from "antd";
import styled from "styled-components";

export const TableWrapper = styled.div`
  width: 100%;
  .loading-tip {
    font-size: 0.75rem;
    font-family: "Hubot Sans";
  }
  .ant-spin {
    max-height: 100% !important;
  }
`;

export const TableCellWrapper = styled.div.attrs({ className: "amaya-cell-wrapper" })<{ size?: string | number }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${({ size }) => (size ? `${size}px` : "unset")};
`;

export const StyledTable = styled(Table)`
  .ant-empty {
    height: 256px;
  }
  &.loading-table {
    table {
      th,
      td {
        overflow: hidden;
        text-overflow: unset;
        white-space: unset;
      }
    }
  }
  table {
    th,
    td {
      height: 55px !important;
      line-height: 1rem;
      padding: 1rem 0.5rem !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100px;
      &:first-of-type {
        padding: 1rem 0.5rem 1rem 1.5rem !important;
      }
      &:last-of-type {
        padding: 0.5rem 1.5rem 0.5rem 0.5rem !important;
      }
      &.ant-table-row-expand-icon-cell {
        padding: 0px !important;
      }
      &.warning {
        color: ${({ theme }) => theme.colors.warning};
      }
      &.error {
        color: ${({ theme }) => theme.colors.error};
      }
    }
    tbody {
      font-weight: 400;
      tr {
        background-color: ${({ theme }) => theme.colors.defaultWhite};
        &:nth-of-type(even) {
          background-color: ${({ theme }) => theme.colors.oddRow};
        }
        &:hover,
        &:has(.ant-dropdown-open) {
          background-color: ${({ theme }) => theme.colors.hoverRow} !important;
        }
        // Override antd td bg and border
        td {
          font-size: 14px !important;
          background-color: unset !important;
          border: unset;
          color: #323232;
        }
        &:not(:last-of-type) {
          td {
            border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
          }
        }
        &.highlighted {
          outline: 2px solid ${({ theme }) => theme.colors.warning};
          outline-offset: -2px;
          td {
            border-color: transparent;
          }
        }
        &.cta-row {
          cursor: pointer;
        }
      }
    }
    // links
    a {
      color: ${({ theme }) => theme.colors.accentBlue};
    }
    // cursor for rows where we can interact
    .row-cta {
      cursor: pointer;
    }
    // Styling for floating rows or bold rows
    .bold-row {
      font-weight: 600;
    }
    // Styling for red cells
    .error {
      color: ${({ theme }) => theme.colors.error};
    }
    .ant-skeleton {
      &.ant-skeleton-element {
        .ant-skeleton-input {
          height: 1rem;
          line-height: 1rem;
          min-width: 4rem;
        }
      }
    }
  }
  // Add margins to sorter icons
  .ant-table-column-sort {
    .ant-table-column-sorters {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 0.5rem;
      .ant-table-column-title {
        max-width: fit-content;
      }
    }
  }

  // Adds custome styling to pagination
  .ant-table-pagination {
    padding: 0 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    padding: 5px 0px;
  }

  .title {
    font-size: 14px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: -0.28px;
  }

  .sub-title {
    font-size: 12px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  // Little hack to keep the general scrollbar styling
  .ant-table {
    scrollbar-color: unset !important;
  }
  // Truly hides the "hidden-row" antd uses for scroll calculations
  // https://github.com/ant-design/ant-design/issues/43437
  tr.ant-table-measure-row {
    visibility: collapse;
  }
  .ant-table-expanded-row:hover {
    background-color: inherit !important;
  }
  .ant-table-fault-history {
    cursor: pointer;
  }
  .ant-pagination-options {
    order: -1;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
  }

  .ant-pagination-options-quick-jumper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    input {
      height: 40px;
    }
  }
  .drag-selected-cell {
    background-color: #0094ff35 !important;
    user-select: none;
  }

  .ant-pagination-item-active {
    border-color: ${({ theme }) => theme.colors.accentBlue};
    a {
      color: ${({ theme }) => theme.colors.accentBlue};
    }
  }

  .cluster-recommendation-row {
    &.strong-match {
      .status-cell {
        border-left: 0.35rem solid #2bc18b;
      }
    }
    &.moderate-match {
      .status-cell {
        border-left: 0.35rem solid #ffac3f;
      }
    }
    &.weak-match {
      .status-cell {
        border-left: 0.35rem solid #df0000;
      }
    }
  }

  &.basic-table-selecting {
    user-select: none;
    tr {
      &:hover {
        background-color: ${({ theme }) => theme.colors.defaultWhite} !important;
        &:nth-of-type(even) {
          background-color: ${({ theme }) => theme.colors.oddRow} !important;
        }
      }
    }
    .ant-table-row:hover .action-button,
    .action-button.ant-dropdown-open {
      background-color: ${({ theme }) => theme.colors.defaultWhite};
      border: 1px solid ${({ theme }) => theme.colors.lightGray};
    }
  }
  .actions-cta {
    width: 2rem;
    height: 2rem;
    .anticon {
      font-size: 1.25rem;
      color: ${({ theme }) => theme.colors.blueGray};
      stroke: ${({ theme }) => theme.colors.blueGray};
      stroke-width: 3rem;
    }
  }
`;
