import React from "react";

import Loading from "../loading";
import { LoadingContainer } from "./LoadingWrapper.styled";

export const LoadingWrapper: React.FC<{
  children: React.ReactNode;
  isLoading: boolean;
  showLoadingMask?: boolean;
  minWidthLoadingMask?: boolean;
}> = ({ children, isLoading, showLoadingMask = true, minWidthLoadingMask }) => {
  const containerClassName = `amaya-loading-wrapper ${minWidthLoadingMask ? " min-width" : ""}`;

  return (
    <LoadingContainer className={containerClassName}>
      {isLoading && (
        <>
          {showLoadingMask ? <div className="loading-mask" /> : undefined}
          <div className="loading-spinner">
            <Loading />
          </div>
        </>
      )}
      {children}
    </LoadingContainer>
  );
};
