import styled from "styled-components";

export const SourceContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  height: 1rem;
`;

export const SourceImage = styled.img`
  object-fit: contain;
  border-radius: 0.25rem;
`;
