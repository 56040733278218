import { toArray } from "@/utils/arrayUtils";
import { formatNumber } from "@/utils/numberUtils";
import { EchartsTooltipFormatterParams } from "@/utils/types/EChartsDefinitions";

// Cell value, Module value, heatmap cell value
export type HDMHeatMapCell = [number, number, number];

export const getComparisonModeValues = (tableValues: number[], comparisonValues: number[], comparisonMode: boolean) => {
  if (!comparisonMode || tableValues?.length !== comparisonValues?.length) return tableValues;
  return tableValues?.map((tableValue, i) => {
    if (!tableValue || isNaN(comparisonValues?.[i] as number)) {
      return undefined;
    }
    return tableValue - comparisonValues[i];
  });
};

export const heatMapTooltipFormater = (
  params: Object | Array<Object>,
  comparisonMode: boolean | undefined,
  comparisonValue: ((index: number) => string) | undefined
): string | HTMLElement | HTMLElement[] => {
  const paramsList = toArray(params) as EchartsTooltipFormatterParams<HDMHeatMapCell>[];

  return paramsList
    .map((p) => {
      const { data, marker, dataIndex } = p;
      return `<div class="tooltip-body hdm-heatmap-tooltip">
        <div class="flex-container vertical">
        <div class="flex-container horizontal">
          <span class="body-medium">Cell <strong>${data[0] + 1}</strong></span>
          <span class="body-medium">Module <strong>${data[1] + 1}</strong></span>
        </div>
          <div class="flex-container">
            ${marker}
            <span class="body-medium"><strong>${formatNumber(data[2])}</strong></span>
          </div>
          ${
            comparisonMode
              ? `<div class="flex-container">
            <span>Difference</span>
            <span class="body-medium"><strong>${comparisonValue?.(dataIndex)}</strong></span>
          </div>`
              : ""
          }            
        </div>
      </div>`;
    })
    .join("");
};

type buildHDMHeatMapConfigProps = {
  data: number[];
  cellCount: number;
  moduleCount: number;
  minValue: number | undefined;
  maxValue: number | number;
  getDisplayValues?: (index: number) => string;
  comparisonMode?: boolean;
  hoveredCells?: { x: number; y: number };
};

export const buildHDMHeatMapConfig = ({
  data,
  cellCount,
  moduleCount,
  minValue,
  maxValue,
  getDisplayValues,
  comparisonMode,
}: buildHDMHeatMapConfigProps) => {
  const xAxisData = Array.from({ length: cellCount }, (_, i) => i + 1);
  const yAxisData = Array.from({ length: moduleCount }, (_, i) => i + 1);

  const seriesData = [];

  for (let i = 0; i < moduleCount; i++) {
    for (let j = 0; j < cellCount; j++) {
      const index = j + cellCount * i;
      seriesData.push([j, i, data[index]]);
    }
  }

  return {
    option: {
      animation: false,
      visualMap: {
        max: maxValue,
        min: minValue,
        calculable: true,
        dimension: 2,
        orient: "vertical",
        left: "right",
        top: "center",
        align: "left",
        itemHeight: 650,
        padding: [0, 20, 0, 0],
        inRange: {
          color: ["#440154", "#c6427e", "#fde725"],
        },
        formatter: (value: number) => formatNumber(value, true),
      },
      tooltip: {
        trigger: "item",
        padding: 0,
        className: "chart-library-tooltip-container",
        borderColor: "transparent",
        formatter: (param: any) => heatMapTooltipFormater(param, comparisonMode, getDisplayValues),
        position: function (_point: any, _params: any, _dom: any, rect: DOMRect, size: { contentSize: number[] }) {
          return [rect.width + rect.x + 10, rect.y + rect.height / 2 - size.contentSize[1] / 2];
        },
      },
      grid: { left: 40, top: 35, right: 110, bottom: 20 },
      textStyle: {
        color: "#5F5F5F",
        fontFamily: "Hubot Sans",
      },
      xAxis: {
        type: "category",
        name: "Cell",
        position: "top",
        nameLocation: "middle",
        nameGap: 20,
        data: xAxisData,
        splitArea: {
          show: true,
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
      },
      yAxis: {
        type: "category",
        name: "Module",
        nameLocation: "middle",
        nameGap: 30,
        data: yAxisData,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        inverse: true,
      },
      series: [
        {
          name: "Heatmap",
          type: "heatmap",
          // Allows the no data chart to be shown.
          data: data.length > 0 ? seriesData : [],
          label: {
            show: true,
            formatter: ({ value }: { value: HDMHeatMapCell }) => {
              return getDisplayValues ? getDisplayValues(value[0] + value[1] * cellCount) : formatNumber(value[2]);
            },
          },
        },
      ],
    },
  };
};
