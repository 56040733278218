import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { DEFAULT_PAGE_SIZE } from "@lib/src/table/defaults";
import { notification, Skeleton, TableProps, Tooltip } from "antd";
import { SortOrder } from "antd/es/table/interface";
import { ColumnsType, ColumnType, TablePaginationConfig } from "antd/lib/table";
import { debounce, get, isNumber, split } from "lodash";
import { MouseEvent, useEffect, useRef, useState } from "react";

import { UI_SETTINGS } from "@/constants";

import IconSortDown from "../icons/icon-sort-down.svg";
import IconSortUp from "../icons/icon-sort-up.svg";
import { EmptyComponent } from "../ui/EmptyComponent";
import { StyledTable, TableCellWrapper } from "./BasicTable.styled";
import { addCellEventListener, getSelectedData, InternalFunctions, SelectionGrid } from "./BasicTableUtils";
import { DEFAULT_SORT_OPTIONS } from "./sorting";

type TableSortIconProps = {
  sortOrder: SortOrder;
};

export const TableSortIcon = ({ sortOrder }: TableSortIconProps) => {
  if (!sortOrder) return <></>;
  return sortOrder === "ascend" ? <IconSortUp /> : <IconSortDown />;
};

function addSortIcon<T>(props: BasicTableProps<T>): BasicTableProps<T> {
  return {
    ...props,
    columns: props.columns?.map((c) => ({
      ...c,
      sortIcon: c.sortIcon || (c.sorter ? ({ sortOrder }) => <TableSortIcon sortOrder={sortOrder} /> : undefined),
    })),
  };
}

export type BasicTableProps<T> = TableProps<T> & {
  noDataText?: string;
};

/**
 * Adds tooltips to the columns of a table.
 */
function addTooltipToColumns<T>(props: TableProps<T>): TableProps<T> {
  return {
    ...props,
    columns: props.columns?.map((c: ColumnType<T>) => {
      const isStringTitle = typeof c.title == "string";
      return {
        ...c,
        ellipsis: { showTitle: false },
        render: (value: any, record: T) => {
          return <BasicTableCell column={c} record={record} value={value} />;
        },
        title: isStringTitle && c.title ? <BasicTableHeaderCell title={c.title.toString()} width={c.width} /> : c.title,
      };
    }),
  };
}

type CellRendererProps = {
  value: any;
  record: any;
  column: ColumnType<any>;
};

const BasicTableHeaderCell = (props: { title: string; width: string | number | undefined }) => {
  const { title, width } = props;
  const headerCellRef = useRef<HTMLTableCellElement>(null);
  const [headerEllipsis, setHeaderEllipsis] = useState(false);

  const isEllipsisActive = () => {
    if (headerCellRef.current) {
      return headerCellRef.current.offsetWidth < headerCellRef.current.scrollWidth;
    }
    return false;
  };

  useEffect(() => {
    setHeaderEllipsis(isEllipsisActive());
  }, [props]);

  return headerEllipsis ? (
    <Tooltip title={title}>
      <TableCellWrapper size={width} ref={headerCellRef}>
        {title}
      </TableCellWrapper>
    </Tooltip>
  ) : (
    <TableCellWrapper size={width} ref={headerCellRef}>
      {title}
    </TableCellWrapper>
  );
};

/**
 * Renders the content of a basic table cell with tooltip and ellipsis.
 */
const BasicTableCell = (props: CellRendererProps) => {
  const { column, record, value } = props;
  const cellRef = useRef<HTMLTableCellElement>(null);
  const [ellipsis, setEllipsis] = useState(true);

  const isEllipsisActive = () => {
    if (cellRef.current) {
      return cellRef.current.offsetWidth < cellRef.current.scrollWidth;
    }
    return false;
  };

  useEffect(() => {
    setEllipsis(isEllipsisActive());
  }, [props]);

  const columnDataIndex = (column.dataIndex || column.key) as string;
  const keys = split(columnDataIndex, ".") ?? [];

  const renderContent = column.render ? column.render(value, record, 0) : get(record, keys);
  if (column.tooltipMessage) {
    return (
      <Tooltip title={column.tooltipMessage(value, record)} placement="topLeft">
        <TableCellWrapper size={column.width}>{renderContent}</TableCellWrapper>
      </Tooltip>
    );
  }

  return ellipsis ? (
    <Tooltip title={renderContent} placement="topLeft">
      <TableCellWrapper size={column.width} ref={cellRef}>
        {renderContent}
      </TableCellWrapper>
    </Tooltip>
  ) : (
    <TableCellWrapper size={column.width} ref={cellRef}>
      {renderContent}
    </TableCellWrapper>
  );
};

const refineProps = <T extends object>(props: TableProps<T>, InternalFunctions: InternalFunctions) => {
  const columnsWithToolTip = addTooltipToColumns(props);
  const columnCellsWithEventListeners = addCellEventListener(columnsWithToolTip, InternalFunctions);
  return addSortIcon(columnCellsWithEventListeners);
};

const buildPaginationConfig = (
  pagination: false | TablePaginationConfig | undefined
): false | TablePaginationConfig | undefined => {
  return pagination
    ? {
        showSizeChanger: false,
        showQuickJumper: false,
        locale: {
          items_per_page: "items per page",
          jump_to: "",
          page: "Jump to page:",
        },
        itemRender(_page, type, element) {
          if (type === "prev") return <ArrowLeftOutlined />;
          if (type === "next") return <ArrowRightOutlined />;
          return element;
        },
        ...pagination,
      }
    : false;
};

const minLoadingSkeletonWidth = 60;
const maxLoadingSkeletonWidth = 250;

const buildLoadingColumns = <T extends object>(columns: ColumnsType<T> | undefined): ColumnsType<T> | undefined => {
  if (columns) {
    return columns.map((col) => {
      return {
        ...col,
        showSorterTooltip: false,
        ellipsis: false,
        sorter: col.sorter ? true : undefined,
        render: () => {
          if (col.title) {
            const colWidth = Math.min(
              isNumber(col.width) ? col.width : minLoadingSkeletonWidth,
              maxLoadingSkeletonWidth
            );
            return <Skeleton.Input active block style={{ maxWidth: colWidth }} />;
          }
          return <Skeleton.Avatar active shape="circle" />;
        },
      };
    });
  }
};

const buildLoadingDatasource = <T extends object>(
  pagination: false | TablePaginationConfig | undefined
): readonly T[] | undefined => {
  return Array(pagination && pagination?.pageSize ? pagination.pageSize : DEFAULT_PAGE_SIZE).fill(<></>);
};

export const BasicTable = <T extends object>(props: BasicTableProps<T>) => {
  // used to prevent a click event from happening if the user is copying from the table
  const selectionMadeRef = useRef<boolean>(false);
  const [api, context] = notification.useNotification();
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  const [selection, setSelection] = useState<{ columns: SelectionGrid; rows: SelectionGrid }>({
    columns: { start: undefined, end: undefined },
    rows: { start: undefined, end: undefined },
  });

  const isSelecting =
    selection.columns.start !== undefined &&
    selection.rows.start !== undefined &&
    selection.columns.end !== undefined &&
    selection.rows.end !== undefined;

  const { loading, ...args } = refineProps(props, { setSelection, selection });
  const resizeSetTimestamp = debounce(() => setTimestamp(new Date().getTime()), UI_SETTINGS.STANDARD_INPUT_DEBOUNCE);

  const lastPaginationKnown = args.pagination as TablePaginationConfig | undefined;

  const totalRowsRef = useRef<number | undefined>(lastPaginationKnown?.total);

  useEffect(() => {
    if (lastPaginationKnown?.total) {
      totalRowsRef.current = lastPaginationKnown?.total;
    }
  }, [lastPaginationKnown?.total]);

  useEffect(() => {
    window.addEventListener("resize", resizeSetTimestamp);

    return () => {
      window.removeEventListener("resize", resizeSetTimestamp);
    };
  }, []);

  if (loading) {
    const nonRefinedColumns = addSortIcon(props).columns;
    const loadingColumns = buildLoadingColumns(nonRefinedColumns);
    const loadingDatasource = buildLoadingDatasource(args.pagination);

    return (
      <StyledTable
        className="loading-table"
        columns={loadingColumns}
        dataSource={loadingDatasource}
        loading={false}
        sortDirections={DEFAULT_SORT_OPTIONS}
        pagination={
          totalRowsRef.current ? { ...buildPaginationConfig(args.pagination), total: totalRowsRef.current } : false
        }
        rowKey={() => Math.random()}
        // Allows loading table to change pagination and sorting
        onChange={args.onChange}
      />
    );
  }

  const tableClassName = `amaya-table ${isSelecting ? " basic-table-selecting" : ""} ${timestamp}`;

  const addOnRowAttributes = (rowProps: any) => {
    const clearSelection = () => {
      setSelection({
        columns: { start: undefined, end: undefined },
        rows: { start: undefined, end: undefined },
      });
      window.removeEventListener("mouseup", clearSelection);
    };
    return {
      ...rowProps,
      onMouseDown: (e: MouseEvent) => {
        window.addEventListener("mouseup", clearSelection);
        //@ts-ignore
        rowProps?.onMouseDown?.(e);
      },
      onMouseUp: (e: MouseEvent) => {
        selectionMadeRef.current = isSelecting;
        getSelectedData(selection, setSelection, props.columns, api);
        //@ts-ignore
        rowProps?.onMouseUp?.(e);
      },
      onClick: (e: MouseEvent) => {
        if (!selectionMadeRef.current) {
          //@ts-ignore
          rowProps?.onClick(e);
        }
        selectionMadeRef.current = true;
      },
    };
  };

  return (
    <>
      {context}
      <StyledTable
        className={tableClassName}
        id={props.id}
        locale={{ emptyText: <EmptyComponent isLoading={!!loading} description={props.noDataText} /> }}
        showSorterTooltip={false}
        sortDirections={DEFAULT_SORT_OPTIONS}
        {...args}
        onHeaderRow={(row, index) => {
          const propsOnHeaderRow = props.onHeaderRow?.(row, index);
          return addOnRowAttributes(propsOnHeaderRow);
        }}
        onRow={(row, index) => {
          const propsOnRow = props.onRow?.(row, index);
          return addOnRowAttributes(propsOnRow);
        }}
        pagination={buildPaginationConfig(args.pagination)}
      />
    </>
  );
};
