import { ColumnType } from "antd/es/table";
import { useContext, useEffect, useState } from "react";

import { CalculatedStatus, useAnomalyVehicleCountForFleetIntelQuery, VehicleCountByCountry } from "@/api/customerApi";
import FleetMap from "@/components/fleetMap/FleetMap";
import { BasicTable } from "@/components/tables/BasicTable";
import HelperTooltipWithIcon from "@/components/ui/HelperTooltipWithIcon";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { QUERY_SETTINGS } from "@/constants";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { getCountryName } from "@/utils/countries";
import { formatNumber } from "@/utils/numberUtils";

import { SummaryContainer, SummaryHeaderContainer } from "./AnomalyFleetSummary.styled";

type AnomalousVehicleCountByCountry = {
  country: string;
  vehicles?: number;
  caution?: number;
  warning?: number;
};

const HIGHLIGHTED_ROW_CLASS = "highlighted";

type AnomalyFleetSummaryProps = {
  id: string;
  status?: CalculatedStatus;
};

const AnomalyFleetSummary = ({ id, status }: AnomalyFleetSummaryProps) => {
  const { globalFilter } = useContext(GlobalFilterContext);
  const [selectedCountry, setSelectedCountry] = useState("");

  const { data, isLoading } = useAnomalyVehicleCountForFleetIntelQuery(
    {
      anomalyId: id,
      anomalyStatus: status,
      filter: globalFilter,
    },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME, enabled: !!status }
  );

  const vehicleData = (data?.filteredQuery.anomalyVehicleCountByCountry.vehiclesByCountry.map((e) => {
    return {
      country: e.country ?? "",
      vehicles: e.vehicles ?? 0,
      caution: e.vehiclesCautionCount ?? 0,
      warning: e.vehiclesWarningCount ?? 0,
    };
  }) ?? []) satisfies AnomalousVehicleCountByCountry[];

  const columns: ColumnType<AnomalousVehicleCountByCountry>[] = [
    {
      title: "Country",
      key: "country",

      render: (_, data: VehicleCountByCountry) => {
        if (data.country == "Total") return "Total";
        return getCountryName(data.country ?? "");
      },
    },
    {
      title: "Total Vehicles",
      key: "vehicles",
      width: 120,
      align: "end",

      render: (_, data) => data.vehicles && formatNumber(data.vehicles),
      defaultSortOrder: "descend",
    },
    {
      title: "Caution",
      key: "caution",
      width: 110,
      align: "end",
      onHeaderCell: () => ({
        className: "warning",
      }),
      render: (_, row) => (row.caution === undefined ? "-" : formatNumber(row.caution ?? 0)),
    },
    {
      title: "Warning",
      key: "warning",
      width: 110,
      align: "end",
      onHeaderCell: () => ({
        className: "error",
      }),
      render: (_, row) => (row.warning === undefined ? "-" : formatNumber(row.warning ?? 0)),
    },
  ];

  useEffect(() => {
    if (selectedCountry) {
      document.querySelector(`.${HIGHLIGHTED_ROW_CLASS}`)?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, [selectedCountry]);

  const handleRowSelected = (vehicle: AnomalousVehicleCountByCountry) => {
    if (vehicle.country) {
      return setSelectedCountry(selectedCountry !== vehicle.country ? vehicle.country : "");
    }
  };

  const summaryHeader = (
    <SummaryHeaderContainer>
      Summary
      <HelperTooltipWithIcon message="Use this map to compare anomaly occurrences across geographies." />
    </SummaryHeaderContainer>
  );

  return (
    <BasicWidget title={summaryHeader}>
      <SummaryContainer>
        <FleetMap
          data={vehicleData}
          isLoading={isLoading}
          selectedCountry={selectedCountry}
          onCountrySelected={setSelectedCountry}
        />
        <div className="summary-table">
          <BasicTable
            id="anomaly-fleet-summary-table"
            dataSource={vehicleData}
            columns={columns}
            rowKey={"country"}
            rowClassName={(vehicle) => {
              return vehicle.country && vehicle.country === selectedCountry
                ? `row-cta ${HIGHLIGHTED_ROW_CLASS}`
                : `row-cta`;
            }}
            onRow={(vehicle) => {
              return {
                onClick: () => handleRowSelected(vehicle),
              };
            }}
            loading={isLoading}
            pagination={false}
          />
        </div>
      </SummaryContainer>
    </BasicWidget>
  );
};

export default AnomalyFleetSummary;
