import { DownOutlined } from "@ant-design/icons";
import { SizeType } from "antd/lib/config-provider/SizeContext";

import { TimeSeriesIndependentVar } from "@/api";

import DropdownWidget from "../ui/DropdownWidget";

const INDEPENDENT_VARIABLES = [
  { key: TimeSeriesIndependentVar.Mileage, label: "Odometer" },
  { key: TimeSeriesIndependentVar.Time, label: "Timestamp" },
];

type TimeSeriesVariableSelectorProps = {
  currentVariable: TimeSeriesIndependentVar;
  onChange: (variableKey: TimeSeriesIndependentVar) => void;
  size?: SizeType;
};

const TimeSeriesVariableSelector = ({ currentVariable, onChange, size = "small" }: TimeSeriesVariableSelectorProps) => {
  const selectedVariableOption = INDEPENDENT_VARIABLES.find((e) => e.key === currentVariable);

  return (
    <DropdownWidget
      elements={INDEPENDENT_VARIABLES}
      onElementSelected={(key) =>
        onChange(INDEPENDENT_VARIABLES.find((v) => v.key === key)?.key ?? TimeSeriesIndependentVar.Mileage)
      }
      label={
        <>
          {`X-Axis: ${selectedVariableOption?.label}${
            selectedVariableOption?.key === TimeSeriesIndependentVar.Mileage ? " (kms)" : ""
          }`}
        </>
      }
      buttonSize={size}
      icon={<DownOutlined />}
      preselectedKey={selectedVariableOption?.key}
    />
  );
};

export default TimeSeriesVariableSelector;
