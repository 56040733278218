import { EChartsInstance } from "echarts-for-react";
import { MutableRefObject } from "react";

import { SingleVehicleAnomalyDetector, TimeSeriesData } from "@/api/customerApi";

import { anomalyScoreToColor } from "./anomalyScoreToColor";

export const handleAnomalyChartMouseOver = (
  e: any,
  ninetyDayHistory: TimeSeriesData | undefined,
  anomaly: SingleVehicleAnomalyDetector | undefined,
  chart: MutableRefObject<EChartsInstance>
) => {
  if (!e || !e.data || !ninetyDayHistory || !anomaly) return;
  let x = ninetyDayHistory.x[e.data.name];
  let y = ninetyDayHistory.y[0][e.data.name];
  if (!x || !y) return;
  chart?.current?.setOption?.({
    graphic: [
      {
        type: "circle",
        position: chart.current.convertToPixel("grid", [x, y * 100]),
        shape: {
          cx: 0,
          cy: 0,
          r: 5,
        },
        style: {
          stroke: anomalyScoreToColor(
            y,
            anomaly.detector.warningThreshold,
            anomaly.detector.cautionThreshold ?? anomaly.detector.warningThreshold
          ),
          fill: "white",
          lineWidth: 2,
        },
        z: 10,
      },
    ],
  });
};
