import styled from "styled-components";

export const FleetMapContainer = styled.div`
  width: 100%;
  position: relative;
  .fleet-intel-map-loading {
    position: absolute;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
`;

export const RoamOverlay = styled.div`
  position: absolute;
  top: 0;
  background-color: transparent;
  margin: 0.25rem;
  width: calc(100% - 0.5rem);
  height: calc(100% - 0.5rem);
  border: 2px dashed ${({ theme }) => theme.colors.lightGray};
  border-radius: 0.5rem;
  .overlay-body {
    padding: 0.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    span {
      width: max-content;
      z-index: 2;
      padding: 0.25rem;
      border-radius: 0.5rem;
      background-color: ${({ theme }) => theme.colors.defaultWhite};
    }
  }
  animation: fadeIn 300ms ease-in-out;
  &.roaming {
    border: 2px dashed ${({ theme }) => theme.colors.accentBlue};
    .overlay-body {
      span {
        color: ${({ theme }) => theme.colors.accentBlue};
      }
    }
  }
`;
