import { Button } from "antd";
import i18next from "i18next";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { AggregateType } from "@/api";
import {
  DiagnosticCheckType,
  HdmComparisonOptions,
  HdmCustomComparisonInput,
  HdmRepairEventType,
} from "@/api/customerApi";
import { StyledSelect } from "@/components/form/input/BasicSelect.styled";
import Loading from "@/components/loading";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { camelCaseToTitleCase } from "@/utils/stringUtils";
import { consolidateModelStyleOptions, toModelDescription } from "@/utils/vehicleModel";

import { ComparisonDefintion } from "./HDMComparison";
import { ComparisonFormContainer, SelectorContainer } from "./HDMComparisonForm.styled";
import { HDMAllowedAggregateTypes } from "./HistoricDataMatrix";

type HDMComparisonFormProps = {
  onChange: (settings: ComparisonDefintion) => void;
  comparisonOptions?: HdmComparisonOptions;
  defaultValues: HdmComparisonOptions | undefined;
};
export const nonSpecificDefaultValues = {
  aggregation: AggregateType.Mean,
  repairType: undefined,
  DiagnosticCheckType: DiagnosticCheckType.First,
};
const HDMCustomComparisonForm = ({ onChange, comparisonOptions, defaultValues }: HDMComparisonFormProps) => {
  const { customerIdentifier } = useCustomerIdentifier();

  const { handleSubmit, setValue, watch } = useForm<HdmCustomComparisonInput>({
    defaultValues: { ...nonSpecificDefaultValues },
  });

  const onSubmit: SubmitHandler<HdmCustomComparisonInput> = (settings) => {
    const filteredSettings = Object.fromEntries(
      Object.entries({ ...settings }).filter(([_, value]) => Boolean(value))
    ) as HdmCustomComparisonInput;
    onChange({ customComparison: filteredSettings, clusterComparison: undefined });
  };

  useEffect(() => {
    if (defaultValues) {
      setValue("model", toModelDescription({ modelStyle: defaultValues.model[0] }, customerIdentifier.models));
      setValue("modelYear", defaultValues.modelYear[0]);
      setValue("deliveryCountry", defaultValues.deliveryCountry[0]);
      setValue("batterySize", defaultValues.moduleCount[0]);
    }
  }, [defaultValues, setValue]);

  return (
    <>
      {!comparisonOptions && <Loading />}
      {comparisonOptions && (
        <ComparisonFormContainer onSubmit={handleSubmit(onSubmit)}>
          <SelectorContainer>
            <span className="selector-label body-medium">Model Year</span>
            <StyledSelect
              placeholder="Select model year"
              options={[
                { value: "", label: "All" },
                ...(comparisonOptions?.modelYear.map((year) => ({ value: year })) ?? []),
              ]}
              value={watch("modelYear") ?? ""}
              onChange={(val) => setValue("modelYear", val as number)}
            />
          </SelectorContainer>
          <SelectorContainer>
            <span className="selector-label body-medium">Model</span>
            <StyledSelect
              placeholder="Select model"
              options={[
                { value: "", label: "All" },
                ...(consolidateModelStyleOptions(
                  comparisonOptions?.model.map((model) => ({ label: model ?? "-", value: model ?? "-" })) ?? [],
                  customerIdentifier.models
                ) ?? []),
              ]}
              value={watch("model") ?? ""}
              onChange={(val) => setValue("model", val as string)}
            />
          </SelectorContainer>
          <SelectorContainer>
            <span className="selector-label body-medium">Delivery Country</span>
            <StyledSelect
              placeholder="Select country"
              options={[
                { value: "", label: "All" },
                ...(comparisonOptions?.deliveryCountry.map((country) => ({
                  value: country,
                })) ?? []),
              ]}
              value={watch("deliveryCountry") ?? ""}
              onChange={(val) => setValue("deliveryCountry", val as string)}
            />
          </SelectorContainer>
          <SelectorContainer>
            <span className="selector-label body-medium">Aggregation</span>
            <StyledSelect
              placeholder="Select Aggregation"
              options={Object.entries(HDMAllowedAggregateTypes).map(([key, value]) => ({
                value: value,
                label: camelCaseToTitleCase(key),
              }))}
              value={watch("aggregation")}
              onChange={(val) => setValue("aggregation", val as AggregateType)}
            />
          </SelectorContainer>
          <SelectorContainer>
            <span className="selector-label body-medium">Workshop Visit</span>
            <StyledSelect
              options={[
                { value: "", label: "All" },
                ...Object.entries(HdmRepairEventType).map(([k, v]) => ({
                  value: v,
                  label: camelCaseToTitleCase(k),
                })),
              ]}
              value={watch("repairType") ?? ""}
              onChange={(val) => setValue("repairType", val as HdmRepairEventType)}
              placeholder={"Select Workshop Visit"}
            />
          </SelectorContainer>
          <SelectorContainer>
            <span className="selector-label body-medium">{i18next.t("columnTitle.DIAGNOSTIC_CHECK_ID")}</span>
            <StyledSelect
              options={Object.entries(DiagnosticCheckType).map(([k, v]) => ({
                value: v,
                label: camelCaseToTitleCase(k),
              }))}
              value={watch("DiagnosticCheckType")}
              onChange={(val) => setValue("DiagnosticCheckType", val as DiagnosticCheckType)}
              placeholder={"Select Diagnostic Check"}
            />
          </SelectorContainer>
          <SelectorContainer>
            <span className="selector-label body-medium">Battery Size</span>
            <StyledSelect
              options={comparisonOptions?.moduleCount.map((module) => ({ value: module }))}
              value={watch("batterySize")}
              onChange={(val) => setValue("batterySize", val as number)}
              placeholder={"Select Battery Size"}
            />
          </SelectorContainer>
          <Button className="submit-form-btn" type="primary" htmlType="submit" block>
            Done
          </Button>
        </ComparisonFormContainer>
      )}
    </>
  );
};

export default HDMCustomComparisonForm;
